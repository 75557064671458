import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


const baseQuery = fetchBaseQuery({
  baseUrl: "https://data.realtorscrm.com/",

  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    const agencyId = getState().auth.agencyId;


    headers.set("Cache-Control", `no-cache`);

    if (token && agencyId) {
      headers.set("Authorization", "Bearer " + token);
      headers.set("userid", agencyId);
    }

    return headers;
  },
});

export const apiSlice = createApi({
  baseQuery: baseQuery,
  endpoints: () => ({}),
  tagTypes: ["Agency", "City", "AgencyProperties", "Agents", "Amenity", "PropertyLeads"],
});
