import { DatePicker as DatePickerMUI } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";
const DatePicker = ({ name, label, rules, disabled = false }) => {
  const { control } = useFormContext();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { ref, value, ...field }, fieldState: { error } }) => {
          return (
            <DatePickerMUI
              disabled={disabled}
              {...field}
              timezone="system"
              value={dayjs(value)}
              inputRef={ref}
              label={label}
              slotProps={{
                textField: {
                  size: "small",
                  fullWidth: true,
                  error: !!error,
                  helperText: error?.message,
                },
              }}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default memo(DatePicker);
