import { Fab, Typography, styled } from "@mui/material";
import React, { memo } from "react";
import { useDropzone } from "react-dropzone";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ImagesContainer from "./ImagesContainer";
import TextField from "../FormElements/TextField";
import { propertyRules } from "./propertyValidation";

function ImagesUpload({ images, onDrop, onSortEnd, onChangeDescription, onRemoveImage }) {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "image/jpeg": [], "image/png": [] },
  });

  return (
    <>
      <StyledContainer>
        <StyledRight>
          <Typography variant="h3" mb={2}>
            Media
          </Typography>

          <Typography variant="h4" mb={2}>
            Upload Video Link
          </Typography>

          <TextField
            sx={{ mb: 4 }}
            name="propertyvideos"
            label="Youtube Link (https://youtu.be/..)"
            rules={propertyRules.propertyvideos}
          />

          <ImagesContainer
            items={images}
            onSortEnd={onSortEnd}
            onChangeDescription={onChangeDescription}
            onRemoveImage={onRemoveImage}
          />
        </StyledRight>

        <StyledLeft {...getRootProps()}>
          <Typography variant="h4">Upload Images</Typography>
          <input {...getInputProps()} />
          <Fab color="primary">
            <FileUploadIcon />
          </Fab>
          <Typography variant="h4">Drag and Drop Images to upload</Typography>
          <Typography variant="h6">OR</Typography>
          <Typography variant="h4">Click this box to select Images</Typography>

          <Typography variant="h6">Supported Formats: JPEG, JPG, PNG</Typography>
        </StyledLeft>
      </StyledContainer>
    </>
  );
}

export default memo(ImagesUpload);

const StyledContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  marginTop: "24px",
  gap: "24px",
  flexDirection: "column",
}));

const StyledLeft = styled("div")(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "16px",
  border: `1px dashed ${theme.palette.primary.main}`,
  borderRadius: "20px",
  transition: "all 0.2s ease",
  cursor: "pointer",
  padding: "8px",

  "&:hover": {
    backgroundColor: "rgba(0,0,0,0.05)",
  },
}));

const StyledRight = styled("div")(({ theme }) => ({
  transition: "all 0.25s ease",
}));
