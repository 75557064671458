import React from "react";
import { Controller } from "react-hook-form";
import "react-phone-number-input/style.css";
import ReactPhoneInput from "react-phone-number-input";

export default function Phone({ name, defaultCountry, label, rules = {}, control, setPhone }) {
  const getCountryCode = () => {
    switch (defaultCountry) {
      case 'Pakistan':
        return 'PK';
      case 'United Arab Emirates':
        return 'AE';
      default:
        return 'unknown';
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <div>
          <ReactPhoneInput
            className={`rounded-md overflow-hidden h-10 px-4 border ${
              error ? 'border-red-500' : 'border-gray-300 hover:border-black'
            } bg-background [&>input]:h-full [&>input]:text-sm [&>input]:outline-none`}
            international
            placeholder={label}
            error={error}
            defaultCountry={getCountryCode()}
            {...field}
            onChange={(value) => {
              field.onChange(value);
              setPhone(value);
            }}
          />
          {error && <p className="text-red-500 mt-2 ml-4 text-xs font-normal">{error.message}</p>}
        </div>
      )}
    />
  );
}
