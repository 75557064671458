import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormControl, InputLabel, MenuItem, Select as MuiSelect } from "@mui/material";

const AgentSelect = ({ name, rules, defaultValue, isFetching, agents, setSelectedAgentId }) => {
    const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      size={"small"}
      defaultValue={defaultValue}
      render={({ field }) => (
        <FormControl variant="outlined" fullWidth size="small" sx={{ minHeight: '48px' }}>
          <InputLabel >Select Agent</InputLabel>
          <MuiSelect
            {...field}
            label="Select Agent"
            disabled={isFetching}
            value={field.value ?? ""}
            onChange={(event) => {
              const selectedValue = event.target.value;
              field.onChange(selectedValue);
              setSelectedAgentId(selectedValue); // Updating the state with selected agent id
              
            }}
          >
            {agents && agents.map(agent => (
              <MenuItem key={agent.agentid} value={agent.agentid}>
                {agent.agentname}
              </MenuItem>
            ))}
          </MuiSelect>
        </FormControl>
      )}
    />
  );
};

export default AgentSelect;
