import { toast } from "react-toastify";
import { apiSlice } from ".";

const dealsPdf = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDealsPdf: builder.query({
      query: ({ agcid, agtid }) => `/DealsTables/DealPDFNames?agcid=${agcid}&agtid=${agtid}`,
    }),
    getDealsDetails: builder.query({
      query: (agcid) => `/DealsTables/DealReport?agcid=${agcid}`,
    }),


    getTransactionDetails: builder.query({
      query: ({ token, dealId, agtid }) =>
      ({
        url: `/dealTransactionTables/GetdealTransactionTable?deid=${dealId}`,
        method: "GET",
        headers: {
          token: `Bearer ${token}`,
          "userId": agtid
        },
      }),
    }),
    postTransaction: builder.mutation({
      query: ({ data, token, agtid }) => ({
        url: "/dealTransactionTables/PostdealTransactionTable",
        method: "POST",
        body: data,
        headers: {
          Authorization: `Bearer ${token}`,
          userId: agtid
        },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        toast.promise(queryFulfilled, {
          pending: "Adding Transaction",
          success: "Transaction Added",
          error: "Error Adding Transaction",
        });
      },
    }),
  }),
});

export const { useGetDealsPdfQuery, useGetDealsDetailsQuery, useGetTransactionDetailsQuery, usePostTransactionMutation } = dealsPdf;
