// import parse from "html-react-parser";
import { useState } from "react";
import { LuChevronDown, LuChevronUp } from "react-icons/lu";

export default function DescriptionText({ text }) {
  const [isOpenReadMore, setIsOpenReadMore] = useState(false);

  return (
    <>
      <div
        className={`relative text-md rounded-lg bg-inputColor p-5 whitespace-pre-wrap leading-7 overflow-hidden transition-all ${
          isOpenReadMore ? "max-h-[1700px]" : `max-h-[150px]`
        }`}
      >
        {!isOpenReadMore && (
          <div className={"absolute bottom-0 left-0 w-full h-16 read-more-linear-gradient"}></div>
        )}
        <div>{text}</div>
      </div>

      {isOpenReadMore ? (
        <button
          onClick={() => setIsOpenReadMore(false)}
          variant="ghost"
          className="text-green float-right flex p-5"
        >
          Read Less <LuChevronUp className="ml-1" />
        </button>
      ) : (
        <button
          variant="ghost"
          className=" text-green float-right flex p-5"
          onClick={() => setIsOpenReadMore(true)}
        >
          Read More <LuChevronDown className="ml-1" />
        </button>
      )}
    </>
  );
}
