import React, { useEffect, useState } from "react";
import { BsWhatsapp } from "react-icons/bs";
import { FaRegCopy, FaCheck } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";

export default function ShareLocation({ setShareLocation, location }) {
    const [isCopied, setIsCopied] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 700);
        };
        
        handleResize(); 
        window.addEventListener("resize", handleResize);
        
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const whatsappURL = isMobile
        ? `whatsapp://send?text=Check out this beautiful property at ${location}`
        : `https://web.whatsapp.com/send?text=${encodeURIComponent("Check out this beautiful property at " + location)}`;


    const onCopy = () => {
        navigator.clipboard.writeText(location);
        setIsCopied(true);
    };

    return (
        <div className="bg-white p-5 rounded-lg w-[80%] sm:w-auto">
            <div className="float-right cursor-pointer" onClick={() => setShareLocation(false)}>
                <RxCross2 size={20} />
            </div>
            <div className="sm:max-w-[425px]">
                <div className="font-bold my-2.5 text-lg">Share Property</div>
                <div>
                    <p>Share this property Location on social media platforms like WhatsApp and Facebook.</p>
                </div>

                <div className="flex gap-2 !my-2 ">
                    <button variant="outline" asChild>
                    <a href={whatsappURL} target="_blank">
                            <BsWhatsapp size={24} color="#25D366" />
                        </a>
                    </button>

                    <button className="m-2.5">
                        <a
                            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(location)}&t=Check out this beautiful property`}
                            target="_blank"
                        >
                            <FaFacebookF size={24} />
                        </a>
                    </button>
                </div>

                <div>
                    <div className="flex w-full max-w-sm items-center gap-2">
                        <input disabled className="w-[100%] bg-inputColor p-2.5 rounded-lg" value={location} />
                        <button className="bg-black text-white p-2.5 rounded-lg" onClick={onCopy}>
                            {isCopied ? <FaCheck /> : <FaRegCopy />}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
