import { configureStore, isRejectedWithValue } from "@reduxjs/toolkit";
import authReducer, { logout } from "./features/authSlice";
import { apiSlice } from "./api";
import { errorToast } from "../constants/toast";

export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {

    if (action?.payload?.data === "Invalid password or email" || action?.payload?.data === "Agency status is pending" || action?.payload?.data === "Agency status not approved") {

      return next(action)
    }


    if (action?.payload?.status === 401) {
      errorToast("Session Expired, Please Login Again");
      store.dispatch(logout());
    }
  }

  return next(action);
};

const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware, rtkQueryErrorLogger),
  devTools: true,
});

export default store;
