import React from "react";
import { useSelector } from "react-redux";
import { selectCurrentagency } from "../../../redux/features/authSlice";
import { useGetTransactionDetailsQuery } from "../../../redux/api/DealsPdf";
import MaterialReactTable from "material-react-table";
import { TRANSACTION_COLUMN } from "./dealsData";
import { useLocation } from "react-router-dom";

export const TransactionDetails = () => {
    const currentAgency = useSelector(selectCurrentagency);
    const token = localStorage.getItem('token');
    const location = useLocation();
    const pathParts = location.pathname.split('/');
    const dealId = pathParts[4];

    const { data, isLoading } = useGetTransactionDetailsQuery({
        agtid: currentAgency.agencyid,
        dealId: dealId,
        token: token
    });

    return (
        <div>
            <h1>
                All Transactions
            </h1>
            <MaterialReactTable
                state={{ showSkeletons: isLoading }}
                columns={TRANSACTION_COLUMN}
                data={data ?? []}
            />
        </div>
    );
};
