import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useSelector } from "react-redux";
import { selectCurrentagency } from "../../../../../redux/features/authSlice";
import { useGetAgentsQuery } from "../../../../../redux/api/leadsApi";

const DealBreakDown = ({
  internalAgents,
  setInternalAgents,
  externalAgents,
  setExternalAgents,
  agencyPercentage,
  setAgencyPercentage,
  dealPrice,
  defaultAgent
}) => {
  const [newAgentName, setNewAgentName] = useState("");
  const [newAgentPercentage, setNewAgentPercentage] = useState();
  const [agencyAmount, setAgencyAmount] = useState(0);

  const currentAgency = useSelector(selectCurrentagency);
  const { data: allAgents } = useGetAgentsQuery(currentAgency?.agencyid);

  const agents = allAgents || [];

  useEffect(() => {
    if (defaultAgent) {
      const selectedAgent = agents.find(
        (agent) => agent.agentid === parseInt(defaultAgent)
      );
      if (selectedAgent) {
        const isAgentAlreadyAdded = internalAgents.some(
          (agent) => agent.agentId === defaultAgent
        );
        if (!isAgentAlreadyAdded) {
          const newAgent = {
            agentId: defaultAgent,
            name: selectedAgent.agentname,
            percentage: 0,
            amount: 0,
          };
          setInternalAgents((prevAgents) => [...prevAgents, newAgent]);
        }
      }
    }
  }, [defaultAgent, agents, internalAgents, setInternalAgents]);


  const handleInternalAgentChange = (event) => {
    const newAgentId = event.target.value;
    const selectedAgent =
      allAgents &&
      allAgents.find((agent) => agent.agentid === parseInt(newAgentId));
    if (
      selectedAgent &&
      !internalAgents.find((agent) => agent.agentId === newAgentId)
    ) {
      const newAgent = {
        agentId: newAgentId,
        name: selectedAgent.agentname,
        percentage: 0,
        amount: 0,
      };
      setInternalAgents((prevAgents) => [...prevAgents, newAgent]);
      setExternalAgents((prevAgents) =>
        prevAgents.filter((agent) => agent.agentId !== newAgentId)
      );
    }
  };
  const handleAgentPercentageChange = (agentId, percentage, isInternal) => {
    const updatedAgents = (isInternal ? internalAgents : externalAgents).map((agent) =>
      agent.agentId === agentId || agent.id === agentId
        ? {
            ...agent,
            percentage: percentage,
            amount: (percentage / 100) * agencyAmount, // Calculate the amount based on percentage
          }
        : agent
    );
  
    if (isInternal) {
      setInternalAgents(updatedAgents);
    } else {
      setExternalAgents(updatedAgents);
    }
  };
  
  // When the deal price changes, update the amounts for all agents
  useEffect(() => {
    const updatedInternalAgents = internalAgents.map((agent) => ({
      ...agent,
      amount: (agent.percentage / 100) * agencyAmount,
    }));
    setInternalAgents(updatedInternalAgents);
  
    const updatedExternalAgents = externalAgents.map((agent) => ({
      ...agent,
      amount: (agent.percentage / 100) * agencyAmount,
    }));
    setExternalAgents(updatedExternalAgents);
  }, [agencyAmount, internalAgents, externalAgents]);
  
  const handleRemoveAgent = (agentId, isInternal) => {
    if (isInternal) {
      setInternalAgents((prevAgents) =>
        prevAgents.filter((agent) => agent.agentId !== agentId)
      );
    } else {
      setExternalAgents((prevAgents) =>
        prevAgents.filter((agent) => agent.id !== agentId)
      );
    }
  };

  const handleAddExternalAgent = () => {
    if (newAgentName.trim() !== "" && !isNaN(newAgentPercentage)) {
      const newAgentId =
        externalAgents.length > 0
          ? Math.max(...externalAgents.map((agent) => agent.id)) + 1
          : 1;
      const newAgent = {
        id: newAgentId,
        cName: newAgentName,
        percentage: newAgentPercentage,
      };
      setExternalAgents((prevAgents) => [...prevAgents, newAgent]);
      setNewAgentName("");
      setNewAgentPercentage(0);
    }
  };

  const handleAgencyPercentageChange = (value) => {
    const percentage = Number(value);
    setAgencyPercentage(percentage);
    setAgencyAmount((percentage / 100) * dealPrice);
  };

  const totalInternalPercentage = internalAgents.reduce(
    (total, agent) => total + agent.percentage,
    0
  );
  const totalExternalPercentage = externalAgents.reduce(
    (total, agent) => total + agent.percentage,
    0
  );

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Container maxWidth="md">
          <Typography variant="h3" mt={4} mb={2}>
            Deal Break Down
          </Typography>
          <Typography
            variant="body1"
            sx={{
              marginBottom: (theme) => theme.spacing(3),
              color: (theme) => theme.palette.text.secondary,
              lineHeight: 1.5,
            }}
          >
            Agency and Agent will be added to the deal as they are involved in a
            percentage of the deal price.
          </Typography>

          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent="space-between"
            my={2}
          >
            <Typography variant="h4" mb={2}>
              Agency Percentage
            </Typography>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              gap={2}
              width={{ xs: "100%", sm: "50%" }}
            >
              <TextField
                label="Agency Percentage"
                type="number"
                size="small"
                variant="outlined"
                value={agencyPercentage}
                onChange={(e) => handleAgencyPercentageChange(e.target.value)}
                fullWidth
              />
              <TextField
                label="Agency Amount"
                type="number"
                size="small"
                variant="outlined"
                value={agencyAmount}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }} 
            alignItems="center"
            justifyContent="space-between"
            mb={2}
            sx={{ width: "100%" }} 
          >
            <Typography variant="h4" sx={{ mb: { xs: 2, sm: 0 } }}>
              Internal Agent
            </Typography>
            <Select
              variant="outlined"
              size="small"
              value=""
              onChange={handleInternalAgentChange}
              displayEmpty
              sx={{ width: { xs: "100%", sm: 200 } }} 
              renderValue={() => "Select an Agent"}
            >
              {agents
                .filter(
                  (agent) =>
                    !internalAgents.find(
                      (selected) =>
                        selected.agentId === agent.agentid.toString()
                    )
                )
                .map((option) => (
                  <MenuItem
                    key={option.agentid}
                    value={option.agentid.toString()}
                  >
                    {option.agentname}
                  </MenuItem>
                ))}
            </Select>
          </Box>

          <Box mb={3}>
            {internalAgents.map((agent) => (
              <Box
                key={agent.agentId}
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }} 
                alignItems="center"
                mb={1}
                sx={{ width: "100%" }} 
              >
                <InputLabel
                  sx={{
                    mb: { xs: 1, sm: 0 },
                    mr: { sm: 2 },
                    width: { xs: "100%", sm: 120 },
                  }}
                >
                  {agent.name}
                </InputLabel>
                <TextField
                  value={agent.percentage}
                  onChange={(e) =>
                    handleAgentPercentageChange(
                      agent.agentId,
                      e.target.value,
                      true
                    )
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  type="number"
                  size="small"
                  inputProps={{
                    min: 0,
                    max: 100 - totalInternalPercentage,
                  }}
                  sx={{
                    width: { xs: "100%", sm: 100 },
                    textAlign: "center",
                    mb: { xs: 1, sm: 0 },
                  }}
                />
                <TextField
                  label="Amount"
                  value={agent.amount}
                  InputProps={{
                    readOnly: true,
                  }}
                  type="number"
                  size="small"
                  sx={{
                    width: { xs: "100%", sm: 100 },
                    textAlign: "center",
                    ml: { xs: 0, sm: 2 },
                  }}
                />
                 <IconButton
                  onClick={() => handleRemoveAgent(agent.agentId, true)}
                  sx={{
                    ml: { xs: 0, sm: 1 },
                    mt: { xs: 1, sm: 0 },
                    display:
                      agent.agentId === defaultAgent ? "none" : "inline-flex",
                  }}
                >
                  <IoMdClose size={17} />
                </IconButton>
              </Box>
            ))}
          </Box>

          <Box
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }} // Column on small screens, row on larger screens
            alignItems="center"
            justifyContent="space-between"
            mb={2}
            sx={{ width: "100%" }} // Full width on all screens
          >
            <Typography variant="h4" sx={{ mb: { xs: 2, sm: 0 } }}>
              External Agent
            </Typography>
            <Box display="flex" flexWrap="wrap" alignItems="center">
              <TextField
                label="Agent Name"
                size="small"
                value={newAgentName}
                onChange={(e) => setNewAgentName(e.target.value)}
                sx={{
                  width: { xs: "100%", sm: 150 },
                  mr: { xs: 0, sm: 2 },
                  mb: { xs: 2, sm: 0 },
                }}
              />
              <TextField
                label="Percentage"
                value={newAgentPercentage === 0 ? "" : newAgentPercentage}
                onChange={(e) => setNewAgentPercentage(e.target.value)}
                type="number"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                inputProps={{
                  min: 0,
                  max: 100 - totalExternalPercentage,
                }}
                sx={{
                  width: { xs: "100%", sm: 150 },
                  textAlign: "center",
                  mr: { xs: 0, sm: 2 },
                }}
              />
              <Button
                onClick={handleAddExternalAgent}
                variant="contained"
                sx={{
                  backgroundColor: "#56C662",
                  marginTop: "10px",
                  color: "white",
                  width: { xs: "100%", sm: "auto" }, // Full width on small screens
                }}
              >
                Add
              </Button>
            </Box>
          </Box>

          <Box>
            {externalAgents.map((agent) => (
              <Box
                key={agent.id}
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }} // Column on small screens, row on larger screens
                alignItems="center"
                mb={1}
                sx={{ width: "100%" }} // Full width on all screens
              >
                <InputLabel
                  sx={{
                    mb: { xs: 1, sm: 0 },
                    mr: { sm: 2 },
                    width: { xs: "100%", sm: 120 },
                  }}
                >
                  {agent.cName}
                </InputLabel>
                <TextField
                  value={agent.percentage}
                  onChange={(e) =>
                    handleAgentPercentageChange(agent.id, e.target.value, false)
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  type="number"
                  size="small"
                  sx={{
                    width: { xs: "100%", sm: 100 },
                    textAlign: "center",
                    mb: { xs: 1, sm: 0 },
                  }}
                  inputProps={{
                    min: 0,
                    max: 100 - totalExternalPercentage,
                  }}
                />
                <TextField
                  label="Amount"
                  value={agent.amount}
                  InputProps={{
                    readOnly: true,
                  }}
                  type="number"
                  size="small"
                  sx={{
                    width: { xs: "100%", sm: 100 },
                    textAlign: "center",
                    ml: { xs: 0, sm: 2 },
                  }}
                />
                <IconButton
                  onClick={() => handleRemoveAgent(agent.id, false)}
                  sx={{ ml: { xs: 0, sm: 1 }, mt: { xs: 1, sm: 0 } }} // Margin-left and margin-top adjust based on screen size
                >
                  <IoMdClose size={17} />
                </IconButton>
              </Box>
            ))}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default DealBreakDown;
