import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, RadioGroup, FormControlLabel, Radio, colors } from "@mui/material";
import { useSelector } from "react-redux";
import { useEditLeadPoolMutation, useGetAgentsQuery, useGetLeadsPoolQuery } from "../../redux/api/leadsApi";
import { selectCurrentagency } from "../../redux/features/authSlice";
import styled from "styled-components";
import { LeadTable } from "./LeadTable";
import { LEADS_POOL_COLUMNS } from "./leadsData";

export default function LeadPool() {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedAgentId, setSelectedAgentId] = useState(null);
  const currentAgency = useSelector(selectCurrentagency);
  const [selectedLeads, setSelectedLeads] = useState([]); // Store selected leads
  const { data: agencyLeadsPool, error, isLoading: LeadsLoading, refetch } = useGetLeadsPoolQuery({ agencyId: currentAgency?.agencyid });
  const { data: allAgents } = useGetAgentsQuery(currentAgency?.agencyid);
  const [editLeadPool] = useEditLeadPoolMutation();

  const onChangeAction = (e, leads) => {
    if (e.target.value === 0) {
      // Open agent selection dialog
      setDialogOpen(true);
      setSelectedLeads(leads); // Store selected leads when opening the dialog
    }
  };
  const handleAssign = () => {
    if (selectedAgentId) {
      const formattedLeads = selectedLeads.map((lead) => {
        // const leadId = lead.Properties[0]?.leadid; 
        return {
          leadSerialNo: lead.leadSerialNo, 
          leadid: lead.leadid, 
          userid: lead.userid,
          propertyid: lead.propertyid,
          description: lead.description,
          leadtype: lead.leadtype,
          leadTime: lead.leadTime,
          agentid: selectedAgentId, 
          agencyid: currentAgency?.agencyid,
          leadTag: lead.leadTag,
          leadStatus: lead.leadStatus,
          sourceId: lead.sourceId,
          mediumId: lead.mediumId,
          priorityId: lead.priorityId,
          companyName: lead.companyName,
          designation: lead.designation,
          budget: lead.budget,
          location: lead.location,
        };
      });
      editLeadPool(formattedLeads)
        .unwrap()
        .then((response) => {
          refetch()
          setDialogOpen(false);
        })
        .catch((error) => {
          console.error("Error updating leads", error);
        });
    }
  };

  return (
    <div>
      <StyledTop>
        <StyledButton size="small" variant="contained" sx={{color: "white"}}>All Pool Leads</StyledButton>
      </StyledTop>

      <LeadTable
        key={2}
        columns={LEADS_POOL_COLUMNS}
        leads={agencyLeadsPool || []}
        isLoading={LeadsLoading}
        allAgents={allAgents}
        onChangeAction={onChangeAction}
      />

      <Dialog open={isDialogOpen} onClose={() => setDialogOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Select Agent</DialogTitle>
        <DialogContent>
          <RadioGroup value={selectedAgentId} onChange={(e) => setSelectedAgentId(e.target.value)}>
            {allAgents && allAgents.map((agent) => (
              <FormControlLabel key={agent.agentid} value={agent.agentid?.toString()} control={<Radio />} label={agent.agentname} />
            ))}
          </RadioGroup>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleAssign} disabled={!selectedAgentId}>Assign</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const StyledButton = styled(Button)(({ theme }) => ({
  marginRight: "8px",
}));

const StyledTop = styled("div")(({ theme }) => ({
  marginTop: "16px",
  marginBottom: "16px",
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  rowGap: "20px",
}));
