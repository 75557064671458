import { toast } from "react-toastify";
import { apiSlice } from ".";
const token = localStorage.getItem("token");

const leadsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLeadAgents: builder.query({
      query: (agcid) => `/LeadTables/AgentInfoByAgencyID?agcid=${agcid}`,
    }),
    // getLeads: builder.query({
    //   query: (agcid) => `/LeadTables/GetLeadsGroupedByUserIdAgentId?agcid=${agcid}`,
    // }),

    getLeads: builder.query({
      query: (agcid) =>
        `/LeadTables/GetLeadsGroupedByUserIdAgentId2_Waleed?agtid={agtid}&agcid=${agcid}&subid={subid}&cid={cid}&source={source}&medium={medium}&status={status}&leadTyp={leadTyp}&priorityid={priorityid}&page=1&pageSize=2`,
    }),
    // getLeads: builder.query({
    //   query: (agcid) =>
    //     `/LeadTables/GetLeadsGroupedByUserIdAgentId2?agcid=${agcid}`,
    // }),

    getFromAppointmentPropertiesId: builder.query({
      query: ({ token, leadId, agtid }) => ({
        url: `/LeadHistoryTables/GetLeadHistoryTable/${leadId}`,
        method: "GET",
        headers: {
          token: `Bearer ${token}`,
          agtid: agtid,
        },
      }),
    }),
    getLeadsHistory: builder.query({
      query: ({ token, leadId, userid }) => ({
        url: `/LeadHistoryTables/GetLeadHistoryTable/${leadId}`,
        method: "GET",
        headers: {
          token: `Bearer ${token}`,
          userid: userid,
        },
      }),
    }),

    getViewedProperties: builder.query({
      query: (userPropIds) => `PropertyFilter/Gettt?userPropIds=${userPropIds}`,
    }),

    getPropertyDetailsWithImages: builder.query({
      query: (propid) => `/PropertyFilter/Gettt?propid=${propid}`,
    }),

    getAgents: builder.query({
      query: (agencyId) => `/Agent/GetAgentbyAgencyID/${agencyId}`,
      providesTags: ["Agents"],
    }),

    getSuggestedProperties: builder.query({
      query: ({
        agcid,
        agtid,
        MinBed,
        MaxBed,
        MinBath,
        MaxBath,
        MinPropSize,
        MaxPropSize,
      }) =>
        `/PropertyFilter/Gettt?agcid=${agcid}&agtid=${agtid}&MinBed=${MinBed}&MaxBed=${MaxBed}&MinBath=${MinBath}&MaxBath=${MaxBath}&MinPropSize=${MinPropSize}&MaxPropSize=${MaxPropSize}`,
    }),

    postLeadStatus: builder.mutation({
      query: (data) => ({
        url: "/LeadHistoryTables/PostLeadHistoryTable",
        method: "POST",
        body: data,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        toast.promise(queryFulfilled, {
          pending: "Updating Lead Status",
          success: "Lead status has been updated",
          error: "Error updating Lead status",
        });
      },
    }),

    postDealStatus: builder.mutation({
      query: (data) => ({
        url: "/dealTransactionTables/BulkPostRequest",
        method: "POST",
        body: data,
        headers: {
          Authorization: `Bearer ${token}`,
          userId: data.userid,
        },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        toast.promise(queryFulfilled, {
          pending: "Updating Lead Status",
          success: "Lead status has been updated",
          error: "Error updating Lead status",
        });
      },
    }),

    postLead: builder.mutation({
      query: (data) => ({
        url: "/LeadTables/PostleadAndHistory",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["PropertyLeads", "ExclusiveLeads"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        toast.promise(queryFulfilled, {
          pending: "Adding Lead",
          success: "Lead Added",
          error: "Error Adding Lead",
        });
      },
    }),
    postLeadToPool: builder.mutation({
      query: (data) => ({
        url: "/LeadTables/PostLeadAndHistoryBulk",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["PropertyLeads", "ExclusiveLeads"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        toast.promise(queryFulfilled, {
          pending: "Adding Lead Pool",
          success: "Lead Pool Added",
          error: "Error Adding Lead Pool",
        });
      },
    }),

    editLeadPool: builder.mutation({
      query: (data) => ({
        url: `/LeadTables/PutLeadTableBulk`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["PropertyLeads", "ExclusiveLeads"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        toast.promise(queryFulfilled, {
          pending: "Editing Lead",
          success: "Lead Edited",
          error: "Error Editing Lead",
        });
      },
    }),

    getLeadById: builder.query({
      query: (id) => `LeadTables/GetLeadTable/${id}`,
    }),
    getLeadsPool: builder.query({
      query: ({agencyId}) => `LeadTables/GetLeadsGroupedByUserIdAgentId2GroupedWithoutAgent?agtid={agtid}&agcid=${agencyId}&subid={subid}&cid={cid}&source={source}&medium={medium}&status={status}&leadTyp={leadTyp}&priorityid={priorityid}&page={page}&pageSize={pageSize}`,
    }),

    getLeadByPhoneNumber: builder.query({
      query: (id) => `/UserProfiles/UserChecking?phone=${id}`,
    }),

    editLead: builder.mutation({
      query: (data) => ({
        url: `/LeadTables/PutLeadTable/${data.leadid}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["PropertyLeads", "ExclusiveLeads"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        toast.promise(queryFulfilled, {
          pending: "Editing Lead",
          success: "Lead Edited",
          error: "Error Editing Lead",
        });
      },
    }),
    updateInstallment: builder.mutation({
      query: (data) => ({
        url: `/dealTransactionTables/PutdealTransactionTable/${data.amounttransactionID}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${token}`,
          userid: data.agencyId,
        },
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        toast.promise(queryFulfilled, {
          pending: "Updating installment Plan",
          success: "Installment Plan updated",
          error: "Error Updating Installment Plan",
        });
      },
    }),

    leadStatus: builder.query({
      query: () => `/StatusTables/GetStatusTables`,
    }),
    leadMedium: builder.query({
      query: () => `/MediumTables/GetMediumTables`,
    }),
    leadSource: builder.query({
      query: () => `/SourceTables/GetSourceTables`,
    }),

    leadType: builder.query({
      query: () => `/LeadTypeTables/GetLeadTypeTables`,
    }),
    leadPriority: builder.query({
      query: () => `/PriorityTables/GetPriorityTables`,
    }),
    dealInstallments: builder.query({
      query: () => `/InstallmentPlanTables/GetInstallmentPlanTables`,
    }),
   
  }),
});

export const {
  useGetLeadAgentsQuery,
  useGetLeadsQuery,
  useGetLeadsHistoryQuery,
  useGetLeadByPhoneNumberQuery,
  usePostDealStatusMutation,
  useGetViewedPropertiesQuery,
  useGetAgentsQuery,
  useGetFromAppointmentPropertiesIdQuery,
  useGetPropertyDetailsWithImagesQuery,
  useGetSuggestedPropertiesQuery,
  usePostLeadStatusMutation,
  usePostLeadMutation,
  useGetLeadByIdQuery,
  useEditLeadMutation,
  useUpdateInstallmentMutation,
  useLeadStatusQuery,
  useLeadMediumQuery,
  useLeadSourceQuery,
  useLeadTypeQuery,
  useLeadPriorityQuery,
  useDealInstallmentsQuery,
  usePostLeadToPoolMutation,
  useGetLeadsPoolQuery,
  useEditLeadPoolMutation
} = leadsApi;
