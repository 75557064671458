import React, { useEffect } from "react";
import { useLazyGetAgencyQuery } from "../redux/api/authApi";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { selectCurrentagency } from "../redux/features/authSlice";

export default function RequireAuth({ children }) {
  const agency = useSelector(selectCurrentagency);
  const agencyId = useSelector((state) => state.auth.agencyId);

  const [getAgency, { data, isLoading, error, isSuccess }] = useLazyGetAgencyQuery();

  const navigate = useNavigate();

  useEffect(() => {
    if (agencyId) {
      getAgency(agencyId);
    } else {
      navigate("/login");
    }
  }, [agencyId]);

  if (isLoading || !agency) {
    return (
      <Box sx={{ width: "100%", height: "100vh", display: "grid", placeItems: "center" }}>
        <CircularProgress sx={{ margin: "auto" }} />
      </Box>
    );
  }

  return children;
}
