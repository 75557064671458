import dayjs from "dayjs";
import { matchIsValidTel } from "mui-tel-input";

export const agentDefaultValues = {
  agentregno: "",
  agentname: "",
  agentemail: "",
  agentpassword: "",
  agentphone: "",
  agentdescription: "",
  agentcareerstarting: dayjs(new Date()),
  cityid: null,
  agentimage: "",
  nationality: null,
  nicimg: null,
  agentLanguages: [],

  // Supportive
  countryid: "",
  provid: "",
};

export const agentRules = {
  agentregno: { required: "Agent Registration No Required" },
  agentname: {
    required: "Agent Name is Required",
  },
  agentemail: {
    required: "Agent Email is Required",
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "Invalid email address",
    },
  },
  agentpassword: {
    required: "Agent Password is Required",
  },
  agentphone: {
    validate: {
      phone: (value) => {
        return matchIsValidTel(value) || "Invalid phone number";
      },
    },
  },
  agentdescription: {
    required: "Agent Description is Required",
    minLength: {
      value: 10,
      message: "Agent Description should have at least 10 characters",
    },
    maxLength: {
      value: 500,
      message: "Agent Description should not exceed 500 characters",
    },
  },
  agentcareerstarting: {
    required: "Career Starting Date Required",
  },

  cityid: {
    required: "City ID is Required",
  },
  agentimage: {
    required: "Agent Image is Required",
  },
  nationality: {
    required: "Nationality is Required",
  },
  nicimg: {},
  agentLanguages: { required: "Select one language at least" },

  //Supportive
  countryid: {
    required: "Country must be selected",
  },
  provid: {
    required: "Province/State must be selected",
  },
};
