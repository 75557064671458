import { Link } from "react-router-dom";
import { PROPERTY_IMAGES_URL } from "../../constants/url";
import { EDIT_PROPERTY_ROUTE } from "../../constants/routes";
import { formatCurrency } from "../../constants/currency";
import { Chip } from "@mui/material";
import { PROPERTY_STATUSES } from "../../constants/property";

export const AGENCY_PROPERTY_COLUMNS = [
  {
    header: "Picture",
    accessor: "propertyimages",
    Cell: ({ row }) => (
      <img
        src={PROPERTY_IMAGES_URL + row.original.propertyimages}
        alt={"property"}
        style={{ width: "50px", height: "50px" }}
      />
    ),
    size: 30,
  },
  {
    accessorKey: "propertyrefnum",
    header: "Ref No",
    size: 20,
    Cell: ({ row }) => (
      <Chip
        sx={{ cursor: "pointer" }}
        size="small"
        variant="filled"
        component={Link}
        to={EDIT_PROPERTY_ROUTE + row.original.propertyid}
        color={PROPERTY_STATUSES.find((item) => item.id === row.original.pstatus)?.color}
        label={row.original.propertyrefnum}
      />
    ),
  },
  { accessorKey: "propertypurpose1", header: "Purpose", size: 30 },
  { accessorKey: "propertytype1", header: "Type", size: 30 },
  {
    accessorKey: "propertyprice",
    filterFn: "between",
    muiTableHeadCellProps: ({ column }) => ({
      sx: {
        textAlign: "center",
      },
    }),
    header: "Price",
    size: 30,
    Cell: ({ row }) => (
      <div style={{ textAlign: "center" }}>
        {formatCurrency(row.original.propertyprice, row.original.countryid)}
      </div>
    ),
  },
  { accessorKey: "bedrooms", filterFn: "between", header: "Beds", size: 30 },
  { accessorKey: "bathrooms", header: "Baths", filterFn: "between", size: 30 },
  { accessorKey: "cityname", header: "City", size: 30 },
  { accessorKey: "agentname", header: "Agent", size: 20 },
  { accessorKey: "propertytitle", header: "Title", size: 30 },
  {
    accessorKey: "propertyaddress",
    header: "Sub Location",
    size: 30,
    Cell: ({ row }) => {
      return (
        <a href={row.original.coordinates} target="_blank" rel="noreferrer">
          {row.original?.sublocation || row.original?.propertyaddress || "show"}
        </a>
      );
    },
  },
  { accessorKey: "propertysizesqft", header: "Size (Sqft)", size: 30 },
  { accessorKey: "ownfirstname", header: "Owner Name", size: 30 },
  { accessorKey: "mobileno", header: "Owner Contact", size: 30 },
];

export const AGENCY_LEAD_PROPERTY_COLUMNS = [
  {
    header: "Picture",
    accessor: "propertyimages",
    Cell: ({ row }) => (
      <img
        src={PROPERTY_IMAGES_URL + row.original.propertyimages}
        alt={"property"}
        style={{ width: "50px", height: "50px" }}
      />
    ),
    size: 30,
  },
  {
    accessorKey: "propertyrefnum",
    header: "Ref No",
    size: 20,
    Cell: ({ row }) => (
      <Chip
        sx={{ cursor: "pointer" }}
        size="small"
        variant="filled"
        component={Link}
        to={''}
        color={PROPERTY_STATUSES.find((item) => item.id === row.original.pstatus)?.color}
        label={row.original.propertyrefnum}
      />
    ),
  },
  { accessorKey: "propertypurpose1", header: "Purpose", size: 30 },
  { accessorKey: "propertytype1", header: "Type", size: 30 },
  {
    accessorKey: "propertyprice",
    filterFn: "between",
    muiTableHeadCellProps: ({ column }) => ({
      sx: {
        textAlign: "center",
      },
    }),
    header: "Price",
    size: 30,
    Cell: ({ row }) => (
      <div style={{ textAlign: "center" }}>
        {formatCurrency(row.original.propertyprice, row.original.countryid)}
      </div>
    ),
  },
  { accessorKey: "bedrooms", filterFn: "between", header: "Beds", size: 30 },
  { accessorKey: "bathrooms", header: "Baths", filterFn: "between", size: 30 },
  { accessorKey: "cityname", header: "City", size: 30 },
  { accessorKey: "agentname", header: "Agent", size: 20 },
  { accessorKey: "propertytitle", header: "Title", size: 30 },
  {
    accessorKey: "propertyaddress",
    header: "Sub Location",
    size: 30,
    Cell: ({ row }) => {
      return (
        <a href={row.original.coordinates} target="_blank" rel="noreferrer">
          {row.original?.sublocation || row.original?.propertyaddress || "show"}
        </a>
      );
    },
  },
  { accessorKey: "propertysizesqft", header: "Size (Sqft)", size: 30 },
  { accessorKey: "ownfirstname", header: "Owner Name", size: 30 },
  { accessorKey: "mobileno", header: "Owner Contact", size: 30 },
];
