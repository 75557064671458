import { apiSlice } from ".";

export const agencyApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    editAgency: builder.mutation({
      query: (data) => ({
        url: `/Agency/PutAgencyTable/${data.agencyid}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Agency"],
    }),
  }),
});

export const { useEditAgencyMutation } = agencyApi;
