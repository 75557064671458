import React, { useEffect, useState } from "react";
import AutoComplete from "../FormElements/AutoComplete";
import TextField from "../FormElements/TextField";
import { propertyRules } from "./propertyValidation";
import { useLazyGetCitiesByProvinceIdQuery } from "../../redux/api/citiesApi";
import { useGetStatesByCountryIdQuery } from "../../redux/api/statesApi";
import { useSelector } from "react-redux";
import { selectCurrentagency } from "../../redux/features/authSlice";
import { Controller, useFormContext } from "react-hook-form";
import { useLazyGetSubLocationsByCityIdQuery } from "../../redux/api/sublocationApi";
import MapModel from "./MapModel";
import { Button } from "@mui/material";

export default function PropertyLocationPicker() {
  const currentAgent = useSelector(selectCurrentagency);
  const [toggleMap, setToggleMap] = useState(false);
  const [googleMapsLink, setGoogleMapsLink] = useState(null);
  const { watch, setValue } = useFormContext();
  const watchStateId = watch("provid");
  const watchCityId = watch("cityid");
  const { data: states, isFetching: isFetchingStates } =
    useGetStatesByCountryIdQuery(currentAgent.countryid);
  const [getCities, { data: cities, isFetching: isFetchingCities }] =
    useLazyGetCitiesByProvinceIdQuery();

  const [
    getSubLocations,
    { data: subLocations, isFetching: isFetchingSubLocations },
  ] = useLazyGetSubLocationsByCityIdQuery();

  useEffect(() => {
    if (watchStateId) {
      getCities(watchStateId);
    }
  }, [watchStateId]);

  useEffect(() => {
    if (watchCityId) {
      getSubLocations(watchCityId);
    }
  }, [watchCityId]);
  const handleMapLocationSelect = (link) => {
    setGoogleMapsLink(link);
    setValue("coordinates", link);
  };
  return (
    <>
      <AutoComplete
        isLoading={isFetchingStates}
        name="provid"
        label="State/ province"
        optionId="provid"
        optionLabel="provname"
        options={states}
        rules={propertyRules.stateId}
      />

      <AutoComplete
        isLoading={isFetchingCities}
        name="cityid"
        options={cities}
        optionId="cityid"
        optionLabel="cityname"
        disabled={!watchStateId}
        label="City"
        rules={propertyRules.cityid}
      />

      <AutoComplete
        isLoading={isFetchingSubLocations}
        name="sublocid"
        options={subLocations}
        optionId="sublocid"
        optionLabel="sublocation"
        disabled={!watchCityId}
        label="Sub Location"
        rules={propertyRules.sublocid}
      />

      <TextField
        name="buildingno"
        label="Building no."
        rules={propertyRules.buildingno}
      />

      <TextField name="unitno" label="Unit no." rules={propertyRules.unitno} />
      <Controller
        name="coordinates"
        render={({ field }) => (
          <TextField
            {...field}
            label="Google Maps Link (https://goo.gl/maps/...)"
            value={googleMapsLink || field.value} // Use the state if available, otherwise use field value
            onChange={(e) => setGoogleMapsLink(e.target.value)}
            onBlur={() => setValue("coordinates", googleMapsLink)} 
            rules={propertyRules.coordinates}
          />
        )}
      />
      <Button
        onClick={() => setToggleMap(true)}
        variant="contained"
        sx={{
          color: "white",
          height: "40px"
        }}
      >
        Select Location
      </Button>

      <MapModel toggleMap={toggleMap} setToggleMap={setToggleMap} setGoogleMapsLink={handleMapLocationSelect} />
    </>
  );
}
