import React, { useMemo } from "react";

import { useParams } from "react-router-dom";
import PropertyForm from "../../components/PropertyForm";
import { Box, CircularProgress } from "@mui/material";
import { useGetPropertyByIdQuery, useGetPropertyImagesQuery } from "../../redux/api/propertiesApi";

export default function EditProperty() {
  const { propertyId } = useParams();
  const { data: propertyData, isFetching } = useGetPropertyByIdQuery(propertyId, {
    refetchOnMountOrArgChange: true,
  });
  const { data: images, isFetching: isFetchingImages } = useGetPropertyImagesQuery(propertyId, {
    refetchOnMountOrArgChange: true,
  });

  const allData = propertyData && {
    ...propertyData[0],
  };

  const imagesData = useMemo(() => {
    return (
      images &&
      images.map((item) => ({
        id: item.propertyimageid,
        url: item.propertyimages,
        description: item.tag,
      }))
    );
  }, [images]);

  if (!propertyId) return <div>Property not found</div>;

  if (isFetching || isFetchingImages)
    return (
      <div style={{ height: "100%", width: "100%", display: "grid", placeItems: "center" }}>
        <CircularProgress />
      </div>
    );

  return (
    <Box sx={{ maxWidth: "1600px", mx: "auto" }}>
      <PropertyForm propertyData={allData} imagesData={imagesData} />
    </Box>
  );
}
