import { PAKISTAN_ID, UAE_ID } from "./countries";

const AED_CURRENCY = new Intl.NumberFormat("en-AE", {
  style: "currency",
  currency: "AED",
  maximumFractionDigits: 0
});

const PAK_CURRENCY = new Intl.NumberFormat("ar-PK", {
  style: "currency",
  currency: "PKR",
  maximumFractionDigits: 0
});

const countryCurrencies = {
  [UAE_ID]: AED_CURRENCY,
  [PAKISTAN_ID]: PAK_CURRENCY,
};

export const formatCurrency = (value, countryId) => {
  if (!countryId || !countryCurrencies[countryId]) return value;

  return countryCurrencies[countryId].format(value);
};
