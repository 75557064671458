import { Close, ContentCopy, ExpandMore, WhatsApp } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import { PROPERTY_IMAGES_URL, PROPERTY_PDFS_URL } from "../../constants/url";
import useUploadPdf from "../../hooks/useUploadPdf";
import { toast } from "react-toastify";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function SharePdfModal({ isOpen, onClose, properties }) {
  const noPdfProperties = properties.filter((property) => !property.documentfile);
  const pdfProperties = properties.filter((property) => property.documentfile);
  const [isLoading, setIsLoading] = useState(false);
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const propertyPdfUploader = useUploadPdf();

  const downloadPdf = async (property) => {
    const url = PROPERTY_PDFS_URL + property.documentfile;
    const toastId = toast.loading(`Downloading Pdf for ${property.propertyrefnum}...`);
    setIsLoading(true);
    try {
      const pdfFile = await fetch(url);
      const pdfBlob = await pdfFile.blob();

      const link = document.createElement("a");
      link.href = URL.createObjectURL(pdfBlob);
      link.download = property.propertyrefnum + ".pdf";
      link.click();
    } catch (error) {
      toast.update(toastId, {
        render: "Failed to download Pdf, please try again",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    } finally {
      toast.update(toastId, {
        render: "Pdf Downloaded",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      setIsLoading(false);
    }
  };

  const generatePdf = async (propertyId) => {
    await propertyPdfUploader.upload(propertyId);
  };

  const onShare = (type) => {
    const availablePdfLinks = pdfProperties.map((property) => {
      return `Ref No: ${property.propertyrefnum} \n ${PROPERTY_PDFS_URL}${property.documentfile} \n\n`;
    });

    const text = `Check out these pdfs of properties:  \n\n ${availablePdfLinks.join("")}`;

    if (type === "whatsapp") {
      const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(text)}`;
      window.open(url, "_blank");
      return;
    }

    navigator.clipboard.writeText(text);
    setIsLinkCopied(true);
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <StyledContainer>
        <IconButton sx={{ position: "absolute", right: 8, top: 8 }} onClick={onClose}>
          <Close />
        </IconButton>
        <Typography variant="h2">Share Pdf</Typography>
        <Divider orientation="horizontal" sx={{ my: 1 }} />

        <StyledWrapper>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h3">Available Pdfs ({pdfProperties.length})</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <StyledPdfsContainer>
                {pdfProperties.map((property) => (
                  <StyledPropertyItem key={property.propertyid}>
                    <img src={PROPERTY_IMAGES_URL + property.propertyimages} alt="property " />
                    <StyledPropertyDetails>
                      <Typography variant="body2" noWrap>
                        {property.propertytitle}
                      </Typography>
                      <Typography variant="h5">{property.propertyrefnum}</Typography>
                    </StyledPropertyDetails>

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Button
                        variant="contained"
                        size="small"
                        disabled={propertyPdfUploader.isLoading || isLoading}
                        onClick={() => downloadPdf(property)}
                      >
                        Download
                      </Button>

                      <Button
                        size="small"
                        variant="text"
                        disabled={propertyPdfUploader.isLoading || isLoading}
                        onClick={() => generatePdf(property.propertyid)}
                      >
                        Regenerate
                      </Button>
                    </Box>
                  </StyledPropertyItem>
                ))}
              </StyledPdfsContainer>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h3">Un Available Pdfs ({noPdfProperties.length})</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <StyledPropertiesContainer>
                {noPdfProperties.map((property) => (
                  <StyledPropertyItem key={property.propertyid}>
                    <img src={PROPERTY_IMAGES_URL + property.propertyimages} alt="property " />

                    <StyledPropertyDetails sx={{ flex: 1, minWidth: 0 }}>
                      <Typography variant="body2" noWrap>
                        {property.propertytitle}
                      </Typography>
                      <Typography variant="h5">{property.propertyrefnum}</Typography>
                    </StyledPropertyDetails>

                    <Button
                      variant="contained"
                      disabled={propertyPdfUploader.isLoading || isLoading}
                      onClick={() => generatePdf(property.propertyid)}
                    >
                      Generate
                    </Button>
                  </StyledPropertyItem>
                ))}
              </StyledPropertiesContainer>
            </AccordionDetails>
          </Accordion>
        </StyledWrapper>

        <StyledFooter>
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            Share Links to Available Pdfs via
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "center", gap: 2, alignItems: "center" }}>
            <IconButton color="success" onClick={() => onShare("whatsapp")}>
              <WhatsApp />
            </IconButton>
            <Typography>OR</Typography>
            <Button
              variant="text"
              size="small"
              color="secondary"
              onClick={() => onShare("clipboard")}
              endIcon={<>{isLinkCopied ? <CheckCircleIcon /> : <ContentCopy />}</>}
            >
              {isLinkCopied ? "Copied" : "Copy Link"}
            </Button>
          </Box>
        </StyledFooter>
      </StyledContainer>
    </Modal>
  );
}

const StyledContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: "600px",
  height: "500px",
  overflow: "hidden",
  backgroundColor: "white",
  border: 0,
  outline: 0,
  padding: 16,
  borderRadius: 8,
  display: "flex",
  flexDirection: "column",
}));

const StyledWrapper = styled("div")(({ theme }) => ({
  overflowY: "auto",
}));

const StyledPdfsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 16,
}));

const StyledPropertiesContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 16,
}));

const StyledPropertyItem = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 8,

  "& img": {
    width: 60,
    aspectRatio: 14 / 9,
  },
}));

const StyledPropertyDetails = styled("div")(({ theme }) => ({
  flex: 1,
  minWidth: 0,
}));

const StyledFooter = styled("div")(({ theme }) => ({
  padding: 8,
  marginTop: "auto",
  borderTop: `1px solid ${theme.palette.divider}`,
}));
