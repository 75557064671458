import {  MdOutlineKingBed } from "react-icons/md";
import Loader from "../../../components/loader/Loader";
import Carousel from "react-material-ui-carousel";
import { useGetPropertyDetailsWithImagesQuery, } from "../../../redux/api/leadsApi";
// import { selectCurrentAgent } from '../../redux/features/authSlice';
import { AGENCY_LOGOS_URL, AGENT_IMAGES_URL, PROPERTY_IMAGES_URL } from "../../../constants/url";
import { LuAreaChart, LuBath } from "react-icons/lu";
import { Paper } from "@mui/material";
import { useSelector } from "react-redux";
import DescriptionText from "../../../components/Description";
import { useLeadsData } from "../../../hooks/useContext/leadsData";


const LeadDetails = () => {
    const currentPath = window.location.pathname;
    const segments = currentPath.split("/");
    const agentId = segments[3];
    const userId = segments[5];
    const leadId = segments[segments.length - 1];
    // const currentAgent = useSelector(selectCurrentAgent);

    //Get all leads
    const { agencyLeads: leadsData, LeadsLoading } = useLeadsData();


    // Get leads details through agent id
    const filterAgentId = leadsData?.filter((item) => item.agentid === parseInt(agentId));


    // Filter user through userId
    let filteredData = filterAgentId && filterAgentId.filter(item => item.userid === parseInt(userId));
    // // Filter leads through leadId
    const leads = filteredData ? filteredData.find(lead => lead.leadid === parseInt(leadId)) : null;

    // //Get Property Images
    const propertyData = useGetPropertyDetailsWithImagesQuery(leads?.propertyid);
    const propertyImages = propertyData.data?.Properties?.[0]?.propertyimages ?? [];


    const bedrooms = leads?.bedrooms === -1 ? "Studio" : leads?.bedrooms;

    return (
        <>
            <div>
                <div className="w-[90%] h-auto m-auto">
                    <div className="w-full h-auto m-auto overflow-hidden">
                        <Carousel>
                            {
                                propertyData.isLoading ? (
                                    <div><Loader /></div>
                                ) : (
                                    propertyImages?.map((item, index) => (
                                        <Item key={index} image={PROPERTY_IMAGES_URL + item} />
                                    ))
                                )
                            }
                        </Carousel>
                    </div>
                    <div className="w-[90%] m-auto">
                        <h3 className="mt-5 ">Price {leads?.propertyprice}</h3>
                        <div className="flex items-center w-full">
                            <h3 className="text-green uppercase font-bold my-5 mr-5 text-xl w-full md:w-[70%]">{leads?.propertytitle}</h3>
                            {/* <div className="md:block hidden p-2 py-4 justify-center w-[30%] items-center border-[1px] border-customGray shadow-sm rounded-xl">
                                <div className="w-full flex items-center gap-5 mb-3 overflow-hidden rounded-md">
                                    <img className="w-10 h-10 rounded-full " src={AGENT_IMAGES_URL + currentAgent.agentimage} alt="" />
                                    <p className="font-bold text-sm text-gray-500">{currentAgent?.agentname}</p>
                                </div>
                                <div className="w-full flex items-center gap-5 overflow-hidden rounded-md">
                                    <img className="w-10 h-10 rounded-full " src={AGENCY_LOGOS_URL + currentAgent.agencylogo} alt="" />
                                    <p className="font-bold text-sm text-gray-500">{currentAgent?.agencyname}</p>
                                </div>
                            </div> */}
                        </div>
                        <div className="sm:flex">
                            <p className="sm:mx-2.5 flex justify-between items-center"><span className="m-1 sm:m-2.5 flex items-center"><LuAreaChart className="mx-2" size={16} /> Marla:</span> <span>{leads?.propertysizemarla}</span></p>
                            <p className="sm:mx-2.5 flex justify-between items-center"><span className="m-1 sm:m-2.5 flex items-center"><MdOutlineKingBed className="mx-2" size={20} /> Beds: </span> <span>{bedrooms}</span></p>
                            <p className="sm:mx-2.5 flex justify-between items-center"><span className="m-1 sm:m-2.5 flex items-center"><LuBath className="mx-2" size={16} /> Baths:</span> <span>{leads?.bathrooms}</span></p>
                        </div>
                        <div className="w-full">
                            <h3 className="font-bold my-5 text-lg ">Property Details</h3>
                            <div className="flex flex-wrap flex-col sm:flex-row sm:justify-between">
                                <p className="w-[100%] sm:w-[40%] my-3 flex justify-between"><span>Reference no</span> {leads?.propertyrefnum}</p>
                                <p className="w-[100%] sm:w-[40%] my-3 flex justify-between"><span>Added on</span> {leads?.leadTime?.split("T")[0]}</p>
                                <p className="w-[100%] sm:w-[40%] my-3 flex justify-between"><span>Bedrooms</span> {bedrooms}</p>
                                <p className="w-[100%] sm:w-[40%] my-3 flex justify-between"><span>Bathrooms</span> {leads?.bathrooms}</p>
                                <p className="w-[100%] sm:w-[40%] my-3 flex justify-between"><span>Area</span> {leads?.propertysizemarla}</p>
                                <p className="w-[100%] sm:w-[40%] my-3 flex justify-between"><span>Commission</span> {leads?.commission}</p>
                                <p className="w-[100%] sm:w-[40%] my-3 flex justify-between"><span>Tag</span> {leads?.leadTag}</p>
                            </div>
                        </div>
                        <h3 className="font-bold my-5 text-lg">Description</h3>
                        <DescriptionText text={leads?.propertydescription} />
                    </div>
                </div>

            </div>
        </>
    );
};

export default LeadDetails;

function Item({ image }) {
    return (
        <Paper>
            <div>
                <img src={image} alt={"Property Images"} className="w-full h-[40vh] sm:h-[60vh] rounded-lg object-cover" />
            </div>
        </Paper>
    );
}