import { useEffect, useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Grid,
  Paper,
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Autocomplete,
  InputLabel,
} from "@mui/material";
import { IoMdAdd } from "react-icons/io";
import styled from "@emotion/styled";
import {
  useGetAgentsQuery,
  useGetSuggestedPropertiesQuery,
  usePostDealStatusMutation,
} from "../../../../../redux/api/leadsApi";
import { selectCurrentagency } from "../../../../../redux/features/authSlice";
import { formatDate } from "../../../../../constants/dateTime";
import { TRANSACTION_TYPES } from "../../../../../constants/transaction";
import { useLeadsData } from "../../../../../hooks/useContext/leadsData";
import DateAndTime from "../../../../../components/DateAndTime";
import PropertyFilter from "../../../../../components/PropertyFilter";
import DealBreakDown from "./DealBreakDown";
import InstallmentPlans from "./Installment";
import { LEAD_DEAL_DONE_ID } from "../../../../../constants/leads";

const Deal = () => {
  const navigate = useNavigate();
  const currentAgency = useSelector(selectCurrentagency);

  const [dealData, setDealData] = useState({
    startDate: null,
    startTime: null,
    note: "",
    agencyPercentage: "",
    dealPrice: "",
    receivedPrice: "",
    paymentType: "",
    selectedPropertyId: null,
    remainingAmount: 0,
    showProperty: false,
  });
  const [internalAgents, setInternalAgents] = useState([]);
  const [externalAgents, setExternalAgents] = useState([]);

  useEffect(() => {
    setDealData((prev) => ({
      ...prev,
      remainingAmount: prev.dealPrice - prev.receivedPrice,
    }));
  }, [dealData.dealPrice, dealData.receivedPrice]);

  const { agencyLeads: leadsData } = useLeadsData();
  const currentPath = window.location.pathname;
  const segments = currentPath.split("/");
  const agentId = segments[3];
  const userId = segments[5];
  const leadId = segments[segments.length - 1];

  const filteredData = leadsData
    ?.filter((item) => item.agentid === parseInt(agentId))
    ?.filter((item) => item.userid === parseInt(userId));

    const leads = filteredData?.find(
      (lead) => lead.leadid === parseInt(leadId)
    );

  const { isLoading: isLoadingLeads, data: propertiesRef } =
    useGetSuggestedPropertiesQuery({ agcid: currentAgency.agencyid });

  const { data: allAgents } = useGetAgentsQuery(currentAgency.agencyid);

  const [dealStatusData, { isLoading }] = usePostDealStatusMutation();

  const handleSubmit = async () => {
    const {
      paymentType,
      selectedPropertyId,
      dealPrice,
      receivedPrice,
      note,
      agencyPercentage,
      remainingAmountPayDates,
    } = dealData;

    if (
      !selectedPropertyId ||
      !dealPrice ||
      !agencyPercentage ||
      !note
    ) {
      toast.error("Please fill out all required fields before submitting.");
      return;
    }

    try {
      const response = await dealStatusData({
        SingleTransaction: {
          leadid: leadId,
          userid: userId,
          propertyid: selectedPropertyId,
          purchasedprice: dealPrice,
          leadStatus: LEAD_DEAL_DONE_ID,
          agencyPercentage: agencyPercentage,
          agentPercentage: internalAgents.map(({ name, ...rest }) => rest),
          externalAgentPercentage: externalAgents.map(
            ({ id, ...rest }) => rest
          ),
          note,
        },
        TransactionArray: remainingAmountPayDates,
      });

      if (response) {
        // Automatically download the PDFs
        const { pdfFileNameInstallment, pdfFileNameReceiptVoucher } =
          response.data;

        const downloadPDF = (filePath) => {
          const url = `https://data.realtorscrm.com${filePath}`;
          window.open(url, "_blank"); // Open the file in a new tab
        };

        if (pdfFileNameReceiptVoucher && pdfFileNameInstallment) {
          downloadPDF(`/ReceiptVoucher/${pdfFileNameReceiptVoucher}`);
          window.location.href = `https://data.realtorscrm.com/Installments/${pdfFileNameInstallment}`;
        } else if (pdfFileNameReceiptVoucher) {
          window.location.href = `https://data.realtorscrm.com/ReceiptVoucher/${pdfFileNameReceiptVoucher}`;
          navigate(`/agent/leads/${agentId}/view/${userId}`);
        } else if (pdfFileNameInstallment) {
          window.location.href = `https://data.realtorscrm.com/Installments/${pdfFileNameInstallment}`;
          navigate(`/agent/leads/${agentId}/view/${userId}`);
        }
        // Navigate to the desired page and refetch data

        // refetch();
      }
    } catch (error) {
      console.error("Error submitting deal status:", error);
      toast.error(`Error submitting deal status`);
    }
  };

  const filteredProperties =
    propertiesRef?.Properties?.map((option) => ({
      propertyid: option.propertyid?.toString(),
      propertyrefnum: option.propertyrefnum,
    })) || [];

  const handleSelectProperty = (property) => {
    setDealData((prev) => ({
      ...prev,
      selectedPropertyId: property?.propertyid?.toString(),
    }));
  };

  const handleChange = (field) => (event) => {
    setDealData((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
  };

  return (
<Box sx={{ p: { xs: 2, sm: 6 }, position: "relative", overflow: "auto" }}>
<Typography variant="h2" fontWeight="bold">
        Deal Done
      </Typography>
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 1 }}>
            <Typography variant="subtitle1" fontWeight="bold">
              Lead Time
            </Typography>
            <Typography>
              {leads ? formatDate(leads.leadTime) : "No Date Available"}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 1 }}>
            <Typography variant="subtitle1" fontWeight="bold">
              Username
            </Typography>
            <Typography>
              {filteredData?.[0]?.username || "No Username Available"}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 1 }}>
            <Typography variant="subtitle1" fontWeight="bold">
              Phone
            </Typography>
            <Typography>
              {filteredData?.[0]?.userphone || "No Phone Available"}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 1 }}>
            <Typography variant="subtitle1" fontWeight="bold">
              Lead Type
            </Typography>
            <Typography>
              {leads?.leadTypeName || "No Lead Type Available"}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper sx={{ p: 1 }}>
            <Typography variant="subtitle1" fontWeight="bold">
              Status
            </Typography>
            <Typography>
              {leads?.statusName || "No Status Available"}
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <StyledInputsContainer>
        <DateAndTime
          startDate={dealData.startDate}
          setStartDate={(date) =>
            setDealData((prevData) => ({ ...prevData, startDate: date }))
          }
          startTime={dealData.startTime}
          setStartTime={(time) =>
            setDealData((prevData) => ({ ...prevData, startTime: time }))
          }
        />

        <Autocomplete
          loading={isLoadingLeads}
          options={filteredProperties}
          size="small"
          getOptionLabel={(option) => option.propertyrefnum}
          isOptionEqualToValue={(option, value) =>
            option.propertyid === value.propertyid
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Property"
              error={dealData.selectedPropertyId === undefined}
            />
          )}
          value={
            filteredProperties.find(
              (prop) => prop.propertyid === dealData.selectedPropertyId
            ) || null
          }
          onChange={(event, newValue) => {
            handleSelectProperty(newValue);
          }}
        />
        <div
          className="flex -ml-5 w-[150px] text-sm items-center justify-center text-green cursor-pointer"
          onClick={() =>
            setDealData((prev) => ({ ...prev, showProperty: true }))
          }
        >
          <IoMdAdd />
          Properties Filter
        </div>
        {dealData.showProperty && (
          <PropertyFilter
            filteredProperties={propertiesRef?.Properties}
            isFetching={isLoadingLeads}
            setShowProperty={(show) =>
              setDealData((prev) => ({ ...prev, showProperty: show }))
            }
            onSelectProperty={handleSelectProperty}
          />
        )}
      </StyledInputsContainer>

      <TextField
        label="Description"
        multiline
        rows={4}
        fullWidth
        variant="outlined"
        sx={{ mt: 2 }} // Reduced margin-top to align better with surrounding elements
        value={dealData.note}
        onChange={handleChange("note")}
      />

      <Typography variant="h3" sx={{ mt: 4 }}>
        Deal Information
      </Typography>

      <Grid container spacing={3} sx={{ mt: 2 }}>
        <InstallmentPlans
          remainingAmountPayDates={dealData.remainingAmountPayDates}
          setRemainingAmountPayDates={(dates) =>
            setDealData((prevData) => ({
              ...prevData,
              remainingAmountPayDates: dates,
            }))
          }
          remainingAmount={dealData.dealPrice}
          dealPrice={dealData.dealPrice}
          handleChange={handleChange}
        />
      </Grid>

      {/* ****************** Deal Break Down ******************************* */}

      <DealBreakDown
        agents={allAgents?.Agents}
        dealPrice={dealData?.dealPrice}
        defaultAgent={agentId}
        internalAgents={internalAgents}
        setInternalAgents={setInternalAgents}
        externalAgents={externalAgents}
        setExternalAgents={setExternalAgents}
        setAgencyPercentage={(agency) =>
          setDealData((prev) => ({ ...prev, agencyPercentage: agency }))
        }
      />

      <Box
        display="flex"
        justifyContent="flex-end"
        sx={{ mt: 4, gap: 2 }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <Button
          variant="contained"
          sx={{ mt: 4, width: "200px", color: "white" }}
          onClick={handleSubmit}
          disabled={
            isLoading ||
            !dealData.selectedPropertyId ||
            !dealData.dealPrice ||
            !dealData.agencyPercentage ||
            !dealData.note
          }
        >
          {isLoading ? "Processing..." : "Deal Done"}
        </Button>
      </Box>
    </Box>
  );
};

export default Deal;

const StyledInputsContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 1fr",
  gap: "24px",
  marginTop: "24px",
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
  },
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr 1fr ",
  },
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "1fr ",
  },
}));
