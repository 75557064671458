import React from "react";
import { Box } from "@mui/material";
import BarGraph from "./BarGraph";
import { useSelector } from "react-redux";
import { selectCurrentagency } from "../../redux/features/authSlice";
import { useGetAgencyDashboardQuery } from "../../redux/api/authApi";
import PropertyStatusGraph from "./PropertyStatusGraph";
import {
  DRAFT_STATUS_ID,
  PENDING_STATUS_ID,
  PUBLISHED_STATUS_ID,
  UNPUBLISHED_STATUS_ID,
} from "../../constants/property";

const propertyStatuses = {
  [UNPUBLISHED_STATUS_ID]: "Un Published",
  [PUBLISHED_STATUS_ID]: "Published",
  [PENDING_STATUS_ID]: "Pending",
  [DRAFT_STATUS_ID]: "Draft",
};

export default function Dashboard() {
  const currentAgency = useSelector(selectCurrentagency);
  const { data, isFetching, isSuccess } = useGetAgencyDashboardQuery(currentAgency.agencyid, {
    refetchOnMountOrArgChange: true,
  });

  console.log(data)

  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit,minmax(285px,1fr))",
        }}
      >
        {data && data.TotalPropertiesOfAgenciesByPropertyStatus && (
          <BarGraph
            title="Properties Listing Overview"
            isSuccess={isSuccess}
            isFetching={isFetching}
            chipTitle={
              data?.TotalPropertiesOfAgenciesByPropertyStatus[0]?.PropertyCounts.reduce((acc, item) => item.PropertyCount + acc, 0) + " Total Properties Listing"
            }
            seriesConfig={{
              name: "Properties",
              data: data?.TotalRegisteredPropertiesByAgentOfAgency.map((item) => item.PropertyCount),
            }}
            labels={data.TotalRegisteredPropertiesByAgentOfAgency?.map((item) => item.AgentName)}
            series={data.TotalRegisteredPropertiesByAgentOfAgency?.map((item) => item.PropertyCount)}
          />
        )}

        {data && data.TotalPropertiesOfAgencyByCity && (

          <BarGraph
            title="Properties By City"
            isFetching={isFetching}
            isSuccess={isSuccess}
            chipTitle={
              data?.TotalPropertiesOfAgencyByCity[0]?.PropertyCountByCity.reduce((acc, item) => item.PropertyCount + acc, 0) + " Total Properties By City"
            }
            labels={
              data?.TotalPropertiesOfAgencyByCity[0]?.PropertyCountByCity.map(
                (item) => item.CityName
              ) || []
            }
            seriesConfig={{
              name: "Properties",
              data: data?.TotalPropertiesOfAgencyByCity[0]?.PropertyCountByCity.map((item) => item.PropertyCount),
            }}
            // series={
            //   data?.TotalPropertiesOfAgencyByCity[0]?.PropertyCountByCity.map(
            //     (item) => item.CityName
            //   ) || []
            // }
          />
        )}

      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit,minmax(285px,1fr))",
        }}
      >
        {data && data.AgencyLeadsByCity && (
          <BarGraph
            title="Leads By City"
            chipTitle={
              data?.AgencyLeadsByCity.reduce((acc, item) => acc + item.LeadCount, 0) + " Total Leads"
            }
            isFetching={isFetching}
            isSuccess={isSuccess}
            labels={data?.AgencyLeadsByCity.map((item) => item.CityName)}
            seriesConfig={{
              name: "Leads",
              data: data?.AgencyLeadsByCity.map((item) => item.LeadCount),
            }}
          />
        )}
        {data && data.AgencyLeadsByPropertyType && (

          <BarGraph
            title="Leads By Property Type"
            chipTitle={
              data?.AgencyLeadsByPropertyType.reduce((acc, item) => acc + item.LeadCount, 0) + " Total Leads"
            }
            isFetching={isFetching}
            isSuccess={isSuccess}
            labels={data?.AgencyLeadsByPropertyType.map((item) => item.PropertType)}
            seriesConfig={{
              name: "Leads",
              data: data?.AgencyLeadsByPropertyType.map((item) => item.LeadCount),
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit,minmax(285px,1fr))",
        }}
      >
        {data && data.TotalPropertiesOfAgenciesByPropertyStatus && (
          <PropertyStatusGraph
            isFetching={isFetching}
            isSuccess={isSuccess}
            labels={
              data?.TotalPropertiesOfAgenciesByPropertyStatus[0]?.PropertyCounts.filter(
                (item) => propertyStatuses[item.Pstatus]
              ).map((item) => ({ id: item.Pstatus, label: propertyStatuses[item.Pstatus] })) || []
            }
            series={
              data?.TotalPropertiesOfAgenciesByPropertyStatus[0]?.PropertyCounts.filter(
                (item) => propertyStatuses[item.Pstatus]
              ).map((item) => item.PropertyCount) || []
            }
          />
        )}
      </Box>
    </>
  );
}
