import { Button, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { leadDefaultValues, leadRules } from "./leadValidation";
import TextField from "../FormElements/TextField";
import Select from "../FormElements/Select";
import AutoComplete from "../FormElements/AutoComplete";
import { useGetAgencyPropertiesQuery } from "../../redux/api/propertiesApi";
import { useSelector } from "react-redux";
import { ADD_LEAD_TYPES, LEAD_TYPES } from "../../constants/leads";
import { selectCurrentagency } from "../../redux/features/authSlice";
import {
  useGetAgentsQuery,
  useGetLeadByPhoneNumberQuery,
  useLeadTypeQuery,
  useLeadMediumQuery,
  useLeadPriorityQuery,
  useLeadSourceQuery,
  useLeadStatusQuery,
} from "../../redux/api/leadsApi";
import { IoMdAdd } from "react-icons/io";
import PropertyFilter from "../PropertyFilter";
import Phone from "../FormElements/Phone";
import { formatDateTime } from "../../constants/dateAndTime";
import DateAndTime from "../DateAndTime";
import { useLazyGetCitiesByProvinceIdQuery } from "../../redux/api/citiesApi";

export default function LeadForm({
  onAddLead,
  title,
  isSubmitting,
  onEditLead,
  leadData,
}) {
  const currentAgency = useSelector(selectCurrentagency);
  const {
    data: agents,
    isFetching,
    isSuccess,
  } = useGetAgentsQuery(currentAgency.agencyid);
  const methods = useForm({ defaultValues: leadData ?? leadDefaultValues });
  const properties = useGetAgencyPropertiesQuery(currentAgency.agencyid);
  const { handleSubmit } = methods;
  const [showProperty, setShowProperty] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [phone, setPhone] = useState(leadData?.userphone ?? "");

  const { setValue } = methods;
  const { data: status = [] } = useLeadStatusQuery();
  const { data: medium = [] } = useLeadMediumQuery();
  const { data: LeadType = [] } = useLeadTypeQuery();
  const { data: Source = [] } = useLeadSourceQuery();
  const { data: Priority = [] } = useLeadPriorityQuery();
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);

  useEffect(() => {
    if (leadData && leadData.leadTime) {
      const date = new Date(leadData.leadTime);
      if (!isNaN(date.getTime())) {
        setStartDate(date);

        // Extract hours and minutes for the time picker
        const hours = date.getHours();
        const minutes = date.getMinutes();
        // Use the current date with the extracted time
        const time = new Date();
        time.setHours(hours, minutes, 0, 0);
        setStartTime(time);
      } else {
        console.error("Invalid date:", leadData.leadTime);
      }
    }
  }, [leadData]);

  const handleSelectProperty = (property) => {
    setSelectedProperty(property);
    setValue("propertyid", property.propertyid);
  };

  function encodePhoneNumber(phone) {
    if (phone && phone.startsWith("+")) {
      phone = phone.replace("+", "%2B");
    }
    return phone;
  }

  // Example usage
  const encodedPhone = encodePhoneNumber(phone);

  const { data: leadByPhoneData } = useGetLeadByPhoneNumberQuery(encodedPhone, {
    skip: !encodedPhone,
  });

  useEffect(() => {
    if (leadByPhoneData) {
      setValue("firstname", leadByPhoneData.firstname);
      setValue("lastname", leadByPhoneData.lastname);
      setValue("useremail", leadByPhoneData.useremail);
      // Add more fields as needed
    }
  }, [leadByPhoneData, phone, setValue]);

  const onSubmit = (data) => {
    const leadLocation = properties.data.find(
      (item) => item.propertyid === data.propertyid
    )?.cityid;

    if (leadData) {
      return onEditLead({
        ...data,
        leadLocation,
      });
    }
    let userData = {
      ...data,
      leadLocation,
      agencyid: currentAgency.agencyid,
    };

    // Check if leadByPhoneData exists and userphone matches the input phone
    if (leadByPhoneData && leadByPhoneData.userphone === encodedPhone) {
      userData.userid = leadByPhoneData.userid;
    }
    userData.leadDate = dateAndTime;

    onAddLead(userData);
  };
  const dateAndTime = formatDateTime(startDate, startTime);
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h2" mb={4}>
          {title}
        </Typography>

        <StyledInputsContainer>
          <DateAndTime
            startDate={startDate}
            setStartDate={setStartDate}
            startTime={startTime}
            setStartTime={setStartTime}
          />
          <Phone
            name="userphone"
            label="Phone"
            defaultCountry={currentAgency.countryname}
            setPhone={setPhone}
            rules={leadRules.mobileno}
          />

          <TextField
            name="firstname"
            label="First Name"
            rules={leadRules.firstName}
          />
          <TextField
            name="lastname"
            label="Last Name"
            rules={leadRules.lastName}
          />
          <TextField
            name="companyName"
            label="Company Name"
            rules={leadRules.companyName}
          />
          <TextField
            name="location"
            label="Location"
            rules={leadRules.location}
          />
          <TextField
            name="designation"
            label="Designation"
            rules={leadRules.designation}
          />
          <TextField
            name="budget"
            label="Budget"
            rules={leadRules.cityId}
          />
          
          <Select
            name="leadtype"
            options={LeadType.map((item) => ({
              id: item.leadTypeId,
              name: item.leadTypeName,
            }))}
            optionId="id"
            optionLabel="name"
            label="Lead Type"
            rules={leadRules.leadtype}
          />
          <TextField
            name="useremail"
            label="Email"
            rules={leadRules.useremail}
          />
          <Select
            name="sourceId"
            options={Source.map((item) => ({
              id: item.sourceId,
              name: item.sourceName,
            }))}
            optionId="id"
            optionLabel="name"
            label="Source"
            rules={leadRules.source}
          />

          <Select
            name="mediumId"
            options={medium.map((item) => ({
              id: item.mediumId,
              name: item.mediumName,
            }))}
            optionId="id"
            optionLabel="name"
            label="Medium"
            rules={leadRules.medium}
          />

          <Select
            name="leadStatus"
            options={status.map((item) => ({
              id: item.statusId,
              name: item.statusName,
            }))}
            optionId="id"
            optionLabel="name"
            label="Status"
            rules={leadRules.status}
          />
          <Select
            name="priorityId"
            options={Priority.map((item) => ({
              id: item.priorityId,
              name: item.priorityName,
            }))}
            optionId="id"
            optionLabel="name"
            label="Priority"
            rules={leadRules.priority}
          />

          <AutoComplete
            name="agentid"
            isLoading={isFetching}
            options={
              agents &&
              agents.map((agent) => ({
                agentid: agent.agentid,
                agentname: agent.agentname,
              }))
            }
            label="Select Agent"
            optionId="agentid"
            optionLabel="agentname"
            rules={leadRules.agentid}
          />
          <AutoComplete
            name="propertyid"
            isLoading={properties.isFetching}
            options={properties.data}
            label="Property"
            optionId="propertyid"
            optionLabel="propertyrefnum"
            rules={leadRules.propertyid}
            value={selectedProperty ? selectedProperty.propertyrefnum : ""}
          />
          <div
            className="flex -ml-5 w-[150px] text-sm items-center justify-center text-green cursor-pointer"
            onClick={() => setShowProperty(true)}
          >
            <IoMdAdd />
            Properties Filter
          </div>
          {showProperty && (
            <PropertyFilter
              filteredProperties={properties.data}
              isFetching={properties.isFetching}
              setShowProperty={setShowProperty}
              onSelectProperty={handleSelectProperty}
            />
          )}
        </StyledInputsContainer>

        <TextField
          name="description"
          label="Description"
          rules={leadRules.description}
          multiline
          rows={4}
          sx={{ my: 3, maxWidth: "600px", display: "block" }}
        />

        <Button
          sx={{ color: "white" }}
          type="submit"
          variant="contained"
          disabled={isSubmitting}
        >
          {isSubmitting ? "submitting..." : "Submit"}
        </Button>
      </form>
    </FormProvider>
  );
}

const StyledInputsContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 1fr",
  gap: "24px",
  marginTop: "24px",
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "1fr 1fr 1fr",
  },

  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr 1fr ",
  },

  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "1fr ",
  },
}));
