import { Box, Checkbox, CircularProgress, Fab, FormControlLabel, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useFormContext } from "react-hook-form";
import { PROPERTY_IMAGES_URL } from "../../constants/url";
import SortableList, { SortableItem, SortableKnob } from "react-easy-sort";
import { Close } from "@mui/icons-material";

const ImagesContainer = ({ items, onChangeDescription, onSortEnd, onRemoveImage }) => {
  const { getValues, setValue } = useFormContext();

  const onChangeAddDescription = (value, item) => {
    let description = getValues("propertydescription");
    if (value) {
      setValue("propertydescription", description + "\n" + item.description);
    } else {
      let removeDescription = description.replace(item.description, "");

      setValue("propertydescription", removeDescription);
    }
  };
  return (
    <SortableList
      onSortEnd={onSortEnd}
      allowDrag={!items.some((item) => item.loadingImage)}
      draggedItemClassName="dragged-item"
    >
      <StyledContainer>
        {items.map((item, index) => (
          <SortableItem key={item.id}>
            <Box sx={{ display: "flex", position: "relative", flexDirection: "column" }}>
              <Fab
                size="small"
                onClick={() => onRemoveImage(item.id)}
                sx={{
                  position: "absolute",
                  backgroundColor: "white",
                  transform: "scale(0.7)",
                  transformOrigin: "top right",
                  top: 0,
                  right: 0,
                  padding: 0,
                }}
              >
                <Close fontSize="small" />
              </Fab>

              <SortableKnob>
                <Box sx={{ position: "relative", display: "flex" }}>
                  {item.loadingImage && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: "absolute",
                        top: "calc(50% - 10px)",
                        left: "calc(50% - 10px)",
                        zIndex: 2,
                        color: "white",
                      }}
                    />
                  )}
                  <StyledImage
                    src={item.loadingImage ? item.url : PROPERTY_IMAGES_URL + item.url}
                    isLoading={item.loadingImage}
                  />
                </Box>
              </SortableKnob>
              <Box sx={{ display: "flex", gap: "16px", alignItems: "flex-end", width: "100%" }}>
                <TextField
                  size="small"
                  variant="standard"
                  label="description"
                  fullWidth
                  defaultValue={item.description}
                  onBlur={(e) => onChangeDescription(e.target.value, index)}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      onChange={(e) => {
                        onChangeAddDescription(e.target.checked, item);
                      }}
                    />
                  }
                  label="Add"
                />
              </Box>
            </Box>
          </SortableItem>
        ))}
      </StyledContainer>
    </SortableList>
  );
};

const StyledContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
  gap: "16px",

  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr 1fr 1fr",
  },

  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "1fr 1fr",
  },
}));

const StyledImage = styled("img")(({ theme, isLoading }) => ({
  width: "100%",
  objectFit: "cover",
  overflow: "hidden",
  userSelect: "none",
  WebkitUserSelect: "none",
  MozUserSelect: "none",
  KhtmlUserSelect: "none",
  msUserSelect: "none",
  cursor: "grab",
  userDrag: "none",
  WebkitUserDrag: "none",
  MozUserDrag: "none",
  KhtmlUserDrag: "none",
  msUserDrag: "none",
  display: "flex",
  borderRadius: "12px",
  aspectRatio: "14/9",
  filter: isLoading ? "grayscale(100%)" : "none",
}));

export default ImagesContainer;
