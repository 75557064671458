import { matchIsValidTel } from "mui-tel-input";

export const agencyProfileDefaultValues = {
  agencyname: "",
  Agency_Descrip: "",
  agencylogo: "",
  agencywatermark: "",
  agencywatermarksize: "",
  agencywatermarkposition: "",
  agencytransparency: "",
  licenseno: "",
  agencylicensepic: "",
  issuedate: "",
  expdate: "",
  agencystatus: 0,
  agencyemail: "",
  agencypassword: "",
  confirmPassword: "",
  agencyregno: "",
  agencyphone: "",
  agencyuan: "",
  agencyaddress: "",
  countryid: "",
  provid: "",
  cityid: "",
  refnostartingprop: "",
  agcRefTag: "",
  terms: false,
};

export const agencyProfileRules = {
  agencyname: { required: "Agency Name is required" },
  Agency_Descrip: { required: "Agency Description is required" },
  agencylogo: { required: "Agency Logo is required" },
  agencywatermark: { required: "Agency Watermark is required" },
  agencywatermarksize: { required: "Agency Watermark Size is required" },
  agencywatermarkposition: { required: "Agency Watermark Position is required" },
  licenseno: { required: "License Number is required" },
  agencylicensepic: { required: "Agency License Picture is required" },
  issuedate: { required: "Issue Date is required" },
  expdate: { required: "Expiry Date is required" },
  agencystatus: { required: "Agency Status is required" },
  agencyemail: {
    required: "Agent Email is Required",
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "Invalid email address",
    },
  },
  agencypassword: {
    required: "Agency Password is required",
  },
  confirmPassword: {
    validate: (value, values) => value === values.agencypassword || "Passwords do not match",
  },
  agencyregno: { required: "Agency Registration Number is required" },
  agencyphone: {
    validate: {
      phone: (value) => {
        return matchIsValidTel(value) || "Invalid phone number";
      },
    },
  },
  agencyuan: { required: "Agency UAN is required" },
  agencyaddress: { required: "Agency Address is required" },
  countryid: { required: "Country ID is required" },
  provid: { required: "Province ID is required" },
  cityid: { required: "City ID is required" },
  refnostartingprop: { required: "Refno Starting Property is required" },
  agcRefTag: { required: "Ref Tag is must" },
  terms: { required: "You must agree to the terms" },
  agencytransparency: { required: "Select Transparency" },
};
