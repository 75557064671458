export const formatDateTime = (date, time) => {
    if (!date || !time) return null;
  
    // Combine date and time into a single Date object
    const combinedDateTime = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.getHours(),
      time.getMinutes(),
      time.getSeconds()
    );
  
    // Convert to ISO string
    const isoString = combinedDateTime.toISOString();
  
    // Adjust format if necessary (e.g., remove timezone offset)
    return isoString;
  };
  
