import { toast } from "react-toastify";
import { apiSlice } from ".";
import { errorToast } from "../../constants/toast";

const propertiesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    changePropertyStatus: builder.mutation({
      query: ({ pstatus, agencyid, propertyid }) => ({
        url: `/PropertyTables/UpdatePropertyStatus/${propertyid}?stat=${pstatus}`,
        method: "POST",
        body: { pstatus },
      }),

      async onQueryStarted({ pstatus, agencyid, propertyid }, { dispatch, queryFulfilled }) {
        const editResult = dispatch(
          propertiesApi.util.updateQueryData("getAgencyProperties", agencyid, (properties) => {
            return properties.map((item) =>
              item.propertyid === propertyid ? { ...item, pstatus: pstatus } : item
            );
          })
        );

        try {
          await queryFulfilled;
        } catch (err) {
          errorToast(
            `Error while changing status for the property Id ${propertyid}, Refresh page and try again`
          );
          dispatch(propertiesApi.util.invalidateTags(["AgencyProperties"]));
        }
      },
    }),

    getPropertyById: builder.query({
      query: (propertyId) => `/PropertyTables/GetPropertyTable/${propertyId}`,
    }),

    getPropertyImages: builder.query({
      query: (propertyId) => `/PropertyImagesTables/GetImageByPropertyID/${propertyId}`,
      providesTags: ["PropertyImages"],
    }),
    addProperty: builder.mutation({
      query: (data) => ({
        url: `/FileUploader/PropertyAdding?agcid=${data.agencyid}`,
        method: "POST",
        body: data,
        formData: true,
      }),
      // invalidatesTags: ["Properties"],

      async onQueryStarted(args, { queryFulfilled }) {
        toast.promise(queryFulfilled, {
          pending: "Adding Property",
          success: "Property Added",
          error: "Error Adding Property",
        });
      },
    }),
    editProperty: builder.mutation({
      query: (data) => ({
        url: `/FileUploader/PropertyInfoAfterEditing/${data.propertyid}`,
        method: "POST",
        body: data,
      }),

      async onQueryStarted(args, { queryFulfilled }) {
        toast.promise(queryFulfilled, {
          pending: "Editing Property",
          success: "Property Edited",
          error: "Error Editing Property",
        });
      },
    }),
    addImages: builder.mutation({
      query: (data) => ({
        url: `/FileUploader/uploadfilesByID2?location=${data.watermark.location}&opacity=${data.watermark.opacity}&logosize=${data.watermark.size}&logoFileName=${data.watermark.name}`,
        method: "POST",
        body: data.formData,
      }),
      async onQueryStarted(args, { queryFulfilled }) {
        toast.promise(queryFulfilled, {
          pending: "Uploading Images",
          success: "Images Uploaded",
          error: "Error Uploading Images",
        });
      },
    }),

    getAgencyProperties: builder.query({
      query: (agencyId) => `/PropertyTables/GetPropByAgencyidAndPropstatus?agcid=${agencyId}`,
      providesTags: ["AgencyProperties"],
    }),

    deleteProperty: builder.mutation({
      query: ({ propertyId, agencyId }) => ({
        url: `/PropertyTables/DeletePropertyTable/${propertyId}`,
        method: "POST",
      }),

      async onQueryStarted({ propertyId, agencyId }, { dispatch, queryFulfilled }) {
        const deleteResult = dispatch(
          propertiesApi.util.updateQueryData("getAgencyProperties", agencyId, (properties) => {
            return properties.filter((item) => item.propertyid !== propertyId);
          })
        );

        try {
          await queryFulfilled;
        } catch (err) {
          errorToast(`Error while deleting Property ${propertyId}, Refresh page and try again`);
          dispatch(propertiesApi.util.invalidateTags(["AgencyProperties"]));
        }
      },
    }),

    getAddedProperty: builder.query({
      query: (propertyId) => `/PropertyFilter/Gettt?propid=${propertyId}`,
    }),
  }),
});

export const {
  useChangePropertyStatusMutation,
  useGetAgencyPropertiesQuery,
  useDeletePropertyMutation,
  useGetPropertyByIdQuery,
  useGetPropertyImagesQuery,
  useEditPropertyMutation,
  useAddImagesMutation,
  useAddPropertyMutation,
  useLazyGetAddedPropertyQuery
} = propertiesApi;
