export const LEAD_CALL_TYPE = 1;
export const LEAD_EMAIL_TYPE = 2;
export const LEAD_WHATSAPP_TYPE = 3;

export const LEAD_TYPES = [
  { id: LEAD_CALL_TYPE, name: "Call" },
  { id: LEAD_EMAIL_TYPE, name: "Email" },
  { id: LEAD_WHATSAPP_TYPE, name: "Whatsapp" },
];

export const ADD_LEAD_CALL_TYPE = 1;
export const ADD_LEAD_EMAIL_TYPE = 2;
export const ADD_LEAD_WHATSAPP_TYPE = 3;
export const ADD_LEAD_FACEBOOK_TYPE = 4;
export const ADD_LEAD_WALK_IN_TYPE = 5;
export const ADD_LEAD_GOOGLE_TYPE = 6;
export const ADD_LEAD_INSTAGRAM_TYPE = 7;
export const ADD_LEAD_TWITTER_TYPE = 8;
export const ADD_LEAD_YOUTUBE_TYPE = 9;
export const ADD_LEAD_PINTEREST_TYPE = 10;
export const ADD_LEAD_REFERRAL_TYPE = 11;

export const ADD_LEAD_TYPES = [
  { id: ADD_LEAD_CALL_TYPE, name: "Call" },
  { id: ADD_LEAD_EMAIL_TYPE, name: "Email" },
  { id: ADD_LEAD_WHATSAPP_TYPE, name: "Whatsapp" },
  { id: ADD_LEAD_FACEBOOK_TYPE, name: "Facebook" },
  { id: ADD_LEAD_WALK_IN_TYPE, name: "Walk In" },
  { id: ADD_LEAD_GOOGLE_TYPE, name: "Google" },
  { id: ADD_LEAD_INSTAGRAM_TYPE, name: "Instagram" },
  { id: ADD_LEAD_TWITTER_TYPE, name: "Twitter" },
  { id: ADD_LEAD_YOUTUBE_TYPE, name: "Youtube" },
  { id: ADD_LEAD_PINTEREST_TYPE, name: "Pinterest" },
  { id: ADD_LEAD_REFERRAL_TYPE, name: "Referral" },
];

export const UNHANDLED_TYPE = 0;
export const CALL_NOT_RECIEVED_TYPE = 1;
export const EMAIL_TYPE = 2;
export const INCORRECT_DETAILS_TYPE = 3;
export const INPROGRESS_TYPE = 4;
export const NOT_YET_CONTACTED_TYPE = 5;
export const CALLED_NO_REPLY_TYPE = 6;
export const FOLLOW_UP_TYPE = 7;
export const CLOSE_DEAL_DONE_TYPE = 8;
export const VIEWING_ARRANGED_TYPE = 9;
export const APPOINTMENT_TYPE = 10;
export const VIEWING_DONE_TYPE = 11;
export const OFFER_MADE_TYPE = 12;
export const NEEDS_MORE_INFO_TYPE = 13;
export const BUDGET_DIFFERS_TYPE = 14;
export const NEED_TIME_TYPE = 15;
export const CLIENT_TO_REVERT_TYPE = 16;
export const PRICE_TOO_HIGHT_TYPE = 17;
export const INTERESTED_TYPE = 18;
export const INTERESTED_TO_MEET_TYPE = 19;
export const NOT_INTERESTED_TYPE = 20;
export const LOOK_SEE_TYPE = 21;
export const CLIENT_CONNECTED_ONLINE_TO_AGENT_TYPE = 22;
export const SMS_SENT_TO_AGENT_TYPE = 23;
export const EMAIL_SEND_TO_AGENT_TYPE = 24;
export const CLIENT_NOT_REACHABLE_TYPE = 25;
export const INCORRECT_CONTACT_DETAILS_TYPE = 26;
export const INVALID_INQUIRY_TYPE = 27;
export const CLOSE_UNSUCCESSFULL_TYPE = 28;


export const STATUS_TYPES = [
  { id: UNHANDLED_TYPE, name: "Unhandled" },
  { id: CALL_NOT_RECIEVED_TYPE, name: "Call Not Recieved" },
  { id: EMAIL_TYPE, name: "Email" },
  { id: INCORRECT_DETAILS_TYPE, name: "Incorrect Details" },
  { id: INPROGRESS_TYPE, name: "In Progress" },
  { id: NOT_YET_CONTACTED_TYPE, name: "Not Yet Contacted" },
  { id: CALLED_NO_REPLY_TYPE, name: "Called No Reply" },
  { id: VIEWING_ARRANGED_TYPE, name: "Viewing Arranged" },
  { id: FOLLOW_UP_TYPE, name: "Follow Up" },
  { id: APPOINTMENT_TYPE, name: "Appointment" },
  { id: VIEWING_DONE_TYPE, name: "Viewing Done" },
  { id: OFFER_MADE_TYPE, name: "Offer Made" },
  { id: NEEDS_MORE_INFO_TYPE, name: "Needs More Info" },
  { id: BUDGET_DIFFERS_TYPE, name: "Budget Differs" },
  { id: NEED_TIME_TYPE, name: "Need Time" },
  { id: CLIENT_TO_REVERT_TYPE, name: "Client To Revert" },
  { id: PRICE_TOO_HIGHT_TYPE, name: "Price Too High" },
  { id: INTERESTED_TYPE, name: "Interested" },
  { id: INTERESTED_TO_MEET_TYPE, name: "Interested To Meet" },
  { id: NOT_INTERESTED_TYPE, name: "Not Interested" },
  { id: LOOK_SEE_TYPE, name: "Look See" },
  { id: CLIENT_CONNECTED_ONLINE_TO_AGENT_TYPE, name: "Client Connected Online To Agent" },
  { id: SMS_SENT_TO_AGENT_TYPE, name: "SMS Sent To Agent" },
  { id: EMAIL_SEND_TO_AGENT_TYPE, name: "Email Send TO Agent" },
  { id: CLIENT_NOT_REACHABLE_TYPE, name: "Client Not Reachable" },
  { id: INCORRECT_CONTACT_DETAILS_TYPE, name: "Incorrect Contact Details" },
  { id: INVALID_INQUIRY_TYPE, name: "Invalid Inquiry" },
  { id: CLOSE_DEAL_DONE_TYPE, name: "Close Deal Done" },
  { id: CLOSE_UNSUCCESSFULL_TYPE, name: "Close-Unsuccessfull" },
];


export const LEAD_VIEWING_ARRANGE_ID = 9;
export const LEAD_VIEWING_DONE_ID = 11;
export const LEAD_DEAL_DONE_ID = 8;

export const DOWN_PAYMENT_TYPE = 1;
export const MONTLY_PAYMENT_TYPE = 2;
export const QUARTERLY_PAYMENT_TYPE = 3;
export const BI_YEARLY_PAYMENT_TYPE = 4;
export const DIGGING_AMOUNT_TYPE = 5;
export const ALLOCATION_AMOUNT_TYPE = 6;
export const ON_POSSESSION_TYPE = 7;
export const BOOKING_AMOUNT_TYPE = 8;
export const CONFIRMATION_TYPE = 9;
export const FOUNDATION_TYPE = 10;


export const INSTALLMENTS_TYPES = [
  { id: DOWN_PAYMENT_TYPE, name: "Down Payment" },
  { id: MONTLY_PAYMENT_TYPE, name: "Monthly Payment" },
  { id: QUARTERLY_PAYMENT_TYPE, name: "Quarterly Payment" },
  { id: BI_YEARLY_PAYMENT_TYPE, name: "Bi-yearly Payment " },
  { id: DIGGING_AMOUNT_TYPE, name: "Digging Amount " },
  { id: ALLOCATION_AMOUNT_TYPE, name: "Allocation Amount " },
  { id: ON_POSSESSION_TYPE, name: "On Possession " },
  { id: BOOKING_AMOUNT_TYPE, name: "Booking Amount " },
  { id: CONFIRMATION_TYPE, name: "Confirmation " },
  { id: FOUNDATION_TYPE, name: "Foundation" }

];
