import { useSelector } from "react-redux";
import { useGetDealsDetailsQuery } from "../../../redux/api/DealsPdf";
import { selectCurrentagency } from "../../../redux/features/authSlice";
import Loader from "../../../components/loader/Loader";
import { Link } from "react-router-dom";
import { PROPERTY_PDFS_URL } from "../../../constants/url";
import { MdOutlinePictureAsPdf } from "react-icons/md";
import { Box, Button, styled, Typography } from "@mui/material";
import { DEAL_COLUMN } from "./dealsData";
import MaterialReactTable from "material-react-table";

export const DealsPdf = () => {
  const currentAgency = useSelector(selectCurrentagency);
  const { data, isLoading, error, refetch } = useGetDealsDetailsQuery(
    currentAgency.agencyid
  );
  // if (isLoading) return <Loader />;
  // if (error) return <p>An error occurred</p>;
  const agencyEarning = data?.agencyDealCountsAndEarnings[0]?.TotalAgencyEarning?.toFixed(2);
  const agencyDeals = data?.agencyDealCountsAndEarnings[0]?.DealCount;
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "flex-start", sm: "center" },
        }}
      >
        <Typography variant="h2" sx={{ mb: { xs: 2, sm: 0 } }}>
          All Deals Report
        </Typography>
        <Box
          display="flex"
          gap={2}
        >
          <StyledButton variant="contained" sx={{ color: "white" }}>
            Total Earning {agencyEarning}
          </StyledButton>
          <StyledButton variant="contained" sx={{ color: "white" }}>
            Total Deals {agencyDeals}
          </StyledButton>
        </Box>
      </Box>
      <MaterialReactTable
        state={{ showSkeletons: isLoading }}
        columns={DEAL_COLUMN}
        data={data?.dealInfo ?? []}
        refetch={refetch}
      />
    </>
  );
};
const StyledButton = styled(Button)(({ theme }) => ({
  marginRight: "8px",
}));
