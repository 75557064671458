import React from "react";
import { Card, CardContent, Divider, Box, Typography, Chip } from "@mui/material";

const BaseCard = ({ title, chipTitle, children, sx }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        p: 0,
        // width: "100%",
        ...sx,
      }}
    >
      <Box p={2} display="flex" alignItems="center" sx={{ minWidth: 0 }}>
        <Typography variant="h4" noWrap>
          {title}
        </Typography>

        {chipTitle ? (
          <Chip
            label={chipTitle}
            size="small"
            sx={{
              ml: "auto",
              fontSize: "12px",
              fontWeight: "500",
            }}
          ></Chip>
        ) : (
          ""
        )}
      </Box>
      <Divider />
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default BaseCard;
