import React, { useState } from "react";
import { Box, CircularProgress, Button } from "@mui/material";
import Chart from "react-apexcharts";
import BaseCard from "../../components/BaseCard/BaseCard";
import ShowMore from './ShowMore'

const BarGraph = ({ isSuccess, isFetching, labels, seriesConfig, title, chipTitle }) => {
  const [showAll, setShowAll] = useState(false);
  const maxCitiesToShow = 10;

  console.log(chipTitle)

  const optionssalesoverview = {
    grid: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    colors: ["#56C662"],
    chart: {
      foreColor: "#adb0bb",
      fontFamily: "'DM Sans',sans-serif",
    },
    xaxis: {
      type: "category",
      categories: showAll ? labels : (labels ? labels.slice(0, maxCitiesToShow) : []),
    },
    yaxis: {
      show: true,
      tickAmount: 10,
    },
  };

  const seriessalesoverview = [
    {
      name: seriesConfig.name,
      data: showAll ? seriesConfig.data : (seriesConfig.data ? seriesConfig.data.slice(0, maxCitiesToShow) : []),
    },
  ];

  return (
    <>
      <BaseCard title={title} chipTitle={chipTitle} sx={{ flex: 1 }}>
        {isFetching && (
          <Box sx={{ height: "300px", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
          </Box>
        )}

        {isSuccess && !isFetching && (
          <>
            <Chart options={optionssalesoverview} series={seriessalesoverview} type="bar" height="300px" />
            {!showAll && labels?.length > maxCitiesToShow && (
              <Box sx={{ display: "flex", justifyContent: "center", marginTop: "10px", }}>
                <Button sx={{ color: "white" }} variant="contained" onClick={() => setShowAll(true)}>Show More</Button>
              </Box>
            )}
          </>
        )}
      </BaseCard>
      {showAll && (
        <ShowMore
          isSuccess={isSuccess}
          setShowAll={setShowAll}
          isFetching={isFetching}
          labels={labels}
          seriesConfig={seriesConfig}
          title={title}
          chipTitle={chipTitle}
          optionssalesoverview={optionssalesoverview}
        />
      )}
    </>
  );
};

export default BarGraph;
