export const PROPERTY_PURPOSES = [
  { id: 5, label: "Rent", refName: "R" },
  { id: 6, label: "Sale", refName: "S" },
  { id: 7, label: "Commercial Rent", refName: "CR" },
  { id: 8, label: "Commercial Sale", refName: "CB" },
];

export const PROPERTY_TYPES = [
  { id: 19, label: "Apartment" },
  { id: 20, label: "Villa" },
  { id: 21, label: "Penthouse" },
  { id: 1019, label: "Compound" },
  { id: 1020, label: "Duplex" },
  { id: 1022, label: "Building" },
  { id: 1023, label: "Shop" },
  { id: 1024, label: "Plot" },
  { id: 1025, label: "Office" },
  { id: 1026, label: "Floor" },
  { id: 1027, label: "Hotel" },
  { id: 1028, label: "Farmhouse" },
  { id: 1029, label: "Warehouse" },
  { id: 1030, label: "Factory" },
  { id: 1031, label: "Retail" },
  { id: 1032, label: "Hotel Apartment" },
  { id: 1033, label: "Land Commercial" },
  { id: 1034, label: "Labour Camp" },
  { id: 1035, label: "Residential Building" },
  { id: 1036, label: "Multiple Sale Units" },
  { id: 1037, label: "Land Residential" },
  { id: 1038, label: "Commercial Full Building" },
  { id: 1039, label: "Loft Apartment" },
  { id: 1040, label: "Townhouse" },
  { id: 1041, label: "Land Mixed Use" },
  { id: 1042, label: "Half Floor" },
  { id: 1043, label: "Full Floor" },
  { id: 1044, label: "Commercial Villa" },
  { id: 1045, label: "Bungalow" },
  { id: 1046, label: "Cottage" },
  { id: 1047, label: "Multiple Rental Units" },
  { id: 1048, label: "Residential Full Floor" },
  { id: 1049, label: "Commercial Full Floor" },
  { id: 1050, label: "Residential Half Floor" },
  { id: 1051, label: "Commercial Half Floor" },
  { id: 1052, label: "Show Room" },
  { id: 1053, label: "Business Center" },
  { id: 1054, label: "Land Industrial" },
  { id: 1055, label: "Open Yard" },
  { id: 1056, label: "Land Agricultural" },
  { id: 1057, label: "Student Accommodation" },
  { id: 1058, label: "Commercial Multiple Sale Units" },
  { id: 1059, label: "Commercial Multiple Rental Units" },
  { id: 1066, label: "House" },
  { id: 1067, label: "Upper Portion" },
  { id: 1068, label: "Lower Portion" },
];

export const PROPERTY_DURATION = [
  { id: 1, label: "Daily" },
  { id: 2, label: "Monthly" },
  { id: 3, label: "Quarterly" },
  { id: 4, label: "Yearly" },
  { id: 5, label: "One Time" },
];

export const OWNER_SALUTATION = [
  { id: 1, label: "Mr." },
  { id: 2, label: "Ms." },
  { id: 3, label: "Mrs." },
  { id: 4, label: "Dr." },
];

export const OWNER_ROLE = [
  { id: 1, label: "Landlord" },
  { id: 2, label: "Investor" },
  { id: 3, label: "Tenant" },
  { id: 4, label: "Buyer" },
  { id: 5, label: "Enquirer" },
  { id: 6, label: "Developer" },
  { id: 7, label: "Seller" },
  { id: 8, label: "Agent" },
  { id: 9, label: "Power of Attorney" },
  { id: 10, label: "Other" },
];

export const FURNISHED_STATUS = 1;
export const UNFURNISHED_STATUS = 0;
export const PARTLY_FURNISHED_STATUS = 2;


export const PROPERTY_FURNISHINGS = [
  { id: 1, label: "Furnished" },
  { id: 0, label: "Unfurnished" },
  { id: 2, label: "Partly-Furnished" },
]

export const PROPERTY_OFF_PLAN_YES = 1;
export const PROPERTY_OFF_PLAN_NO = 2;

export const PROPERTY_OFF_PLANS = [
  { id: PROPERTY_OFF_PLAN_NO, label: "Ready to Move In" },
  { id: PROPERTY_OFF_PLAN_YES, label: "Off Plan" },
]

export const UNPUBLISHED_STATUS_ID = 0;
export const PUBLISHED_STATUS_ID = 1;
export const PENDING_STATUS_ID = 2;
export const DRAFT_STATUS_ID = 3;

export const PROPERTY_STATUSES = [
  { id: 0, label: "Un Published", color: "error" },
  { id: 1, label: "Published", color: "success" },
  { id: 2, label: "Pending", color: "warning" },
  { id: 3, label: "Draft", color: "secondary" },
];
