import { MuiTelInput } from "mui-tel-input";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";

const PhoneInput = ({ name, rules, label, allCountries = false, disabled = false }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { ref, ...field }, fieldState: { error } }) => {
        return (
          <MuiTelInput
            disabled={disabled}
            size="small"
            fullWidth
            label={label}
            onlyCountries={!allCountries && ["AE", "PK"]}
            defaultCountry="AE"
            error={!!error}
            focusOnSelectCountry
            forceCallingCode
            disableFormatting
            helperText={error?.message}
            {...field}
            inputRef={ref}
          />
        );
      }}
    />
  );
};

export default memo(PhoneInput);
