import React, { useEffect, useState } from "react";
import Select from "../FormElements/Select";
import { OWNER_ROLE, OWNER_SALUTATION } from "../../constants/property";
import { propertyRules } from "./propertyValidation";
import TextField from "../FormElements/TextField";
import PhoneInput from "../FormElements/PhoneInput";
import AutoComplete from "../FormElements/AutoComplete";
import { useGetAllCountriesQuery } from "../../redux/api/countriesApi";
import { useFormContext } from "react-hook-form";
import Phone from "../FormElements/Phone";

export default function OwnerInfoPicker({ editingNationality, currentAgency }) {
  const { data: nationalities, isFetching, isSuccess } = useGetAllCountriesQuery();
  const [phone, setPhone] = useState();

  const { resetField } = useFormContext();

  useEffect(() => {
    if (isSuccess && editingNationality) {
      resetField("nationality", { defaultValue: parseInt(editingNationality) });
    }
  }, [editingNationality, isSuccess]);
  return (
    <>
      <Select
        name="saluation"
        label="Salutation"
        optionId="label"
        options={OWNER_SALUTATION}
        rules={propertyRules.saluation}
      />

      <TextField name="ownfirstname" label="First Name" rules={propertyRules.ownfirstname} />

      <TextField name="ownlastname" label="Last Name" rules={propertyRules.ownlastname} />

      <TextField name="ownemail" label="Email" type="email" rules={propertyRules.ownemail} />

      {/* <PhoneInput name="mobileno" label="Mobile" rules={propertyRules.mobileno} /> */}
      <Phone
            name="mobileno"
            label="Mobile"
            defaultCountry={currentAgency.countryname}
            setPhone={setPhone}
            rules={propertyRules.mobileno}
          />

      <AutoComplete
        isLoading={isFetching}
        name="nationality"
        options={nationalities}
        optionId="countryid"
        optionLabel="countryname"
        label="Nationality"
        rules={propertyRules.nationality}
      />

      <Select name="ownrole" label="Role" options={OWNER_ROLE} rules={propertyRules.ownrole} />
    </>
  );
}
