import { Avatar, Box, Fab, FormHelperText, IconButton, Typography } from "@mui/material";
import { MuiFileInput } from "mui-file-input";
import { Controller, useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
import { AddAPhoto, Close } from "@mui/icons-material";

export default function FileInput({
  variant = "standard",
  required = true,
  shape,
  name,
  rules,
  label,
  sx,
}) {
  const { control } = useFormContext();
  if (variant === "avatar") {
    return (
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value, ...field }, fieldState: { error } }) => {
          return (
            <Box sx={{ position: "relative" }}>
              {!required && (
                <IconButton
                  onClick={() => onChange("")}
                  size="small"
                  backgroundColor="white"
                  sx={{ position: "absolute", top: 0, right: 0, zIndex: 1 }}
                >
                  <Close fontSize="small" />
                </IconButton>
              )}
              <Avatar
                variant={shape}
                component="label"
                htmlFor={name}
                id="inputImage"
                sx={{
                  width: "100px",
                  height: "100px",
                  cursor: "pointer",
                  ...sx,
                }}
                src={value && value instanceof File && URL.createObjectURL(value)}
              >
                <AddAPhoto fontSize="large" />
              </Avatar>
              <FormHelperText
                variant="outlined"
                error
                sx={{ textAlign: "center", maxWidth: "100px", margin: 0 }}
              >
                {error?.message}
              </FormHelperText>

              <input
                {...field}
                value={value?.fileName}
                onChange={(event) => {
                  onChange(event.target.files[0]);
                }}
                type="file"
                id={name}
                accept=".jpg, .jpeg, .png"
                hidden
              />
            </Box>
          );
        }}
      />
    );
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <MuiFileInput
          {...field}
          inputRef={ref}
          size="small"
          helperText={error?.message}
          error={!!error}
          label={label}
        />
      )}
    />
  );
}

FileInput.propTypes = {
  avatar: PropTypes.oneOf(["avatar", "standard"]),
};
