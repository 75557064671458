import { toast } from "react-toastify";

export const errorToast = (title) => {
  return toast.error(title ?? "Unkown Error Occurred, Please Try Again", {
    position: "top-center",
    autoClose: 3000,
    closeOnClick: true,
    theme: "light",
  });
};

export const successToast = (title) => {
  return toast.success(title ?? "Success", {
    position: "top-center",
    autoClose: 3000,
    closeOnClick: true,
    theme: "light",
  });
};
