import { apiSlice } from ".";
import { logout, setAgency } from "../features/authSlice";

export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    loginAgency: builder.mutation({
      query: (data) => ({
        url: `/Agency/login`,
        method: "POST",
        body: data,
      }),
    }),

    forgetAgencyPassword: builder.mutation({
      query: (data) => ({
        url: `/Agency/RequestPasswordReset`,
        method: "POST",
        body: data,
      }),
    }),
    resetAgencyPassword: builder.mutation({
      query: ({password, confirmPassword, token}) => ({
        url: `/Agency/ResetPassword`,
        method: 'POST',
        body: { 
          code: token,
          Password: password,
          ConfirmPassword: confirmPassword,
        },
      }),
    }),
    verifyToken: builder.query({
      query: (token) => `/Agency/TokenVerification?token=${token}`,
    }),

    signupAgency: builder.mutation({
      query: (data) => ({
        url: `/agency/agencySignup`,
        method: "POST",
        body: data,
      }),
    }),

    getAgency: builder.query({
      query: (agencyId) => `/Agency/GetAgencyTable/${agencyId}`,
      providesTags: ["Agency"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: agency } = await queryFulfilled;
          if (agency.length === 0) dispatch(logout())
          dispatch(setAgency(agency[0]));
        } catch {
          dispatch(logout());
        }
      },
    }),

    getAgencyDashboard: builder.query({
      query: (agencyId) => `/Dashboard/AgencyDashboard?agtid={agtid}&agcid=${agencyId}&proptyp=`,
    }),
  }),
});

export const {
  useLoginAgencyMutation,
  useForgetAgencyPasswordMutation,
  useResetAgencyPasswordMutation,
  useGetAgencyQuery,
  useVerifyTokenQuery,
  useLazyGetAgencyQuery,
  useGetAgencyDashboardQuery,
  useSignupAgencyMutation,
} = authApi;
