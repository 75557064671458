import { apiSlice } from ".";

const countriesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllCountries: builder.query({
      query: () => "/countryTables/getCountryTables",
    }),
  }),
});

export const { useGetAllCountriesQuery } = countriesApi;
