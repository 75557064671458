import { Button, Typography, styled } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { agentDefaultValues, agentRules } from "./agentValidation";
import { useGetAllCountriesQuery } from "../../redux/api/countriesApi";
import { useLazyGetStatesByCountryIdQuery } from "../../redux/api/statesApi";
import { useLazyGetCitiesByProvinceIdQuery } from "../../redux/api/citiesApi";
import TextField from "../FormElements/TextField";
import AutoComplete from "../FormElements/AutoComplete";
import PhoneInput from "../FormElements/PhoneInput";
import DatePicker from "../FormElements/DatePicker";
import PasswordField from "../FormElements/PasswordField";
import { AutoCompleteMultiple } from "../FormElements/AutoCompleteMultiple";
import { LANGUAGES } from "../../constants/languages";
import { useSelector } from "react-redux";
import { selectCurrentagency } from "../../redux/features/authSlice";
import AgentImage from "./AgentImage";
import AgentId from "./AgentId";

export default function AgentForm({ agentData = null, onEditAgent, onAddAgent, isSubmitting }) {
  const currentAgency = useSelector(selectCurrentagency);

  const title = useMemo(() => (agentData ? "Edit Agent" : "Add Agent"), [agentData]);
  const submitButtonName = useMemo(() => (agentData ? "Update Agent" : "Add Agent"), [agentData]);

  const methods = useForm({
    defaultValues: agentData ?? agentDefaultValues,
  });
  const { handleSubmit, watch } = methods;

  const watchCountryId = watch("countryid");
  const watchStateId = watch("provid");

  const { data: countries = [], isLoading: isLoadingGetAllCountries } = useGetAllCountriesQuery();
  const [getStates, { data: states = [], isLoading: isLoadingGetStates }] =
    useLazyGetStatesByCountryIdQuery();
  const [getCities, { data: cities = [], isLoading: isLoadingGetCities }] =
    useLazyGetCitiesByProvinceIdQuery();

  useEffect(() => {
    if (watchCountryId) {
      getStates(watchCountryId);
    }
  }, [watchCountryId]);

  useEffect(() => {
    if (watchStateId) {
      getCities(watchStateId);
    }
  }, [watchStateId]);

  const onSubmit = (data) => {
    let postData = data;
    postData.agencyid = currentAgency.agencyid;
    postData.status = 0;

    if (agentData) return onEditAgent(postData);

    return onAddAgent(postData);
  };

  return (
    <div>
      <Typography variant="h2">{title}</Typography>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledTop>
            <Typography variant="h3" mt={4}>
              Agent Details
            </Typography>

            <AgentImage />
          </StyledTop>

          <StyledInputsContainer>
            <TextField name="agentname" label="Agent Name" rules={agentRules.agentname} />

            <TextField name="agentregno" label="Agent Reg No." rules={agentRules.agentregno} />

            <DatePicker
              name="agentcareerstarting"
              label="Career Start"
              rules={agentRules.agentcareerstarting}
            />

            <PhoneInput name="agentphone" label="Phone" rules={agentRules.agentphone} />

            <AutoComplete
              options={countries}
              name="nationality"
              label="Nationality"
              optionId="countryid"
              optionLabel="countryname"
              rules={agentRules.nationality}
              isLoading={isLoadingGetAllCountries}
            />

            <AutoComplete
              options={countries}
              name="countryid"
              label="Select Country"
              optionId="countryid"
              optionLabel="countryname"
              rules={agentRules.countryid}
              isLoading={isLoadingGetAllCountries}
            />

            <AutoComplete
              options={states}
              name="provid"
              label="Select State/ Province"
              optionId="provid"
              optionLabel="provname"
              disabled={!watchCountryId}
              rules={agentRules.provid}
              isLoading={isLoadingGetStates}
            />

            <AutoComplete
              options={cities}
              name="cityid"
              label="City Name"
              optionId="cityid"
              optionLabel="cityname"
              disabled={!watchStateId}
              rules={agentRules.cityid}
              isLoading={isLoadingGetCities}
            />

            <TextField name="agentemail" label="Agent Email" rules={agentRules.agentemail} />

            <PasswordField
              name="agentpassword"
              label="Agent Password"
              rules={agentRules.agentpassword}
            />
            <AgentId />

            <AutoCompleteMultiple
              multiple
              options={LANGUAGES}
              name="agentLanguages"
              label="Select Languages"
              rules={agentRules.agentLanguages}
            />
          </StyledInputsContainer>

          <TextField
            name="agentdescription"
            label="Description"
            rules={agentRules.agentdescription}
            multiline
            rows={4}
            sx={{ my: 3, maxWidth: "600px", display: "block" }}
          />

          <Button type="submit" variant="contained" sx={{ mt: 2, color: "white" }} disabled={isSubmitting}>
            {submitButtonName}
          </Button>
        </form>
      </FormProvider>
    </div>
  );
}

const StyledTop = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const StyledInputsContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 1fr",
  gap: "24px",
  marginTop: "24px",
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "1fr 1fr 1fr",
  },

  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr 1fr ",
  },

  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "1fr ",
  },
}));
