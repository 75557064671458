import React from "react";
import LeadForm from "../../components/LeadForm";
import { useNavigate, useParams } from "react-router-dom";
import { useEditLeadMutation, useGetLeadByIdQuery } from "../../redux/api/leadsApi";
import { Box, CircularProgress } from "@mui/material";
import { useLeadsData } from "../../hooks/useContext/leadsData";

export default function EditLead() {
  const { leadId } = useParams();
  const lead = useGetLeadByIdQuery(leadId, { refetchOnMountOrArgChange: true });
  const [editLead] = useEditLeadMutation();
  const navigate = useNavigate();
  const { refetch } = useLeadsData();
  const onEditLead = (data) => {
    editLead(data)
      .unwrap()
      .then((res) => {
         if (data.directLeadAgentId) {
          navigate("/leads?lc=exclusive");
        } else {
          refetch();
          navigate(-1);
        }
      });
  };

  if (lead.isFetching) {
    return (
      <Box
        sx={{ height: "300px", display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (lead.error) {
    return <div>Unkown Error Occured</div>;
  }

  return <LeadForm title="Edit Lead" onEditLead={onEditLead} leadData={lead && lead.data[0]} />;
}
