import { useMemo, useState, React } from "react";
// import { selectCurrentAgent } from "../../redux/features/authSlice";
import { useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import MaterialReactTable from "material-react-table";
import {
  Button,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { LEAD_TYPES } from "../../../constants/leads";
import styled from "styled-components";
import { VIEW_COLUMNS } from "../leadsData";
import { useLeadsData } from "../../../hooks/useContext/leadsData";
import { LeadTable } from "../LeadTable";
import { selectCurrentagency } from "../../../redux/features/authSlice";
import {
  useEditLeadPoolMutation,
  useGetAgentsQuery,
} from "../../../redux/api/leadsApi";
const ViewLead = () => {
  const { id } = useParams();
  const currentPath = window.location.pathname;
  const segments = currentPath.split("/");
  const agentId = segments[3];
  const currentAgency = useSelector(selectCurrentagency);
  const [searchParams, setSearchParams] = useSearchParams();
  // const [clientName, setClientName] = useState("");
  // const [selectedAgent, setSelectedAgent] = useState(null);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedAgentId, setSelectedAgentId] = useState(null);
  const isMobileScreen = useMediaQuery("(max-width:768px)");
  const { data: allAgents } = useGetAgentsQuery(currentAgency?.agencyid);
  const leadType = searchParams.get("lt");
  const [selectedLeads, setSelectedLeads] = useState([]); // Store selected leads
  //Get all agency leads
  const { agencyLeads, LeadsLoading, refetch } = useLeadsData();
  // Get leads filtered through agent id
  const filterAgentId = agencyLeads?.filter(
    (item) => item.agentid === parseInt(agentId)
  );
  const filteredLeads = useMemo(() => {
    if (!agencyLeads) {
      return [];
    }

    // let filteredData = filterAgentId?.filter(
    //   (item) => item.userid === parseInt(id)
    // );

    // const firstname =
    //   filteredData[0]?.firstname !== null ? filteredData[0]?.firstname : "";
    // const lastname =
    //   filteredData[0]?.lastname !== null ? filteredData[0]?.lastname : "";

    // setClientName(`${firstname ? firstname : ""} ${lastname ? lastname : "" }`);

    const leadTypeId = parseInt(leadType);

    if ([1, 2, 3].includes(leadTypeId)) {
      filterAgentId = filterAgentId.reduce((acc, item) => {
        const matchingProperties = item.Properties.filter(
          (property) => property.leadtype === leadTypeId
        );

        if (matchingProperties.length > 0) {
          acc.push({ ...item, Properties: matchingProperties });
        }

        return acc;
      }, []);
    }

    return filterAgentId;
  }, [agencyLeads, leadType]);

  // const filteredProperties =
  //   filteredLeads && filteredLeads.map((lead) => lead.Properties);
  // const filteredLeadsArray = filterAgentId.flatMap((lead) => {
  //   return lead.Properties.map((property) => ({
  //     ...property,
  //     firstName: lead.firstname,
  //     lastName: lead.lastname,
  //     useremail: lead.useremail,
  //     userid: lead.userid,
  //     userphone: lead.userphone,
  //   }));
  // });

  const onChangeLeadType = (type) => {
    setSearchParams((prev) => {
      return new URLSearchParams({
        ...Object.fromEntries(prev),
        lt: type,
      });
    });
  };
  const isAllLeads = LEAD_TYPES.every(
    (item) => item.id.toString() !== leadType
  );

  const [editLeadPool] = useEditLeadPoolMutation();

  const onChangeAction = (e, leads) => {
    if (e.target.value === 0) {
      // Open agent selection dialog
      setDialogOpen(true);
      setSelectedLeads(leads); // Store selected leads when opening the dialog
    }
  };

  const handleAssign = () => {
    if (selectedAgentId) {
      const formattedLeads = selectedLeads.map((lead) => ({
        leadSerialNo: lead.leadSerialNo,
        leadid: lead.leadid,
        userid: id,
        propertyid: lead.propertyid,
        description: lead.description,
        leadtype: lead.leadtype,
        leadTime: lead.leadTime,
        agentid: selectedAgentId, // Assign the selected agent
        agencyid: lead.agencyid,
        leadTag: lead.leadTag,
        leadStatus: lead.leadStatus,
        sourceId: lead.sourceId,
        mediumId: lead.mediumId,
        priorityId: lead.priorityId,
        companyName: lead.companyName,
        designation: lead.designation,
        budget: lead.budget,
        location: lead.location,
      }));
      editLeadPool(formattedLeads)
        .unwrap()
        .then((response) => {
          refetch();
          setDialogOpen(false);
        })
        .catch((error) => {
          console.error("Error updating leads", error);
        });
    }
  };
  return (
    <>
      <StyledButton
        size="small"
        variant={isAllLeads ? "contained" : "outlined"}
        sx={{
          color: isAllLeads ? "white" : "",
        }}
        onClick={() => onChangeLeadType("all")}
      >
        {`All Leads ${
          filterAgentId && filterAgentId.length > 0
            ? "of " + filterAgentId[0].agentname
            : ""
        }`}
      </StyledButton>
      {/* {LEAD_TYPES.map((button) => (
        <StyledButton
          size="small"
          sx={{
            color: button.id.toString() === leadType ? "white" : "#56C662",
            bgcolor:
              button.id.toString() === leadType ? "#56C662" : "transparent",
          }}
          variant={button.id.toString() === leadType ? "contained" : "outlined"}
          onClick={() => onChangeLeadType(button.id)}
        >
          {button.name}
        </StyledButton>
      ))} */}

      <div className="relative">
        {/* <h1 className="mt-5 mb-5 capitalize"> */}
        {/* {LeadsLoading ? "" : } Leads Information */}
        {/* </h1> */}

        <LeadTable
          key={2}
          columns={VIEW_COLUMNS}
          leads={filterAgentId ? filterAgentId : []}
          isLoading={LeadsLoading}
          onChangeAction={(onChangeAction)}
          applyColor={true}
        />

        <Dialog
          open={isDialogOpen}
          onClose={() => setDialogOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Select Agent</DialogTitle>
          <DialogContent>
            <RadioGroup
              value={selectedAgentId}
              onChange={(e) => setSelectedAgentId(e.target.value)}
            >
              {allAgents &&
                allAgents.map((agent) => (
                  <FormControlLabel
                    key={agent.agentid}
                    value={agent.agentid?.toString()}
                    control={<Radio />}
                    label={agent.agentname}
                  />
                ))}
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleAssign} disabled={!selectedAgentId}>
              Assign
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default ViewLead;

const StyledButton = styled(Button)(({ theme }) => ({
  marginRight: "8px",
}));
