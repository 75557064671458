import React, { memo } from "react";

import {
  Autocomplete as AutoCompleteMUI,
  CircularProgress,
  TextField,
  createFilterOptions,
} from "@mui/material";

import { Controller, useFormContext } from "react-hook-form";

const filterOptions = createFilterOptions({
  matchFrom: "start",
  limit: 100,
});

const AutoComplete = ({
  name,
  rules,
  label,
  optionId = "id",
  optionLabel = "label",
  disabled = false,
  options = [],
  size = "small",
  isLoading,
  sx,
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        const { onChange, value, ref } = field;
        return (
          <AutoCompleteMUI
            disabled={disabled}
            filterOptions={filterOptions}
            value={
              value
                ? options.find((option) => {
                    return value === option[optionId];
                  }) ?? null
                : null
            }
            getOptionLabel={(option) => {
              return option[optionLabel];
            }}
            onChange={(event, newValue) => {
              onChange(newValue ? newValue[optionId] : null);
            }}
            size={size}
            options={options}
            renderInput={(params) => (
              <TextField
              
                {...params}
                inputProps={{
                  autocomplete: "new-password",
                  form: {
                    autocomplete: "off",
                  },
                  ...params.inputProps,
                }}
                SelectProps={{
                  MenuProps: {
                    sx: { maxHeight: "400px" },
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isLoading ? <CircularProgress size={20} /> : null}
                    </React.Fragment>
                  ),
                }}
                label={label}
                error={!!error}
                helperText={error?.message}
                inputRef={ref}
                sx={{...sx}}

              />
            )}
          />
        );
      }}
    />
  );
};

export default memo(AutoComplete);
