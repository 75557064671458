import { Button, CircularProgress, Typography, styled } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { agencyProfileDefaultValues, agencyProfileRules } from "./agencyProfileValidation";
import { FormProvider, useForm } from "react-hook-form";
import FileInput from "../FormElements/FileInput";
import { useGetAllCountriesQuery } from "../../redux/api/countriesApi";
import { useLazyGetStatesByCountryIdQuery } from "../../redux/api/statesApi";
import { useLazyGetCitiesByProvinceIdQuery } from "../../redux/api/citiesApi";
import TextField from "../FormElements/TextField";
import AutoComplete from "../FormElements/AutoComplete";
import DatePicker from "../FormElements/DatePicker";
import {
  AGENCY_LICENSE_IMAGES_URL,
  AGENCY_LOGOS_URL,
  AGENCY_WATERMARKS_URL,
} from "../../constants/url";
import Select from "../FormElements/Select";
import {
  WATERMARK_OPACITIES,
  WATERMARK_POSITIONS,
  WATERMARK_SIZES,
} from "../../constants/watermark";
import PhoneInput from "../FormElements/PhoneInput";
import { useUploadImageMutation } from "../../redux/api/filesApi";

export default function AgencyProfileForm({ agencyData, onAddAgency, onEditAgency, isSubmitting }) {
  const methods = useForm({
    defaultValues: agencyData ?? agencyProfileDefaultValues,
  });

  const { watch, resetField, handleSubmit } = methods;

  const watchCountryId = watch("countryid");
  const watchStateId = watch("provid");

  const { data: countries = [], isLoading: isLoadingGetAllCountries } = useGetAllCountriesQuery();
  const [getStates, { data: states = [], isLoading: isLoadingGetStates }] =
    useLazyGetStatesByCountryIdQuery();
  const [getCities, { data: cities = [], isLoading: isLoadingGetCities }] =
    useLazyGetCitiesByProvinceIdQuery();
  const [uploadImage, { isLoading: isLoadingUploadImage }] = useUploadImageMutation();

  useEffect(() => {
    resetField("provid");
    resetField("cityid");
    if (watchCountryId) {
      getStates(watchCountryId);
    }
  }, [watchCountryId]);

  useEffect(() => {
    resetField("cityid");
    if (watchStateId) {
      getCities(watchStateId);
    }
  }, [watchStateId]);

  const onSubmit = async (data) => {
    if (agencyData) return onEditAgency(data);

    onAddAgency(data);
  };

  return (
    <div>
      <Typography variant="h2" mb={4}>
        Agency Profile
      </Typography>
      {agencyData && (
        <Typography variant="h5" mb={2}>
          Agency ID: {agencyData.agencyid}
        </Typography>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <StyledInputsContainer>
            <TextField
              label="Agency Name"
              name="agencyname"
              rules={agencyProfileRules.agencyname}
            />

            <TextField
              label="Agency Email"
              name="agencyemail"
              rules={agencyProfileRules.agencyemail}
            />

            <TextField
              label="Agency password"
              name="agencypassword"
              rules={agencyProfileRules.agencypassword}
            />

            <TextField
              label="Agency Ref Tag"
              name="agcRefTag"
              rules={agencyProfileRules.agcRefTag}
            />

            <TextField
              type="number"
              label="Property Ref No Starting"
              name="refnostartingprop"
              rules={agencyProfileRules.refnostartingprop}
            />

            <TextField type="number" label="Agency Reg No" name="agencyregno" />

            <PhoneInput
              name="agencyphone"
              label="Agency Phone"
              rules={agencyProfileRules.agencyphone}
            />

            <TextField label="Agency UAN" name="agencyuan" />

            <TextField
              label="Agency Address"
              name="agencyaddress"
              rules={agencyProfileRules.agencyaddress}
            />

            <AutoComplete
              options={countries}
              name="countryid"
              label="Select Country"
              optionId="countryid"
              optionLabel="countryname"
              disabled={isLoadingGetAllCountries}
              rules={agencyProfileRules.countryid}
              isLoading={isLoadingGetAllCountries}
            />

            <AutoComplete
              options={states}
              name="provid"
              label="Select State/ Province"
              optionId="provid"
              optionLabel="provname"
              disabled={!watchCountryId || isLoadingGetStates}
              rules={agencyProfileRules.provid}
              isLoading={isLoadingGetStates}
            />

            <AutoComplete
              options={cities}
              name="cityid"
              label="City Name"
              optionId="cityid"
              optionLabel="cityname"
              disabled={!watchStateId || isLoadingGetCities}
              rules={agencyProfileRules.cityid}
              isLoading={isLoadingGetCities}
            />

            <Select name="agencywatermarksize" label="Watermark Size" options={WATERMARK_SIZES} />

            <Select
              name="agencywatermarkposition"
              options={WATERMARK_POSITIONS}
              label="Watermark Position"
            />

            <Select
              name="agencytransparency"
              options={WATERMARK_OPACITIES}
              label="Watermark Transparency"
            />

            <TextField label="License No" name="licenseno" rules={agencyProfileRules.licenseno} />

            <DatePicker
              label="License Issued"
              name="issuedate"
              rules={{ required: "field required" }}
            />

            <DatePicker
              label="License Expiry"
              name="expdate"
              rules={{ required: "field required" }}
            />
          </StyledInputsContainer>
          <TextField
            label="Agency Profile Description"
            placeholder="Please provide a brief description of your agency, including its mission, services, and key strengths."
            name="Agency_Descrip"
            rules={agencyProfileRules.Agency_Descrip}
            multiline
            rows={10}
            sx={{ my: 3 }}
          />

          <StyledImagesContainer>
            <StyledImageWrapper>
              {agencyData && (
                <>
                  {" "}
                  <Typography variant="h4">Current Logo</Typography>
                  <StyledImage src={AGENCY_LOGOS_URL + agencyData.agencylogo} alt="logo" />
                </>
              )}

              <Typography variant="h4" mt={2}>
                {agencyData ? "Update" : "Add"} Logo
              </Typography>
              <FileInput
                name="agencylogo"
                variant="avatar"
                shape="rounded"
                required={false}
                sx={{ width: "200px", height: "150px", "& img": { objectFit: "contain" } }}
              />
            </StyledImageWrapper>

            <StyledImageWrapper>
              {agencyData && (
                <>
                  <Typography variant="h4">Current Watermark</Typography>
                  <StyledImage
                    src={AGENCY_WATERMARKS_URL + agencyData.agencywatermark}
                    alt="agency watermark"
                  />
                </>
              )}

              <Typography variant="h4" mt={2}>
                {agencyData ? "Update" : "Add"} Watermark
              </Typography>
              <FileInput
                name="agencywatermark"
                variant="avatar"
                shape="rounded"
                required={false}
                sx={{ width: "200px", height: "150px", "& img": { objectFit: "contain" } }}
              />
            </StyledImageWrapper>

            <StyledImageWrapper>
              {agencyData && (
                <>
                  {" "}
                  <Typography variant="h4">Current Agency License</Typography>
                  <StyledImage
                    src={AGENCY_LICENSE_IMAGES_URL + agencyData.agencylicensepic}
                    alt="license pic"
                  />
                </>
              )}

              <Typography variant="h4" mt={2}>
                {agencyData ? "Update" : "Add"} License
              </Typography>
              <FileInput
                name="agencylicensepic"
                variant="avatar"
                shape="rounded"
                required={false}
                sx={{ width: "200px", height: "150px", "& img": { objectFit: "contain" } }}
              />
            </StyledImageWrapper>
          </StyledImagesContainer>

          <Button
            type="submit"
            variant="contained"
            size="large"
            sx={{ mt: 6 }}
            disabled={isSubmitting || isLoadingUploadImage}
            endIcon={
              isSubmitting || isLoadingUploadImage ? <CircularProgress size={20} /> : undefined
            }
          >
            Submit Details
          </Button>
        </FormProvider>
      </form>
    </div>
  );
}

const StyledInputsContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 1fr",
  gap: "24px",
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "1fr 1fr 1fr",
  },

  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr 1fr ",
  },

  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "1fr ",
  },
}));

const StyledImagesContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-around",
  columnGap: "16px",
  rowGap: "32px",
  flexWrap: "wrap",
}));

const StyledImageWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  alignItems: "center",
}));

const StyledImage = styled("img")(({ theme }) => ({
  //   border: `1px solid ${theme.palette.secondary.light}`,
  borderRadius: "10px",
  width: "200px",
  height: "150px",
  objectFit: "contain",
}));
