import { Chip, Tooltip } from "@mui/material";
import { AGENT_IMAGES_URL } from "../../constants/url";
import { Link } from "react-router-dom";
import { EDIT_AGENT_ROUTE } from "../../constants/routes";

export const AGENT_STATUSES = {
  0: "In Active",
  1: "Active",
};

export const AGENT_COLUMNS = [
  {
    accessorKey: "agentid",
    header: "ID",
    size: 20,
    Cell: ({ row }) => <Link to={EDIT_AGENT_ROUTE + row.original.agentid} >{row.original.agentid}</Link>,
  },
  { accessorKey: "agentregno", header: "Reg No", size: 20 },
  { accessorKey: "agentname", header: "Name ", size: 20 },
  { accessorKey: "agentemail", header: "Email", size: 20 },
  { accessorKey: "agentphone", header: "Phone", size: 20 },
  {
    header: "Image",
    accessorKey: "agentimage",
    Cell: ({ row }) => (
      <img
        src={AGENT_IMAGES_URL + row.original.agentimage}
        alt={"agent"}
        style={{ width: "50px", height: "50px" }}
      />
    ),
    size: 30,
  },
  {
    header: "Description",
    accessorKey: "agentdescription",
    Cell: ({ row }) => (
      <Tooltip title={row.original.agentdescription} placement="top">
        <div
          style={{
            width: "150px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {row.original.agentdescription}
        </div>
      </Tooltip>
    ),
    size: 30,
  },
  {
    header: "Status",
    accessorKey: "status",
    Cell: ({ row }) => (
      <Chip
        label={AGENT_STATUSES[row.original.status]}
        variant="filled"
        color={row.original.status === 1 ? "success" : "error"}
        size="small"
      />
    ),
    size: 30,
  },
];
