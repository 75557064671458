

export function getTimeAgoString(date) {
    const currentTime = new Date();
    const inputDate = new Date(date);

    const timeDifferenceInMilliseconds = currentTime - inputDate;
    const timeDifferenceInDays = timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24); // 5184000000 = 60 * 60 * 24 * 1000

    if (timeDifferenceInDays < 1) return "Today";
    if (timeDifferenceInDays < 7) {
        const days = Math.floor(timeDifferenceInDays);
        return `${days} ${days === 1 ? "day" : "days"} ago`;
    }
    if (timeDifferenceInDays < 30) {
        const weeks = Math.floor(timeDifferenceInDays / 7);
        return `${weeks} ${weeks === 1 ? "week" : "weeks"} ago`;
    }

    if (timeDifferenceInDays < 365) {
        const months = Math.floor(timeDifferenceInDays / 30);
        return `${months} ${months === 1 ? "month" : "months"} ago`;
    }

    const years = Math.floor(timeDifferenceInDays / 365);
    return `${years} ${years === 1 ? "year" : "years"} ago`;
}

export const formatDate = (dateString) => {
    const options = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit', 
      second: '2-digit',
      hour12: true
    };
    return new Date(dateString).toLocaleString(undefined, options);
  };