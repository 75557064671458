import React, { useRef, useState } from "react";
import {
  Button,
  styled,
  useMediaQuery,
  Grid,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import * as XLSX from "xlsx";
import { useGetLeadAgentsQuery, usePostLeadToPoolMutation } from "../../redux/api/leadsApi";
import { selectCurrentagency } from "../../redux/features/authSlice";
import { AGENT_COLUMNS } from "./leadsData";
import MaterialReactTable from "material-react-table";
import { ADD_LEAD_ROUTE, All_LEADS_ROUTE, LEADS_POOL_ROUTE } from "../../constants/routes";
import ExcelDialogTable from "../../components/ExcelDialogTable";

const Table = ({ leads, isLoading, columns }) => {
  const isMobileScreen = useMediaQuery("(max-width:768px)");

  return (
    <MaterialReactTable
      enableRowActions={false}
      columns={columns}
      data={leads}
      getRowId={(row) => row.agentid}
      state={{
        showSkeletons: isLoading,
      }}
      initialState={{ density: "compact" }}
      enableDensityToggle={false}
      enableStickyHeader={!isMobileScreen}
      muiTableContainerProps={{
        sx: { maxHeight: isMobileScreen ? "100%" : "calc(100vh - 270px)" },
      }}
    />
  );
};

export default function AgentsLead() {
  const currentAgency = useSelector(selectCurrentagency);
  const { data: agents, isLoading } = useGetLeadAgentsQuery(
    currentAgency?.agencyid
  );
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [loading, setLoading] = useState(false); 
  const [postLeadPool] = usePostLeadToPoolMutation();

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setLoading(true);

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);

        setExcelData(jsonData);
        setOpenDialog(true);
      } catch (error) {
        console.error("Error parsing file: ", error);
      } finally {
        setLoading(false);
      }
    };

    reader.onerror = (error) => {
      console.error("File reading error: ", error);
      setLoading(false);
    };

    reader.readAsArrayBuffer(file);
  };

  const triggerFileUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSave = (formattedLeads) => {
    postLeadPool(formattedLeads)
      .unwrap()
      .then((response) => {
        console.log("Lead posted successfully", response);
        setOpenDialog(false);
      })
      .catch((error) => {
        console.error("Error posting lead", error);
      });
  };

  return (
    <>
      <StyledTop container spacing={2}>
        <Grid item>
          <StyledButton
            size="small"
          >
            Agency Total Leads{" "}
            {agents && agents[0] && agents[0].LeadCountPerAgency}
          </StyledButton>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item>
            <Button
              component={Link}
              to={ADD_LEAD_ROUTE}
              variant="contained"
              size="small"
              sx={{ color: "white" }}
            >
              Add Lead
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={triggerFileUpload}
              size="small"
              sx={{ color: "white" }}
            >
              Upload Lead
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => navigate(LEADS_POOL_ROUTE)}
              size="small"
              sx={{ color: "white" }}
            >
              Lead Pool
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => navigate(All_LEADS_ROUTE)}
              size="small"
              sx={{ color: "white" }}
            >
              All Agency Leads
            </Button>
          </Grid>
        </Grid>
        <input
          type="file"
          accept=".xlsx, .xls"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileUpload}
        />
      </StyledTop>

      <Table
        key={2}
        columns={AGENT_COLUMNS}
        leads={agents || []}
        isLoading={isLoading}
      />

      <ExcelDialogTable
        open={openDialog}
        onClose={handleCloseDialog}
        excelData={excelData}
        loading={loading}
        onSave={handleSave}
      />
    </>
  );
}

const StyledTop = styled(Grid)(({ theme }) => ({
  marginTop: "16px",
  marginBottom: "16px",
  display: "flex",
  alignItems: "center",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginRight: "8px",
}));
