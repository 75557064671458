import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup as MuiRadioGroup,
    FormHelperText,
  } from "@mui/material";
  import React from "react";
  import { Controller, useFormContext } from "react-hook-form";
  
  export default function RadioGroup({
    name,
    rules,
    options,
    optionId = "id",
    optionLabel = "label",
  }) {
    const { control } = useFormContext();
    return (
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { ref, ...field }, fieldState: { error } }) => (
          <FormControl size="small" error={!!error}>
            <FormLabel>Off Plan / Ready to Move In</FormLabel>
            <MuiRadioGroup row name="row-radio-buttons-group" {...field}>
              {options.map((item) => (
                <FormControlLabel
                  key={item[optionId]}
                  value={item[optionId]}
                  control={<Radio size="small" />}
                  label={item[optionLabel]}
                />
              ))}
            </MuiRadioGroup>
            {error?.message && <FormHelperText>{error.message}</FormHelperText>}
          </FormControl>
        )}
      />
    );
  }
  