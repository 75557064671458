import React from "react";
import LeadForm from "../../components/LeadForm";
import { usePostLeadMutation } from "../../redux/api/leadsApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function AddLead() {
  const [postLead, { isLoading }] = usePostLeadMutation();

  const navigate = useNavigate();

  const onAddLead = (data) => {
    postLead(data)
      .unwrap()
      .then(() => {
        if (data.directLeadAgentId) {
          navigate("/leads?lc=exclusive");
        } else {
          navigate("/leads");
        }
      }).catch((error) => {
        console.error("Error adding lead:", error);
        toast.error(`${error.data}`);
      });
  };


  return <LeadForm onAddLead={onAddLead} isSubmitting={isLoading} title={"Add Lead"} />;
}
