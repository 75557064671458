import { apiSlice } from ".";

const statesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStatesByCountryId: builder.query({
      query: (countryId) => `/provinceTables/getProvincebyCountry/${countryId}`,
    }),
  }),
});

export const {
  useLazyGetStatesByCountryIdQuery,

  useGetStatesByCountryIdQuery,
} = statesApi;
