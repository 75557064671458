import { MaterialReactTable } from "material-react-table";
import React, { useRef } from "react";
import { useMediaQuery } from "@mui/material";
import { AGENCY_LEAD_PROPERTY_COLUMNS } from "../pages/Properties/propertiesData";
import { RxCross2 } from "react-icons/rx";

export default function PropertyFilter({ filteredProperties, isFetching, setShowProperty, onSelectProperty }) {
    const isMobileScreen = useMediaQuery("(max-width:768px)");
    const tableRef = useRef();

    const handleRowClick = (row) => {
        onSelectProperty(row.original);
        setShowProperty(false);
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-white z-[999999]">
            <div className="relative m-2 w-[90vw] md:w-[70vw] lg:w-[60vw] max-h-[90vh] bg-white shadow-lg border border-gray-200 rounded-lg overflow-hidden flex flex-col">
                <div className="w-[50px]  p-3 cursor-pointer z-10" onClick={() => setShowProperty(false)}>
                    <RxCross2 size={24} />
                    {/* Close */}
                </div>


                <MaterialReactTable
                    state={{ showSkeletons: isFetching }}
                    columns={AGENCY_LEAD_PROPERTY_COLUMNS}
                    tableInstanceRef={tableRef}
                    data={filteredProperties ?? []}
                    enableFixed
                    enableStickyHeader={!isMobileScreen}
                    muiTableContainerProps={{
                        sx: { maxHeight: "60vh", maxWidth: "100%" },
                    }}
                    enableRowSelection={false}
                    enableDensityToggle={false}
                    initialState={{ density: "compact" }}
                    getRowId={(row) => row.propertyid}
                    muiTableBodyRowProps={({ row }) => ({
                        onClick: () => handleRowClick(row)
                    })}
                />
            </div>
        </div>
    );
}
