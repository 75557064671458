import { useCallback, useState } from "react";
// import Slider from "@material-ui/core/Slider";
import Cropper from "react-easy-crop";
import getCroppedImg from "./Crop";
import { Avatar, Box, Button, Fab, FormHelperText, Modal } from "@mui/material";
import { toast } from "react-toastify";
import { Controller, useFormContext } from "react-hook-form";
import { Close } from "@mui/icons-material";
import { useUploadImageMutation } from "../../redux/api/filesApi";
import { AGENT_IMAGES_URL } from "../../constants/url";

const initialCrop = { x: 0, y: 0 };

const AgentImage = () => {
  const [crop, setCrop] = useState(initialCrop);
  const [zoom, setZoom] = useState(1);
  const [image, setImage] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadAgentImage] = useUploadImageMutation();

  const { setValue, control } = useFormContext();

  const handleImageUpload = async (e) => {
    setZoom(1);
    if (e.target.files && e.target.files.length > 0) {
      setImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onUploadImage = useCallback(async () => {
    setIsLoading(true);
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels);

      let agentImageFormData = new FormData();
      agentImageFormData.append("", croppedImage);
      uploadAgentImage({ image: agentImageFormData, imageType: "agentimg" })
        .unwrap()
        .then((res) => setValue("agentimage", res));

      setImage(null);
    } catch (e) {
      toast.error("unkown error occured");
    } finally {
      setIsLoading(false);
    }
  }, [croppedAreaPixels, image]);

  const onClose = () => {
    URL.revokeObjectURL(image);
    setImage(null);
  };

  return (
    <div>
      <Controller
        control={control}
        name="agentimage"
        rules={{ required: "Agent Image is Required" }}
        render={({ field: { value, ...field }, fieldState: { error } }) => {
          return (
            <>
              <input
                {...field}
                type="file"
                accept="image/*"
                id="agent-image-input"
                onChange={handleImageUpload}
                hidden
              />
              <Avatar
                variant="rounded"
                src={AGENT_IMAGES_URL + value}
                component={"label"}
                htmlFor="agent-image-input"
                sx={{ width: "100px", height: "100px" }}
              />
              <FormHelperText
                variant="outlined"
                error
                sx={{ textAlign: "center", maxWidth: "100px", margin: 0 }}
              >
                {error?.message}
              </FormHelperText>
            </>
          );
        }}
      />

      {!!image && (
        <Modal
          open={!!image}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              height: "100%",
              width: "100%",
              maxWidth: { xs: "375px", sm: "500px" },
              maxHeight: { xs: "375px", sm: "500px" },
              position: "relative",
            }}
          >
            <Cropper
              style={{ containerStyle: { margin: "auto", width: "100%", height: "100%" } }}
              objectFit="cover"
              image={image}
              crop={crop}
              zoom={zoom}
              zoomSpeed={1}
              zoomWithScroll={true}
              showGrid={true}
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />

            <Box sx={{ marginTop: -6, display: "flex", gap: "20px", alignItems: "center" }}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => onUploadImage()}
                disabled={isLoading}
              >
                Upload
              </Button>

              <Fab sx={{ flexShrink: 0 }} size="small" onClick={onClose}>
                <Close fontSize="small" />
              </Fab>
            </Box>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default AgentImage;
