import React from "react";
import { Document, Page, View, StyleSheet, Text, Link, Font, Image } from "@react-pdf/renderer";
import { formatCurrency } from "../../constants/currency";
import BedIcon from "../../assets/icons/bed.png";
import BathIcon from "../../assets/icons/bath-tub.png";
import AreaIcon from "../../assets/icons/area.png";
import { PROPERTY_DURATION, PROPERTY_FURNISHINGS, PROPERTY_OFF_PLAN_YES } from "../../constants/property";
import { AGENCY_LOGOS_URL, AGENT_IMAGES_URL, PROPERTY_IMAGES_URL } from "../../constants/url";
import { PAKISTAN_ID } from "../../constants/countries";


Font.register({
    family: "DM Sans",
    fonts: [
        {
            src: "https://fonts.gstatic.com/s/dmsans/v14/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAopxhTmf3ZGMZpg.ttf",
        },
        {
            src: "https://fonts.gstatic.com/s/dmsans/v14/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAop1hTmf3ZGMZpg.ttf",
            fontWeight: "light",
        },
        {
            src: "https://fonts.gstatic.com/s/dmsans/v14/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAkJxhTmf3ZGMZpg.ttf",
            fontWeight: "medium",
        },
        {
            src: "https://fonts.gstatic.com/s/dmsans/v14/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAfJthTmf3ZGMZpg.ttf",
            fontWeight: "semibold",
        },
        {
            src: "https://fonts.gstatic.com/s/dmsans/v14/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwARZthTmf3ZGMZpg.ttf",
            fontWeight: "bold",
        },
        {
            src: "https://fonts.gstatic.com/s/dmsans/v14/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAIpthTmf3ZGMZpg.ttf",
            fontWeight: "ultrabold",
        },
        {
            src: "https://fonts.gstatic.com/s/dmsans/v14/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAC5thTmf3ZGMZpg.ttf",
            fontWeight: "heavy",
        },
    ],
});

const styles = StyleSheet.create({
    container: {
        fontFamily: "DM Sans",
    },
    header: {
        backgroundColor: "#0C2D48",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingHorizontal: 20,
    },
    agencyName: {
        fontSize: 18,
        fontWeight: "bold",
        color: "white",
    },
    agencyLogo: {
        width: 35,
        height: 35,
        objectFit: "contain",
    },
    wrapper: {
        paddingHorizontal: 20,
        marginTop: 20,
    },

    heading: {
        fontSize: 18,
        fontWeight: "bold",
        marginVertical: 4,
    },
    subHeading: {
        fontSize: 14,
        fontWeight: "bold",
        marginVertical: 4,
    },

    mediumText: {
        fontSize: 14,
        marginVertical: 2,
    },
    smallText: {
        fontSize: 12,
        marginVertical: 2,
        textDecoration: "none",
        color: "black",
    },

    pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 10,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
    },
});

const Header = ({ agencyName, agencyLogo }) => {
    return (
        <View style={styles.header} fixed>
            <Text style={styles.agencyName}>{agencyName}</Text>
            <Image style={styles.agencyLogo} src={AGENCY_LOGOS_URL + agencyLogo} />
        </View>
    );
};

const IconWithName = ({ name, src }) => {
    return (
        <View style={{ flexDirection: "row", alignItems: "center", gap: 8 }}>
            <Image src={src} style={{ width: 18, height: 18 }} />
            <Text style={styles.smallText}>{name}</Text>
        </View>
    );
};

const VerticalDivider = () => {
    return <View style={{ height: 18, width: 1, backgroundColor: "gray" }}></View>;
};

const Landing = ({
    refNo,
    title,
    type,
    purpose,
    address,
    bedrooms,
    bathrooms,
    area,
    price,
    duration,
    offPlan,
    furnishing
}) => {
    return (
        <View style={styles.wrapper}>
            <View
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Text style={styles.mediumText}>
                    <Text style={{ fontWeight: "bold" }}>Ref No. </Text>
                    {refNo}
                </Text>

                <Text style={styles.subHeading}>
                    {price} <Text style={{ fontSize: 12 }}>{duration}</Text>
                </Text>
            </View>

            <Text style={styles.heading}>{title}</Text>

            <Text style={{ ...styles.smallText, fontWeight: 'bold' }}>{type}</Text>

            <Text style={{ ...styles.smallText, fontWeight: 'bold' }}>For {purpose}</Text>

            <Text style={styles.smallText}>{address}</Text>

            <View
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 14,
                    marginTop: 10,
                }}
            >
                <IconWithName name={`${bedrooms} Bedrooms`} src={BedIcon} />

                <VerticalDivider />
                <IconWithName name={`${bathrooms} Bathrooms`} src={BathIcon} />
                <VerticalDivider />
                <IconWithName name={area} src={AreaIcon} />
                {furnishing && <>
                    <VerticalDivider />
                    <Text style={styles.smallText}>{furnishing}</Text>
                </>
                }

                <VerticalDivider />
                <Text style={styles.smallText}>{offPlan}</Text>

            </View>


        </View>
    );
};

const Description = ({ description }) => {
    return (
        <View style={styles.wrapper}>
            <Text style={styles.subHeading}>Description</Text>
            <Text style={{ ...styles.smallText, textAlign: "justify" }}>{description}</Text>
        </View>
    );
};

const Amenities = ({ amenities }) => {
    return (
        <View style={styles.wrapper} >
            <Text style={styles.subHeading}>Amenities</Text>

            <View
                style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: 8,
                }}
            >
                {amenities.map((amenity) => (
                    <Text style={{ ...styles.smallText, width: "32%" }}>{`>${amenity}`}</Text>
                ))}
            </View>
        </View>
    );
};

const Media = ({ video, images }) => {
    return (
        <View style={styles.wrapper} break>
            <Text style={styles.subHeading}>Media</Text>
            {video && (
                <Link style={{ ...styles.smallText, marginBottom: 12 }}>
                    Video Link: {video}
                </Link>
            )}
            <Text style={styles.subHeading}>Photo Gallery</Text>
            <View
                style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    marginTop: 12,
                }}
            >
                {images.map((item) => (
                    <View style={{ width: "50%", aspectRatio: 14 / 9, padding: 4 }}>
                        <Image
                            src={PROPERTY_IMAGES_URL + item}
                            cache={false}
                            style={{ width: "100%", borderRadius: 4, objectFit: "cover" }}
                        />
                    </View>
                ))}
            </View>
        </View>
    );
};

const ContactIcon = ({ src, href, name }) => {
    return (
        <View style={{ flexDirection: "row", alignItems: "center", gap: 4, marginVertical: 4 }}>
            <Image
                src={src}
                style={{
                    width: 12,
                    height: 12,
                    objectFit: "contain",
                }}
            />
            <Link src={href} style={styles.smallText}>
                {name}
            </Link>
        </View>
    );
};

const ContactAgent = ({ name, phone, email, website, image, description }) => {
    return (

        <View style={{ ...styles.wrapper, paddingTop: 12, borderTopWidth: 1, borderColor: "gray" }}>
            <View style={{ flexDirection: "row", alignItems: "center", gap: 24, marginBottom: 12 }}>
                <Image style={{ width: 100, height: 100 }} src={AGENT_IMAGES_URL + image} cache={false} />

                <View>
                    <Text style={styles.subHeading}>Contact {name}</Text>

                    <ContactIcon src={"/static/icons/phone.png"} name={phone} href={"tel:" + phone} />
                    <ContactIcon
                        src={"/static/icons/whatsapp.png"}
                        name={phone}
                        href={"https://wa.me/" + phone}
                    />
                    <ContactIcon src={"/static/icons/mail.png"} name={email} href={"mailto:" + email} />
                    <ContactIcon src={"/static/icons/website.png"} name={website} href={website} />
                </View>
            </View>

            <Text style={styles.smallText}>
                {description}
            </Text>
        </View>

    );
};

export const PropertyPdf = ({ property }) => {

    const area = property.countryid === PAKISTAN_ID ? property.propertysizemarla + " Marla" : property.propertysizesqft + " Sqft";

    return (
        <Document>
            <Page size="a4" style={styles.container}>
                <Header agencyName={property.agencyname} agencyLogo={property.agencylogo} />

                <Landing
                    address={`${property.cityname}, ${property.provname}, ${property.countryname}`}
                    refNo={property.propertyrefnum}
                    title={property.propertytitle}
                    purpose={property.propertypurpose1}
                    type={property.propertytype1}
                    bedrooms={property.bedrooms}
                    bathrooms={property.bathrooms}
                    area={area}
                    price={formatCurrency(property.propertyprice, property.countryid)}
                    duration={PROPERTY_DURATION.find((item) => item.id === property.duration).label}
                    offPlan={property.offPlan === PROPERTY_OFF_PLAN_YES ? "Off Plan" : "Ready to Move in"}
                    furnishing={PROPERTY_FURNISHINGS.find((item) => item.id === property.furnished)?.label}
                />

                <Description description={property.propertydescription} />

                <Amenities amenities={property.Amenities} />

                <Media video={property.propertyvideos} images={property.propertyimages} />

                <ContactAgent
                    name={property.agentname}
                    phone={property.agentphone}
                    email={property.agentemail}
                    website={`https://propertians.com/agent/${property.agentid}`}
                    image={property.agentimage}
                    description={property.agentdescription}
                />

                <Text
                    style={styles.pageNumber}
                    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                    fixed
                />


            </Page>
        </Document>
    );
};
