import { MaterialReactTable } from "material-react-table";
import React, { useRef, useState } from "react";
import { AGENCY_PROPERTY_COLUMNS } from "./propertiesData";
import { MenuItem, TextField, useMediaQuery } from "@mui/material";
import {
  useChangePropertyStatusMutation,
  useDeletePropertyMutation,
} from "../../redux/api/propertiesApi";
import { useSelector } from "react-redux";
import { selectCurrentagency } from "../../redux/features/authSlice";
import { toast } from "react-toastify";
import ShareLocation from "./ShareLocation";
import ShareYoutubeVideo from "./ShareYoutubeVideo";

const propertyStatusOptions = [
  {
    value: 0,
    label: "Un Publish",
  },
  {
    value: 1,
    label: "Publish",
  },
  {
    value: 2,
    label: "Pending ",
  },
  {
    value: 3,
    label: "Draft",
  },
  { value: 4, label: "Delete" },
  { value: 5, label: "Share PDF" },
  { value: 6, label: "Share Location" },
  { value: 7, label: "Share YouTube Video" },
];

export default function PropertiesTable({ isFetching, data, onClickSharePdf }) {
  const currentAgency = useSelector(selectCurrentagency);
  const [changePropertyStatus] = useChangePropertyStatusMutation();
  const [deleteProperty] = useDeletePropertyMutation();
  const isMobileScreen = useMediaQuery("(max-width:768px)");
  const [shareLocation, setShareLocation] = useState(false)
  const [location, setLocation] = useState([])
  const [shareYoutubeVideo, setShareYoutubeVideo] = useState(false)
  const [video, setVideo] = useState([])

  const tableRef = useRef();

  const handleStatusChange = (value, propertyIds) => {
    if (propertyIds.length === 0) return toast.warn("Please select at least one property");
    if (value === 4) {
      if (window.confirm("Are you sure you want to delete?")) {
        propertyIds.map((id) => {
          return deleteProperty({ agencyId: currentAgency.agencyid, propertyId: id });
        });
      }
      return tableRef?.current?.resetRowSelection();
    }

    if (value === 5) {
      return onClickSharePdf(propertyIds);
    }

    if (value === 6) {
      onShareProperties(propertyIds);
    };
    if (value === 7) {
      if (!video) {
        toast.error("Could not find a video link")
      }
      else {
        onShareYoutube(propertyIds);
      }
    }

    if (propertyStatusOptions.some((option) => option.value === value)) {
      propertyIds.map((id) => {
        return changePropertyStatus({
          agencyid: currentAgency.agencyid,
          propertyid: id,
          pstatus: value,
        });
      });

      tableRef?.current?.resetRowSelection();
    }
  };


  const onShareProperties = (propertyIds) => {
    setShareLocation(true)
  };

  const onShareYoutube = (newVideos) => {
    setShareYoutubeVideo(true)
  };

  return (
    <>
      <MaterialReactTable
        state={{ isLoading: isFetching }}
        columns={AGENCY_PROPERTY_COLUMNS}
        tableInstanceRef={tableRef}
        data={data ?? []}
        enableFixed
        enableStickyHeader={!isMobileScreen}
        muiTableContainerProps={{
          sx: { maxHeight: isMobileScreen ? "100%" : "calc(100vh - 320px)" },
        }}
        enableRowSelection
        enableDensityToggle={false}
        initialState={{ density: "compact" }}
        getRowId={(row) => row.propertyid}
        renderTopToolbarCustomActions={({ table }) => {
          const propertyIds = table.getSelectedRowModel().rows.map((row) => row.id);
          const location = table.getSelectedRowModel().rows.map((row) => setLocation(row.original.coordinates));
          const newVideos = table.getSelectedRowModel().rows.map((row) => setVideo(row.original.propertyvideos));
          return (
            <TextField
              key={0}
              select
              variant="outlined"
              size="small"
              sx={{ minWidth: "150px" }}
              label="Action"
              value=""
              onChange={(e) => handleStatusChange(e.target.value, propertyIds)}
            >
              {propertyStatusOptions.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </TextField>
          );
        }}
      />
      {
        shareLocation && (
          <div className="fixed top-0 left-0 z-10 bg-customGray w-full h-[100vh] flex justify-center items-center">
            <ShareLocation setShareLocation={setShareLocation} location={location} />
          </div>
        )
      }
      {
        shareYoutubeVideo && (
          <div className="fixed top-0 left-0 z-10 bg-customGray w-full h-[100vh] flex justify-center items-center">
            <ShareYoutubeVideo setShareYoutubeVideo={setShareYoutubeVideo} video={video} />
          </div>
        )
      }
    </>
  );
}
