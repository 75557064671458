import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    agencyId: localStorage.getItem("agencyId"),
    agency: null,
    token: localStorage.getItem("token"),
  },

  reducers: {
    setCredentials: (state, action) => {
      const { token, agencyId } = action.payload;
      state.token = token;
      state.agencyId = agencyId;
      localStorage.setItem("token", token);
      localStorage.setItem("agencyId", agencyId);
    },
    setAgency: (state, action) => {
      const agency = action.payload;
      state.agency = agency;
    },

    logout: (state, action) => {
      state.agencyId = null;
      state.agency = null;
      state.token = null;
      localStorage.removeItem("token");
      localStorage.removeItem("agencyId");
    },
  },
});

export const { setCredentials, logout, setAgency } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentagency = (state) => state.auth.agency;
export const selectCurrentToken = (state) => state.auth.token
