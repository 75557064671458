import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Switch,
  FormControlLabel,
  IconButton,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  List,
  ListItem,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useDealInstallmentsQuery } from "../../../../../redux/api/leadsApi";
import { TRANSACTION_TYPES } from "../../../../../constants/transaction";

const InstallmentPlans = ({
  remainingAmountPayDates = [],
  setRemainingAmountPayDates,
  remainingAmount,
  handleChange,
  dealPrice,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [numberOfRows, setNumberOfRows] = useState(1);
  const { data: INSTALLMENTS_TYPES = [] } = useDealInstallmentsQuery();

  useEffect(() => {
    // Generate installments when dealPrice or numberOfRows changes
    generateInstallments();
  }, [dealPrice]); // Added numberOfRows to dependency array

  const generateInstallments = () => {
    if (numberOfRows > 0) {
      const amountPerInstallment = remainingAmount / numberOfRows;
      const startDate = new Date();
      const newRows = Array.from({ length: numberOfRows }, (_, i) => {
        let currentDate = new Date(startDate);
        currentDate.setMonth(currentDate.getMonth() + i);
        return {
          amountReceived: amountPerInstallment.toFixed(2),
          amountReceivedDate: currentDate,
          paymentType: "",
          clearedStatus: 0,
          installmentId: INSTALLMENTS_TYPES[2]?.installmentId || "",
          isTransactionTypeVisible: false,
        };
      });
      setRemainingAmountPayDates(newRows);
    }
  };

  const handleDateChange = (newDate, index) => {
    const updatedDates = [...remainingAmountPayDates];
    updatedDates[index].amountReceivedDate = newDate;
    setRemainingAmountPayDates(updatedDates);

    const startDate = new Date(newDate);
    for (let i = index + 1; i < updatedDates.length; i++) {
      let nextDate = new Date(startDate);
      nextDate.setMonth(startDate.getMonth() + (i - index));
      updatedDates[i].amountReceivedDate = nextDate;
    }
    setRemainingAmountPayDates(updatedDates);
  };

  const handleAmountChange = (event, index) => {
    const updatedDates = [...remainingAmountPayDates];
    const newAmount = parseFloat(event.target.value).toFixed(2);

    // Update the current installment with the new amount
    updatedDates[index].amountReceived = newAmount;

    // Calculate the total of installments up to and including the current one
    const totalPaidUpToIndex = updatedDates
      .slice(0, index + 1)
      .reduce((sum, row) => sum + parseFloat(row.amountReceived || 0), 0);

    // Calculate the remaining amount to distribute
    let remainingAmountToDistribute = remainingAmount - totalPaidUpToIndex;

    // Distribute the remaining amount across the following installments
    for (let i = index + 1; i < updatedDates.length; i++) {
      if (remainingAmountToDistribute > 0) {
        const amountForThisInstallment = Math.min(
          updatedDates[i].amountReceived, // Keep original installment amount if possible
          remainingAmountToDistribute
        );
        updatedDates[i].amountReceived = amountForThisInstallment.toFixed(2);
        remainingAmountToDistribute -= amountForThisInstallment;
      } else {
        // Set to 0 if the remaining amount is fully distributed
        updatedDates[i].amountReceived = "0.00";
      }
    }

    setRemainingAmountPayDates(updatedDates);
  };

  const handleInstallmentTypeChange = (event, index) => {
    const updatedDates = [...remainingAmountPayDates];
    updatedDates[index].installmentId = event.target.value;
    setRemainingAmountPayDates(updatedDates);
  };

  const handlePaymentTypeChange = (event, index) => {
    const updatedDates = [...remainingAmountPayDates];
    updatedDates[index].paymentType = event.target.value;
    setRemainingAmountPayDates(updatedDates);
  };

  const handleDelete = (index) => {
    const updatedDates = remainingAmountPayDates.filter((_, i) => i !== index);
    setRemainingAmountPayDates(updatedDates);
  };

  const handleSwitchChange = (event, index) => {
    const updatedDates = [...remainingAmountPayDates];
    const isChecked = event.target.checked;
    updatedDates[index].clearedStatus = isChecked ? 1 : 0;
    updatedDates[index].isTransactionTypeVisible = isChecked;
    setRemainingAmountPayDates(updatedDates);
  };

  return (
    <StyledBox>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            label="Deal Price"
            type="number"
            fullWidth
            variant="outlined"
            size="small"
            value={dealPrice}
            onChange={handleChange("dealPrice")}
            onBlur={generateInstallments} // Call generateInstallments on blur
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            label="Number of Installments"
            variant="outlined"
            type="number"
            size="small"
            value={numberOfRows}
            onChange={(e) => setNumberOfRows(Number(e.target.value))}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Button
            variant="contained"
            fullWidth
            sx={{ color: "white" }}
            onClick={generateInstallments}
          >
            Generate
          </Button>
        </Grid>

        <Grid item xs={12} md={12}>
          {remainingAmountPayDates.length > 0 && (
            <StyledList>
              <Typography variant="h5">Installments:</Typography>
              {remainingAmountPayDates.map((entry, index) => (
                <StyledListItem key={index} divider>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={4} lg={3}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          sx={{ width: "100%" }}
                          value={entry.amountReceivedDate || null}
                          format="dd/MM/yyyy"
                          onChange={(newValue) =>
                            handleDateChange(newValue, index)
                          }
                          slotProps={{ textField: { size: "small" } }}
                          renderInput={(params) => (
                            <TextField {...params} label="Select Date" />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={2}>
                      <TextField
                        type="number"
                        value={entry.amountReceived || ""}
                        onChange={(event) => handleAmountChange(event, index)}
                        label="Amount"
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} lg={2}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel>Installment Type</InputLabel>
                        <Select
                          value={entry.installmentId || ""}
                          onChange={(event) =>
                            handleInstallmentTypeChange(event, index)
                          }
                          label="Installment Type"
                        >
                          {INSTALLMENTS_TYPES.map((option) => (
                            <MenuItem
                              key={option.installmentId}
                              value={option.installmentId}
                            >
                              {option.installmentPlanType}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={2}>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        size="small"
                        disabled={!entry.isTransactionTypeVisible} // Disable if switch is not enabled
                      >
                        <InputLabel>Payment Type</InputLabel>
                        <Select
                          value={entry.paymentType || ""}
                          onChange={(event) =>
                            handlePaymentTypeChange(event, index)
                          }
                          label="Payment Type"
                        >
                          {TRANSACTION_TYPES.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={1}>
                      <Tooltip title="Enable if this is Paid">
                        <FormControlLabel
                          control={
                            <Switch
                              checked={entry.clearedStatus === 1}
                              onChange={(event) =>
                                handleSwitchChange(event, index)
                              }
                              size="medium"
                            />
                          }
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={1}>
                      <Tooltip title="Remove">
                        <IconButton
                          onClick={() => handleDelete(index)}
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </StyledListItem>
              ))}
            </StyledList>
          )}
        </Grid>
      </Grid>
    </StyledBox>
  );
};

const StyledBox = styled(Box)({
  padding: "16px",
});

const StyledList = styled(List)({
  width: "100%",
});

const StyledListItem = styled(ListItem)({
  padding: "16px",
});

export default InstallmentPlans;
