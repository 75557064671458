import { toast } from "react-toastify";
import { apiSlice } from ".";

const filesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    uploadImage: builder.mutation({
      query: ({ image, imageType }) => ({
        url: `/AALuploader/AALuploadfiles?A=${imageType}`,
        method: "POST",
        body: image,
      }),
      async onQueryStarted(args, { queryFulfilled }) {
        toast.promise(queryFulfilled, {
          pending: "Uploading Image",
          success: "Image Uploaded",
          error: "Error Uploading Image",
        });
      },
    }),

    uploadPdf: builder.mutation({
      query: ({ formData, propertyId, pdfType }) => ({
        url: `/PDFUploader/UploadPDF?ver=${pdfType}&propid=${propertyId}`,
        method: "POST",
        body: formData,
      }),

      async onQueryStarted(args, { queryFulfilled }) {
        toast.promise(queryFulfilled, {
          pending: "Generating Pdf",
          success: "Pdf Generated",
          error: "Error Generating Pdf",
        })
      },
      invalidatesTags: ["AgencyProperties"]
    })


  }),
});

export const { useUploadImageMutation, useUploadPdfMutation } = filesApi;
