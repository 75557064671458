import React from "react";
import AgencyProfileForm from "../../components/AgencyProfileForm";
import { useSelector } from "react-redux";
import { selectCurrentagency } from "../../redux/features/authSlice";
import { useEditAgencyMutation } from "../../redux/api/agencyApi";
import { errorToast, successToast } from "../../constants/toast";
import { useNavigate } from "react-router-dom";
import { useUploadImageMutation } from "../../redux/api/filesApi";

export default function Profile() {
  const currentAgency =
    useSelector(selectCurrentagency);
  const [editAgency, { isLoading }] = useEditAgencyMutation();
  const navigate = useNavigate()
  const [uploadImage] = useUploadImageMutation();



  const onSubmit = async (data) => {

    let uploadedLogo = null;
    let uploadedWatermark = null;
    let uploadedLicensePic = null;


    let p1;
    let p2;
    let p3;

    if (data.agencylogo instanceof File) {
      let logoFormData = new FormData();
      logoFormData.append("files", data.agencylogo);
      p1 = uploadImage({ image: logoFormData, imageType: "agencylogo" })
        .unwrap()
        .then((res) => (uploadedLogo = res));
    }

    if (data.agencywatermark instanceof File) {
      let watermarkFormData = new FormData();
      watermarkFormData.append("files", data.agencywatermark);
      p2 = uploadImage({ image: watermarkFormData, imageType: "agencywm" })
        .unwrap()
        .then((res) => (uploadedWatermark = res));
    }

    if (data.agencylicensepic instanceof File) {
      let licensePicFormData = new FormData();
      licensePicFormData.append("files", data.agencylicensepic);

      p3 = uploadImage({ image: licensePicFormData, imageType: "agencylicpic" })
        .unwrap()
        .then((res) => (uploadedLicensePic = res));
    }

    await Promise.all([p1, p2, p3]).then((res) => {
      let postData = data;
      postData.agencylogo = uploadedLogo ?? data.agencylogo;
      postData.agencywatermark = uploadedWatermark ?? data.agencywatermark;
      postData.agencylicensepic = uploadedLicensePic ?? data.agencylicensepic;

      editAgency(data)
        .unwrap()
        .then((res) => {
          successToast("Agency Details Successfully Updated");
          navigate("/");
        })
        .catch((err) => errorToast("An error occured while updating agency pleaase try again"));

    });


  }

  return (
    <div>
      <AgencyProfileForm agencyData={currentAgency} onEditAgency={onSubmit} isSubmitting={isLoading} />
    </div>
  );
}
