import { MenuItem, TextField } from "@mui/material";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";

const Select = ({
  name,
  rules,
  label,
  fullWidth = true,
  size = "small",
  options,
  required = false,
  optionId = "id",
  optionLabel = "label",
  value,
  sx,
  ...props
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field:{ref, ...field}, fieldState: { error } }) => {
        return (
          <TextField
            name={name}
            label={label}
            fullWidth={fullWidth}
            variant="outlined"
            size={size}
            select
            error={!!error}
            helperText={error?.message}
            SelectProps={{MenuProps: {sx: {maxHeight: "400px"}}}}
            inputRef={ref}
            {...field}
            {...props}
          >
            {!required && <MenuItem value="">{label}</MenuItem>}

            {options.map((option) => {
              return (
                <MenuItem key={option[optionId]} value={option[optionId]}>
                  {option[optionLabel]}
                </MenuItem>
              );
            })}
          </TextField>
        );
      }}
    />
  );
};

export default memo(Select);
