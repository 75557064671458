export const WATERMARK_SIZES= [
    { id: 1, label: "20%" },
    { id: 2, label: "30%" },
    { id: 3, label: "40%" },
  ];
  
  export const WATERMARK_POSITIONS = [
    { id: 1, label: "Top Left" },
    { id: 2, label: "Bottom Left" },
    { id: 3, label: "Top Right" },
    { id: 4, label: "Bottom Right" },
    { id: 5, label: "Center" },
  ];
  
  export const WATERMARK_OPACITIES = [
    { id: 1, label: "20%" },
    { id: 2, label: "40%" },
    { id: 3, label: "60%" },
    { id: 4, label: "80%" },
    { id: 5, label: "100%" },
  ];
  