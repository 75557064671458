import { apiSlice } from ".";

export const amenitiesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllAmenities: builder.query({
      query: () => "/amenities/getAmenities",
      providesTags: ["Amenity"],
    }),
    getPropertyAmenities: builder.query({
      query: (propertyId) => `/Prop_Amen/GetProp_Amen_ByPropID/${propertyId}`,
      transformResponse: (res) => res[0]?.amenityInfor,
    }),
  }),
});

export const {
  useGetAllAmenitiesQuery,
  useLazyGetPropertyAmenitiesQuery,
} = amenitiesApi;
