import React, { useEffect } from "react";
import TextField from "../FormElements/TextField";
import { propertyRules } from "./propertyValidation";
import Select from "../FormElements/Select";
import { PROPERTY_DURATION } from "../../constants/property";
import { useFormContext, Controller } from "react-hook-form";
import TextFieldMUI from "@mui/material/TextField";

function PercentagePicker() {
  const { control, setValue, getValues } = useFormContext();
  return (
    <Controller
      name="percentage"
      control={control}
      rules={propertyRules.percentage}
      render={({ field: { ref, value, onChange, ...field }, fieldState: { error } }) => {
        return (
          <TextFieldMUI
            value={value}
            onChange={(e) => {
              const percentage = parseInt(e.target.value) || "";
              onChange(percentage);
              const propertyPrice = parseInt(getValues("propertyprice"));

              let commission = (propertyPrice * percentage) / 100 ?? 0;

              setValue("commission", commission);
            }}
            size="small"
            fullWidth
            label="Percentage %"
            type="number"
            error={!!error}
            helperText={error?.message}
            inputRef={ref}
            {...field}
          />
        );
      }}
    />
  );
}

function PricePicker() {
  const { control, setValue, getValues } = useFormContext();

  return (
    <Controller
      name="propertyprice"
      control={control}
      rules={propertyRules.propertyprice}
      render={({ field: { ref, value, onChange, ...field }, fieldState: { error } }) => {
        return (
          <TextFieldMUI
            value={value}
            onChange={(e) => {
              const propertyPrice = parseInt(e.target.value) || "";
              onChange(propertyPrice);
              const percentage = parseInt(getValues("percentage"));

              let commission = (propertyPrice * percentage) / 100 || 0;

              setValue("commission", commission);
            }}
            size="small"
            fullWidth
            label="Property Price"
            type="number"
            error={!!error}
            helperText={error?.message}
            inputRef={ref}
            {...field}
          />
        );
      }}
    />
  );
}

function CommissionPicker() {
  const { control, setValue, getValues } = useFormContext();
  return (
    <Controller
      name="commission"
      control={control}
      rules={propertyRules.commission}
      render={({ field: { ref, value, onChange, ...field }, fieldState: { error } }) => {
        return (
          <TextFieldMUI
            value={value}
            onChange={(e) => {
              const commission = parseInt(e.target.value) || "";
              onChange(commission);
              const propertyPrice = parseInt(getValues("propertyprice"));

              let percentage = (commission * 100) / propertyPrice ?? 0;

              setValue("percentage", percentage);
            }}
            size="small"
            fullWidth
            label="Commission"
            type="number"
            error={!!error}
            helperText={error?.message}
            inputRef={ref}
            {...field}
          />
        );
      }}
    />
  );
}

export default function PropertyPricePicker() {
  return (
    <>
      <PricePicker />
      <Select
        name="duration"
        label="Duration"
        options={PROPERTY_DURATION}
        rules={propertyRules.duration}
      />

      <TextField
        name="securityfee"
        label="Security Fee"
        type="number"
        rules={propertyRules.securityfee}
      />

      <PercentagePicker />

      <CommissionPicker />
    </>
  );
}
