import { apiSlice } from ".";

const citiesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCitiesByProvinceId: builder.query({
      query: (provinceId) => `/cityTables/getCityByProvince/${provinceId}`,
    }),
  }),
});

export const { useLazyGetCitiesByProvinceIdQuery } = citiesApi;
