import React, { useState } from "react";
import { Link } from "react-router-dom";
import { PROPERTY_PDFS_URL } from "../../../constants/url";
import { DealsDetails } from "./DealsDetails";
import { TRANSACTION_DETAILS_ROUTE } from "../../../constants/routes";
import { TRANSACTION_TYPES } from "../../../constants/transaction";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import { useUpdateInstallmentMutation } from "../../../redux/api/leadsApi";
import { selectCurrentagency } from "../../../redux/features/authSlice";
import { useSelector } from "react-redux";

export const DEAL_COLUMN = [
  {
    accessorKey: "dealDate",
    header: "Deal Date",
    size: 20,
    Cell: ({ row }) => {
      const [showDetails, setShowDetails] = useState(false);

      return (
        <>
          <div className="cursor-pointer" onClick={() => setShowDetails(true)}>
            {new Date(row.original.dealDate).toLocaleDateString()}
          </div>
          {showDetails && (
            <DealsDetails data={row.original} setShowDetails={setShowDetails} />
          )}
        </>
      );
    },
  },
  {
    accessorKey: "dealDate",
    header: "Time",
    size: 20,
    Cell: ({ row }) => {
      const [showDetails, setShowDetails] = useState(false);

      const dealDate = row.original.dealDate;
      let timeString;
      if (dealDate) {
        timeString = new Date(dealDate).toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
        });
      } else {
        return "";
      }

      return (
        <>
          <div className="cursor-pointer" onClick={() => setShowDetails(true)}>
            {timeString}
          </div>
          {showDetails && (
            <DealsDetails data={row.original} setShowDetails={setShowDetails} />
          )}
        </>
      );
    },
  },
  {
    accessorKey: "agentName",
    header: "Agent",
    size: 20,
    Cell: ({ row }) => {
      const [showDetails, setShowDetails] = useState(false);

      const agentInfo = row.original?.AgentsInfo;
      const agentNameDisplay =
        agentInfo.length > 1 ? "Multiple" : agentInfo[0].AgentName;

      return (
        <>
          <div className="cursor-pointer" onClick={() => setShowDetails(true)}>
            {agentNameDisplay}
          </div>
          {showDetails && (
            <DealsDetails data={row.original} setShowDetails={setShowDetails} />
          )}
        </>
      );
    },
  },
  {
    accessorKey: "propertyrefnum",
    header: "Ref No",
    size: 20,
    Cell: ({ row }) => {
      const [showDetails, setShowDetails] = useState(false);

      return (
        <>
          <div className="cursor-pointer" onClick={() => setShowDetails(true)}>
            {row.original?.propertyrefnum}
          </div>
          {showDetails && (
            <DealsDetails data={row.original} setShowDetails={setShowDetails} />
          )}
        </>
      );
    },
  },
  {
    accessorKey: "amountTotal",
    header: "Deal Amount",
    size: 20,
    Cell: ({ row }) => {
      const [showDetails, setShowDetails] = useState(false);

      const amountTotal = row.original?.amountTotal ?? 0;
      return (
        <>
          <div className="cursor-pointer" onClick={() => setShowDetails(true)}>
            {amountTotal}
          </div>
          {showDetails && (
            <DealsDetails data={row.original} setShowDetails={setShowDetails} />
          )}
        </>
      );
    },
  },
  {
    accessorKey: "balance",
    header: "Balance",
    size: 20,
    Cell: ({ row }) => {
      const [showDetails, setShowDetails] = useState(false);

      const balance = row.original?.balance?.toFixed(2) ?? 0;
      return (
        <>
          <div className="cursor-pointer" onClick={() => setShowDetails(true)}>
            {balance}
          </div>
          {showDetails && <DealsDetails data={row.original} setShowDetails={setShowDetails} />}
        </>
      );
    },
  },
  {
    accessorKey: "Plans",
    header: "Installments Plans",
    size: 20,
    Cell: ({ row }) => {
      return (
        <>
          <Link
            className="group relative inline-flex items-center overflow-hidden rounded bg-green px-4 py-1 text-white focus:outline-none active:bg-black"
            to={`${TRANSACTION_DETAILS_ROUTE}/${row.original?.dealId}`}
          >
            <span className="absolute -start-full transition-all group-hover:start-4">
              <svg
                className="size-3 rtl:rotate-180"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </span>

            <span className="text-sm font-medium transition-all group-hover:ms-4">
              Details
            </span>
          </Link>
        </>
      );
    },
  },
  {
    accessorKey: "pdf",
    header: "Deal Voucher",
    size: 25,
    Cell: ({ row }) => (
      <Link
        target="_blank"
        className="group relative inline-flex items-center overflow-hidden rounded bg-green px-4 py-1 text-white focus:outline-none active:bg-black"
        to={`https://data.realtorscrm.com/ReceiptVoucher/${row.original?.receipt}`}
      >
        <span className="absolute -start-full transition-all group-hover:start-4">
          <svg
            className="size-3 rtl:rotate-180"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M17 8l4 4m0 0l-4 4m4-4H3"
            />
          </svg>
        </span>

        <span className="text-sm font-medium transition-all group-hover:ms-4">
          Pdf
        </span>
      </Link>
    ),
  },
  {
    accessorKey: "pdf",
    header: "Installment Plan",
    size: 25,
    Cell: ({ row }) => (
      <Link
        target="_blank"
        className="group relative inline-flex items-center overflow-hidden rounded bg-green px-4 py-1 text-white focus:outline-none active:bg-black"
        to={`https://data.realtorscrm.com/Installments/${row.original?.installment}`}
      >
        <span className="absolute -start-full transition-all group-hover:start-4">
          <svg
            className="size-3 rtl:rotate-180"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M17 8l4 4m0 0l-4 4m4-4H3"
            />
          </svg>
        </span>

        <span className="text-sm font-medium transition-all group-hover:ms-4">
          Pdf
        </span>
      </Link>
    ),
  },
  // {
  //   accessorKey: "pdf",
  //   header: "New Transaction",
  //   size: 20,
  //   Cell: ({ row }) => (
  //     <Link
  //       className="group relative inline-flex items-center overflow-hidden rounded bg-green px-4 py-1 text-white focus:outline-none active:bg-black"
  //       to={`add/${row.original?.dealId}/${row.original?.amounttransactionID}`}
  //     >
  //       <span className="absolute -start-full transition-all group-hover:start-4">
  //         <svg
  //           className="size-3 rtl:rotate-180"
  //           xmlns="http://www.w3.org/2000/svg"
  //           fill="none"
  //           viewBox="0 0 24 24"
  //           stroke="currentColor"
  //         >
  //           <path
  //             strokeLinecap="round"
  //             strokeLinejoin="round"
  //             strokeWidth="2"
  //             d="M17 8l4 4m0 0l-4 4m4-4H3"
  //           />
  //         </svg>
  //       </span>

  //       <span className="text-sm font-medium transition-all group-hover:ms-4">
  //         Add
  //       </span>
  //     </Link>
  //   ),
  // },
];

export const TRANSACTION_COLUMN = [
  {
    accessorKey: "amounttransactionID",
    header: "Transaction ID",
    size: 20,
  },
  {
    accessorKey: "amountReceivedDate",
    header: "Date",
    size: 20,
    Cell: ({ row }) => {
      return (
        <div className="">
          {new Date(row.original.amountReceivedDate).toLocaleDateString()}
        </div>
      );
    },
  },
  {
    accessorKey: "amountReceivedDate",
    header: "Time",
    size: 20,
    Cell: ({ row }) => {
      const amountReceivedDate = row.original.amountReceivedDate;
      if (amountReceivedDate) {
        const timeString = new Date(amountReceivedDate).toLocaleTimeString(
          "en-US",
          { hour: "2-digit", minute: "2-digit" }
        );
        return timeString;
      } else {
        return "";
      }
    },
  },
  {
    accessorKey: "amountTotal",
    header: "Total Amount",
    size: 20,
    Cell: ({ row }) => {
      const amountTotal = row.original?.amountTotal ?? 0;
      return (
        <>
          <div>{amountTotal}</div>
        </>
      );
    },
  },
  {
    accessorKey: "amountReceived",
    header: "Installment",
    size: 20,
    Cell: ({ row }) => {
      const amountReceived = row.original?.amountReceived ?? 0;
      return (
        <>
          <div>{amountReceived}</div>
        </>
      );
    },
  },
  // {
  //   accessorKey: "balance",
  //   header: "Remaining",
  //   size: 20,
  //   Cell: ({ row }) => {
  //     const balance = row.original?.balance ?? 0;
  //     return (
  //       <>
  //         <div>{balance}</div>
  //       </>
  //     );
  //   },
  // },
  {
    accessorKey: "payment",
    header: "Payment Type",
    size: 20,
    Cell: ({ row }) => (
      <div>
        {
          TRANSACTION_TYPES.find((item) => item.id === row.original.paymentType)
            ?.name
        }
      </div>
    ),
  },

  {
    accessorKey: "installmentPlanType",
    header: "Installment Type",
    size: 20,
  },
  {
    header: "Paid Installment",
    size: 20,
    Cell: ({ row }) => {
      const isPaid = row.original?.clearedStatus === 1;
      const [switchState, setSwitchState] = useState(isPaid);
      const [open, setOpen] = useState(false);
      const [updateInstallments] = useUpdateInstallmentMutation();
      const [paymentType, setPaymentType] = useState(
        row.original?.paymentType || ""
      ); // Initialize with current payment type or empty
      const currentAgencyId = useSelector(selectCurrentagency);
      const handleSwitchChange = (event) => {
        const isChecked = event.target.checked;
        setSwitchState(isChecked);
        if (isChecked) {
          setOpen(true);
        }
      };

      const handlePaymentTypeChange = (event) => {
        setPaymentType(event.target.value);
      };

      const handleClose = () => {
        setOpen(false);
      };

      const onSubmit = async () => {
        const currentDateTime = new Date()
          .toISOString()
          .slice(0, 19)
          .replace("T", " ");

        const installmentPrice = row.original?.amountReceived;
        const totalAmount = row.original?.balance;
        const dealId = row.original?.dealid;
        const installmentId = row.original?.installmentId;
        const transactionId = row.original?.amounttransactionID;

        const installmentData = {
          agencyId: currentAgencyId,
          amounttransactionID: transactionId,
          amountTotal: totalAmount,
          amountReceived: installmentPrice,
          balance: totalAmount,
          amountReceivedDate: currentDateTime,
          paymentType: paymentType, // Use the selected payment type
          dealId: dealId,
          clearedStatus: 1,
          installmentId: installmentId,
        };

        try {
          const response = await updateInstallments(installmentData);
          if (response) {
            setOpen(false);
            row.original.refetch();
          }
        } catch (error) {
          console.error("Error updating installment:", error);
        }
      };

      return (
        <>
          <Switch checked={switchState} onChange={handleSwitchChange} />
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Mark Installment Plan as Paid</DialogTitle>
            <DialogContent>
              <FormControl sx={{ width: "320px" }}>
                <InputLabel>Payment Type</InputLabel>
                <Select
                  margin="10px"
                  value={paymentType}
                  onChange={handlePaymentTypeChange}
                  displayEmpty
                  label="Payment Type"
                  fullWidth
                >
                  {TRANSACTION_TYPES.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={onSubmit} color="primary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    },
  },
];
