import { MenuItem, TextField, useMediaQuery } from "@mui/material";
import MaterialReactTable from "material-react-table";
import { useRef } from "react";
import dayjs from 'dayjs';
import { CLOSE_DEAL_DONE_TYPE, CLOSE_UNSUCCESSFULL_TYPE } from "../../constants/leads";

export const LeadTable = ({ leads, isLoading, columns, onChangeAction, setSelectedLeads, applyColor }) => {
    const tableRef = useRef(null);
    const isMobileScreen = useMediaQuery("(max-width:768px)");

    const leadActions = [
        { id: 0, label: "Assign to Another Agent" },
    ];

    // Function to determine the row background color based on the lead time
    const getRowBackgroundColor = (leadStatus) => {

        if (!applyColor) return undefined; 

        if (leadStatus === CLOSE_DEAL_DONE_TYPE ) {
            return "#d4edda"; // Green for less than 24 hours
        } else if (leadStatus === CLOSE_UNSUCCESSFULL_TYPE ) {
            return "#f8d7da"; // Orange for 24 to 48 hours
        } else {
            return ""; // Red for more than 48 hours
        }
    };
    return (
        <MaterialReactTable
            state={{ isLoading: isLoading }}
            tableInstanceRef={tableRef}
            enableStickyHeader={!isMobileScreen}
            muiTableContainerProps={{
                sx: { maxHeight: isMobileScreen ? "100%" : "calc(100vh - 300px)" },
            }}
            columns={columns}
            data={leads ?? []}
            enableRowSelection
            enableDensityToggle={false}
            initialState={{ density: "compact" }}
            getRowId={(row) => row.leadid}
            onRowSelect={(row) => {
                setSelectedLeads((prevSelected) => [...prevSelected, row.original]);
            }}
            // Custom row styling based on leadTime and applyColor parameter
            muiTableBodyRowProps={({ row }) => ({
                sx: {
                    backgroundColor: getRowBackgroundColor(row.original.leadStatus),
                },
            })}
            renderTopToolbarCustomActions={({ table }) => {
                const selectedLeads = table.getSelectedRowModel().flatRows.map((item) => item.original);

                return (
                    <TextField
                        select
                        variant="outlined"
                        size="small"
                        sx={{ minWidth: "200px" }}
                        label="Action"
                        value=""
                        onChange={(e) => onChangeAction(e, selectedLeads)}
                    >
                        {leadActions.map((status) => (
                            <MenuItem key={status.id} value={status.id}>
                                {status.label}
                            </MenuItem>
                        ))}
                    </TextField>
                );
            }}
        />
    );
};
