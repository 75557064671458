import { useLazyGetAddedPropertyQuery } from "../redux/api/propertiesApi";

import { useUploadPdfMutation } from "../redux/api/filesApi";
import { PROPERTY_PDF_ID } from "../constants/pdf";

import { useState } from "react";
import { toast } from "react-toastify";
import {PropertyPdf} from '../components/PdfGenerator'
import { pdf } from "@react-pdf/renderer";

export default function useUploadPdf() {
    const [getAddedproperty] = useLazyGetAddedPropertyQuery();
    const [uploadPdf] = useUploadPdfMutation();
    const [isLoading, setIsLoading] = useState(false)

    const upload = async (propertyId) => {
        setIsLoading(true)
        try {
            const { data } = await getAddedproperty(propertyId);

            if (!data) {
                return;
            }

            const blob = await pdf(PropertyPdf({ property: data.Properties[0] })).toBlob();

            const formData = new FormData();

            formData.append("", blob);

            await uploadPdf({ formData, propertyId: propertyId, pdfType: PROPERTY_PDF_ID });
        } catch (err) {
            toast.error("Something went wrong while uploading property pdf, please try again later");
        } finally {
            setIsLoading(false)
        }
    };

    return { upload, isLoading };
}
