import React, { useEffect, useState } from "react";
import Select from "../FormElements/Select";
import TextField from "../FormElements/TextField";
import {
  PROPERTY_FURNISHINGS,
  PROPERTY_OFF_PLANS,
  PROPERTY_PURPOSES,
  PROPERTY_TYPES,
} from "../../constants/property";
import { propertyRules } from "./propertyValidation";
import { Typography, TextField as TextFieldMUI, MenuItem, Box } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import useAreaUnits from "../../hooks/useAreaUnits.js";
import { AREA_UNITS, convertArea } from "../../constants/area";
import { selectCurrentagency } from "../../redux/features/authSlice.js";
import RadioGroup from "../FormElements/RadioGroup.jsx";
import AutoComplete from "../FormElements/AutoComplete.jsx";
import AgentSelect from "../FormElements/AgentSelect.jsx";

const AreaPicker = () => {
  const areaUnits = useAreaUnits();
  const { setValue, watch } = useFormContext();
  const [activeAreaUnit, setActiveAreaUnit] = useState(areaUnits[0].id); //marla || sqft

  const name = AREA_UNITS.find((unit) => unit.id === activeAreaUnit)?.dbName;

  const areaSize = watch(name);

  useEffect(() => {
    let convertedValues = convertArea(areaSize, activeAreaUnit);
    setValue("propertysizemarla", convertedValues.marla);
    setValue("propertysizesqft", convertedValues.sqft);
    setValue("propertysizekanal", convertedValues.kanal);
    setValue("propertysizeSqmt", convertedValues.sqm);
    setValue("propertysizeSqyd", convertedValues.sqyd);
  }, [activeAreaUnit, areaSize]);

  return (
    <>
      <TextFieldMUI
        size="small"
        select
        label="Area unit"
        value={activeAreaUnit}
        onChange={(e) => setActiveAreaUnit(e.target.value)}
      >
        {areaUnits.map((unit) => (
          <MenuItem key={unit.id} value={unit.id}>
            {unit.label}
          </MenuItem>
        ))}
      </TextFieldMUI>

      <TextField
        name={name}
        type="number"
        label="Area Size"
        rules={{
          required: "Field Required",
          min: { value: 1, message: "Enter Valid Area Size" },
        }}
      />
    </>
  );
};

export default function PropertyAttributesPicker({ agents, isFetching, setSelectedAgentId }) {
  const currentAgency = useSelector(selectCurrentagency);

  const { watch, setValue } = useFormContext();

  const propertypurposeid = watch("propertypurposeid");
  const propertyrefnum = watch("propertyrefnum");

  useEffect(() => {
    if (currentAgency && propertypurposeid) {
      const agencyRefTag = currentAgency.agcRefTag.trim();
      const refName = PROPERTY_PURPOSES.find(item => item.id === propertypurposeid)?.refName;
      const parts = propertyrefnum?.split("-");
      const thirdPart = parts.length > 2 ? parts[2] : '';

      const refNo = `${agencyRefTag}-${refName}-${thirdPart}`;

      setValue("propertyrefnum", refNo);
    }
  }, [propertypurposeid, currentAgency.agcRefTag, propertyrefnum, setValue]);


  return (
    <>
      <Typography variant="h4" mt={1} sx={{ gridColumn: "1/-1" }}>
        Property RefNo : {propertyrefnum}
      </Typography>

      {/* <AutoComplete
        name="agentid"
        isLoading={isFetching}
        options={agents && agents.map(agent => ({ agentid: agent.agentid, agentname: agent.agentname }))}
        label="Select Agent"
        optionId="agentid"
        optionLabel="agentname"
        rules={propertyRules.agentid}
      /> */}

      <AgentSelect
        name="agentid"
        rules={propertyRules.agentid}
        defaultValue=""
        isFetching={isFetching}
        agents={agents}
        setSelectedAgentId={setSelectedAgentId}
      />

      <Select
        name="propertypurposeid"
        label="Property Purpose"
        options={PROPERTY_PURPOSES}
        rules={propertyRules.propertypurposeid}
      />

      <Select
        name="propertytypeid"
        label="Property Type"
        options={PROPERTY_TYPES}
        rules={propertyRules.propertytypeid}
      />

      <TextField name="bedrooms" label="Bedrooms" type="number" rules={propertyRules.bedrooms} />

      <TextField name="bathrooms" label="Bathrooms" type="number" rules={propertyRules.bathrooms} />

      <Select name="furnished" label="Furnishing" required options={PROPERTY_FURNISHINGS} />

      <AreaPicker />
      <Box sx={{ gridColumn: "1/-1" }}>
        <RadioGroup name="offPlan" label="Off Plan" options={PROPERTY_OFF_PLANS} />
      </Box>
    </>
  );
}
