import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { Button, CircularProgress, styled } from "@mui/material";
import { Link } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useState } from "react";
import TextField from "../../components/FormElements/TextField";
import { useForgetAgencyPasswordMutation } from "../../redux/api/authApi";
import { toast } from "react-toastify";

export default function ForgetPassword() {
  const [error, setError] = useState("");
  const methods = useForm({
    defaultValues: { email: "" },
  });
  const { handleSubmit } = methods;

  const [ForgetAgencyPassword, { isLoading }] = useForgetAgencyPasswordMutation();

  const onSubmit = (data) => {
    setError("");
    ForgetAgencyPassword(data)
      .unwrap()
      .then((res) => {
        console.log(res);

        if (res) {
          toast.success(`Email has been send to ${data.email}`);
        } else {
          setError(res?.Message || "Invalid email");
        }
      })
      .catch((err) => {
        console.log(err);
        const errorMessage = err?.data?.Message || "Unknown Error Occurred";
        setError(errorMessage);
      });
  };

  return (
    <StyledContainer>
      <Avatar sx={{ m: 1, bgcolor: "#56C662" }}>
        <LockOutlinedIcon />
      </Avatar>

      <Typography variant="h2">Agency Login</Typography>

      <Typography variant="h5" color="error">
        {error}
      </Typography>
      <FormProvider {...methods}>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <TextField
            name="email"
            rules={{ required: "Email Address Required" }}
            label="Email Address"
            size="large"
          />

          <Button
            type="submit"
            variant="contained"
            size="large"
            disabled={isLoading}
            endIcon={isLoading ? <CircularProgress size={20} /> : undefined}
            sx={{ color: "white" }}
          >
            Submit
          </Button>
          <Typography
            component={Link}
            color="secondary"
            to="/signup"
            variant="body2"
          >
            Don't have an account? Sign Up
          </Typography>
        </StyledForm>
      </FormProvider>
    </StyledContainer>
  );
}

const StyledContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  height: "70vh",
  padding: "16px",
  marginTop: "40px",
}));

const StyledForm = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "400px",
  justifyContent: "center",
  gap: "24px",
  marginTop: "24px",
}));
