import { PAKISTAN_ID, UAE_ID } from "./countries";

export const MARLA_UNIT_ID = 1;
export const SQUARE_FEET_UNIT_ID = 2;
export const KANAL_UNIT_ID = 3;
export const SQUARE_METER_UNIT_ID = 4;
export const SQUARE_YARD_UNIT_ID = 5;

export const AREA_UNITS = [
  { id: MARLA_UNIT_ID, label: "Marla", dbName: 'propertysizemarla' },
  { id: SQUARE_FEET_UNIT_ID, label: "Square Feet", dbName: 'propertysizesqft' },
  { id: SQUARE_METER_UNIT_ID, label: "Square Meter", dbName: 'propertysizeSqmt' },
  { id: KANAL_UNIT_ID, label: "Kanal", dbName: 'propertysizekanal' },
  { id: SQUARE_YARD_UNIT_ID, label: "Square Yard", dbName: 'propertysizeSqyd' },
];


export const PAKISTAN_AREA_UNITS = [
  { id: MARLA_UNIT_ID, label: "Marla" },
  { id: SQUARE_FEET_UNIT_ID, label: "Square Feet" },
  { id: SQUARE_METER_UNIT_ID, label: "Square Meter" },
  { id: KANAL_UNIT_ID, label: "Kanal" },
  { id: SQUARE_YARD_UNIT_ID, label: "Square Yard" },
];

export const UAE_AREA_UNITS = [
  { id: SQUARE_FEET_UNIT_ID, label: "Square Feet" },
  { id: SQUARE_METER_UNIT_ID, label: "Square Meter" },
  { id: SQUARE_YARD_UNIT_ID, label: "Square Yard" },
];

export const AREA_UNITS_BY_COUNTRY = {
  [PAKISTAN_ID]: PAKISTAN_AREA_UNITS,
  [UAE_ID]: UAE_AREA_UNITS,
};

// Define conversion factors
const marlaToSqft = 272.25; // 1 Marla = 272.25 Sqft
const kanalToSqft = 5445; // 1 Kanal = 5445 Sqft
const sqmToSqft = 10.7639; // 1 Sqm = 10.7639 Sqft

// Function to convert from Marla to other units
export function marlaConverter(marla) {
  const sqft = marla * marlaToSqft;
  const kanal = sqft / kanalToSqft;
  const sqm = sqft / sqmToSqft;
  const sqyd = sqft / 9;
  return { sqft, kanal, sqm, marla, sqyd };
}

// Function to convert from Kanal to other units
export function kanalConverter(kanal) {
  const sqft = kanal * kanalToSqft;
  const marla = sqft / marlaToSqft;
  const sqm = sqft / sqmToSqft;
  const sqyd = sqft / 9;
  return { sqft, marla, sqm, kanal, sqyd };
}

// Function to convert from Sqft to other units
export function sqftConverter(sqft) {
  const marla = sqft / marlaToSqft;
  const kanal = sqft / kanalToSqft;
  const sqm = sqft / sqmToSqft;
  const sqyd = sqft / 9;
  return { marla, kanal, sqm, sqft, sqyd };
}

// Function to convert from Sqm to other units
export function sqmConverter(sqm) {
  const sqft = sqm * sqmToSqft;
  const marla = sqft / marlaToSqft;
  const kanal = sqft / kanalToSqft;
  const sqyd = sqft / 9;
  return { sqft, marla, kanal, sqm, sqyd };
}

export function sqydConverter(sqyd) {
  const sqft = sqyd * 9;
  const marla = sqft / marlaToSqft;
  const kanal = sqft / kanalToSqft;
  const sqm = sqft / sqmToSqft;
  return { sqft, marla, kanal, sqm, sqyd };
}

export const convertArea = (areaSize, areaType) => {
  if (areaType === MARLA_UNIT_ID) {
    return marlaConverter(areaSize);
  }
  if (areaType === SQUARE_FEET_UNIT_ID) {
    return sqftConverter(areaSize);
  }

  if (areaType === SQUARE_METER_UNIT_ID) {
    return sqmConverter(areaSize);
  }

  if (areaType === KANAL_UNIT_ID) {
    return kanalConverter(areaSize);
  }

  if (areaType === SQUARE_YARD_UNIT_ID) {
    return sqydConverter(areaSize);
  }
};
