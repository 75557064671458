import React, { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentagency } from "../../redux/features/authSlice";
import ContactTable from "./ContactTable";
import { useGetContactsQuery } from "../../redux/api/contactsApi";
import { CONTACT_TYPES } from "../../constants/contacts";
import { Button, styled } from "@mui/material";

export default function Contacts() {
  const currentAgency = useSelector(selectCurrentagency);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedContactType, setSelectedContactType] = useState(searchParams.get("lt") || "all");

  const { data, isLoading } = useGetContactsQuery(currentAgency.agencyid);

  // Normalizing data to a unified schema
  const normalizeData = (data) => {
    const allData = [];
    if (data.landlord) {
      allData.push(...data.landlord.map(item => ({
        ownfirstname: item.ownfirstname,
        ownlastname: item.ownlastname,
        type: 'Landlord',
        ownemail: item.ownemail,
        mobileno: item.mobileno
      })));
    }
    if (data.webBasedClient) {
      allData.push(...data.webBasedClient.map(item => ({
        ownfirstname: item.firstname,
        ownlastname: item.lastname,
        type: 'Web Based Client',
        ownemail: item.useremail,
        mobileno: item.userphone
      })));
    }
    if (data.directAgentClient) {
      allData.push(...data.directAgentClient.map(item => ({
        ownfirstname: item.firstname,
        ownlastname: item.lastname,
        type: 'Direct Agent Client',
        ownemail: item.useremail,
        mobileno: item.userphone
      })));
    }
    return allData;
  };
  
  const filteredData = useMemo(() => {
    if (!data) return [];
    switch (selectedContactType) {
      case "1":
        return normalizeData({ landlord: data.landlord });
      case "2":
        return normalizeData({ webBasedClient: data.webBasedClient });
      case "3":
        return normalizeData({ directAgentClient: data.directAgentClient });
      default:
        return normalizeData(data);
    }
  }, [data, selectedContactType]);

  const onChangeLeadType = (type) => {
    setSelectedContactType(type);
    setSearchParams({ lt: type });
  };

  return (
    <div>
      <StyledButton
        size="small"
        variant={selectedContactType === "all" ? "contained" : "outlined"}
        sx={{
          color: selectedContactType === "all" ? "white" : "",
          marginBottom: "10px"
        }}
        onClick={() => onChangeLeadType("all")}
      >
        All Contacts
      </StyledButton>
      {CONTACT_TYPES.map((button) => (
        <StyledButton
          key={button.id}
          size="small"
          sx={{
            color: button.id.toString() === selectedContactType ? "white" : "#56C662",
            bgcolor: button.id.toString() === selectedContactType ? "#56C662" : "transparent",
            marginBottom: "10px"
          }}
          variant={button.id.toString() === selectedContactType ? "contained" : "outlined"}
          onClick={() => onChangeLeadType(button.id.toString())}
        >
          {button.name}
        </StyledButton>
      ))}

      <ContactTable
        isFetching={isLoading}
        data={filteredData}
      />
    </div>
  );
}

const StyledButton = styled(Button)(({ theme }) => ({
  marginRight: "8px",
}));
