import { useState } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { usePostLeadStatusMutation } from "../../../../redux/api/leadsApi";
import { toast } from "react-toastify";
import {
  DatePicker,
  LocalizationProvider,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { combineDateAndTime } from "../../../../constants/customFunction";
import DateAndTime from "../../../../components/DateAndTime";
import { useLeadsData } from "../../../../hooks/useContext/leadsData";

const StatusDescription = ({ setShowVerify, selectedLeadStatus, leadData }) => {
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [note, setNote] = useState("");
  const [leadStatusData, { isLoading }] = usePostLeadStatusMutation();

  const handleClose = () => {
    setShowVerify(false);
  };

  // convert propertyid to this form "[436]"
  const propertyid = leadData?.propertyid;
  const propertyID = JSON.stringify([propertyid]);
  const dateAndTime = combineDateAndTime(startDate, startTime);
  const { refetch } = useLeadsData();

  const submitHandler = () => {
    const LeadID = leadData.leadid;
    if (!dateAndTime) {
      toast.error("Date and Time is required");
    } else {
      leadStatusData({
        Leadid: LeadID,
        leadStatus: selectedLeadStatus,
        propertyIds: propertyID,
        leadDate: dateAndTime,
        note: note || null,
      }).then(() => {
        refetch();
        setShowVerify(false);
      });
    }
  };

  return (
    <>
      <div className="relative">
        <div className=" flex items-center flex-col p-10">
          <div className="">
            <div
              className="absolute top-10 left-0 sm:left-[-30px] p-2 cursor-pointer "
              onClick={handleClose}
            >
              <IoMdArrowBack size={20} />
            </div>
            <h1>Write Note</h1>
          </div>
          <div className="w-full mt-5">
            <div className="flex flex-col items-center w-full">
              <div className="flex items-center justify-evenly gap-1	">
                <DateAndTime
                  startDate={startDate}
                  setStartDate={setStartDate}
                  startTime={startTime}
                  setStartTime={setStartTime}
                />
              </div>
              <div className="sm:w-[450px]">
                <textarea
                  className="rounded w-full border-[1px] mt-[10px] lg:h-auto  p-3 resize-none outline-none shadow-sm "
                  rows={7}
                  cols={40}
                  onChange={(e) => setNote(e.target.value)}
                  placeholder="Note..."
                ></textarea>
              </div>
              <div className="flex justify-center rounded-xl  cursor-pointer bg-green w-20 p-2.5 ">
                <button
                  className="bg-inherit text-white border-none cursor-pointer"
                  onClick={submitHandler}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StatusDescription;
