export const PROPERTY_IMAGES_URL = "https://data.realtorscrm.com/Images/";

export const AGENT_IMAGES_URL = "https://data.realtorscrm.com/AgentImages/";

export const AGENCY_LOGOS_URL = "https://data.realtorscrm.com/AgencyLogos/";

export const AGENCY_WATERMARKS_URL = "https://data.realtorscrm.com/Agencywatermark/";

export const AGENCY_LICENSE_IMAGES_URL = "https://data.realtorscrm.com/AgencyLicensePics/";

export const PROPERTY_PDFS_URL = "https://data.realtorscrm.com/pdfs/";