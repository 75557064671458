import { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { PROPERTY_IMAGES_URL } from "../constants/url";

export const CardSlider = ({ selectedProperties, handlePropertySelect, suggestedProperties }) => {

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 7
        },
        desktop: {
            breakpoint: { max: 1700, min: 1024 },
            items: 4
        },
        tabletLg: {
            breakpoint: { max: 1024, min: 700 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 700, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <>
            <Carousel responsive={responsive}>
                {suggestedProperties && suggestedProperties.map((item, index) => (
                    <div
                        className="w-auto m-2.5 h-[240px] shadow-md p-2.5 rounded-2xl cursor-pointer flex-nowrap"
                        key={index}
                        onClick={() => handlePropertySelect(item.propertyid)}
                        style={{ backgroundColor: selectedProperties.includes(item.propertyid) ? 'rgb(244, 244, 244)' : 'transparent' }}>
                        <input
                            type="checkbox"
                            checked={selectedProperties.includes(item.propertyid)}
                            onChange={() => handlePropertySelect(item.propertyid)}
                            style={{ accentColor: '#56C662', float: "right", cursor: "pointer", margin: "2px" }}
                        />
                        <label className="cursor-pointer">
                            <span className="custom-checkbox" style={{ backgroundColor: selectedProperties.includes(item.propertyid) ? "green" : "transparent" }}></span>
                            <img
                                className="w-full h-[100px] rounded-lg object-cover"
                                src={item.propertyimages && item.propertyimages[0] ? PROPERTY_IMAGES_URL + item.propertyimages[0] : ''}
                                alt={item.propertytitle}
                            />
                            <div className="m-auto overflow-hidden capitalize">
                                <h1 className="text-base h-7 overflow-hidden mt-2 font-bold">{item.propertytitle}</h1>
                                <p className='text-xs h-8 text-left text-gray-500 overflow-hidden'>{item.propertydescription}</p>
                                <div className="flex w-full justify-between text-gray-700 font-bold mt-2 text-sm">
                                    {
                                        item?.bedrooms === -1 ? (
                                            <p>{item?.bedrooms === -1 ? "Studio" : item?.bedrooms}</p>
                                        ) : (
                                            <p><span className="mr-2 ">Beds </span><span>{item?.bedrooms}</span></p>
                                        )
                                    }
                                    <p><span className="mr-2 ">Bathroom </span><span>{item.bathrooms}</span></p>
                                </div>
                            </div>
                        </label>
                    </div>
                ))}
            </Carousel>
        </>
    );
}
