import { apiSlice } from ".";


const sublocationApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSubLocationsByCityId: builder.query({
            query: (cityId) => `/SubLocationTables/GetSublocByCouProvCiIds?counid=&provid=&cityid=${cityId}`,
        })
    })

});

export const {
    useLazyGetSubLocationsByCityIdQuery
} = sublocationApi;
