import { useState } from "react";
import { CardSlider } from "../../../../components/ViewingSlider";
import {
  useGetFromAppointmentPropertiesIdQuery,
  useGetViewedPropertiesQuery,
  usePostLeadStatusMutation,
} from "../../../../redux/api/leadsApi";
import { useNavigate } from "react-router-dom";
import {
  combineDateAndTime,
  removeSquareBrackets,
} from "../../../../constants/customFunction";
import DateAndTime from "../../../../components/DateAndTime";
import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import { useLeadsData } from "../../../../hooks/useContext/leadsData";

const ViewingDone = () => {
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [note, setNote] = useState("");
  const currentPath = window.location.pathname;
  const segments = currentPath.split("/");
  const leadId = segments[segments.length - 1];
  const agtid = segments[3];
  const userId = segments[5];
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const { data } = useGetFromAppointmentPropertiesIdQuery({
    token,
    leadId,
    agtid,
  });
  const lastIndex = data?.length - 1;
  const lastObject = data?.[lastIndex];
  const propertyIdsString = lastObject?.propertyIds || "";

  const formattedPropertyIds = removeSquareBrackets(propertyIdsString);
  const { data: viewedProperties, isLoading: isLoadingViewedProperties } =
    useGetViewedPropertiesQuery(formattedPropertyIds);

  const handlePropertySelect = (propertyId) => {
    if (!selectedProperties.includes(propertyId)) {
      setSelectedProperties([...selectedProperties, propertyId]);
    } else {
      setSelectedProperties(
        selectedProperties.filter((id) => id !== propertyId)
      );
    }
  };

  const [leadStatusData, { isLoading }] = usePostLeadStatusMutation();

  const propertyIdsJSON = JSON.stringify(selectedProperties);
  const dateAndTime = combineDateAndTime(startDate, startTime);
  const { refetch } = useLeadsData();

  const handleSubmit = () => {
    if (!dateAndTime) {
      toast.error("Date and Time is required");
    } else {
      leadStatusData({
        Leadid: leadId,
        leadStatus: 11,
        propertyIds: propertyIdsJSON,
        leadDate: dateAndTime,
        note: note || null,
      }).then(() => {
        setNote("");
        setStartDate(null);
        refetch();
        navigate(-1);
      });
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Viewing Done
      </Typography>
      <Box display="flex" gap={2} mb={2}>
        <DateAndTime
          startDate={startDate}
          setStartDate={setStartDate}
          startTime={startTime}
          setStartTime={setStartTime}
        />
      </Box>
      <Box mt={3} width="100%" maxWidth="md">
        <TextField
          variant="outlined"
          fullWidth
          multiline
          rows={7}
          value={note}
          onChange={(e) => setNote(e.target.value)}
          placeholder="Description"
          InputProps={{
            style: { padding: "10px" },
          }}
        />
      </Box>
      <Box mt={5} p={2} borderRadius={2} boxShadow={3}>
        <Typography variant="h6" gutterBottom>
          Select Viewed Properties
        </Typography>
        <Box>
          {isLoadingViewedProperties ? (
            <CircularProgress />
          ) : viewedProperties ? (
            <Box p={2}>
              <CardSlider
                selectedProperties={selectedProperties}
                handlePropertySelect={handlePropertySelect}
                suggestedProperties={viewedProperties?.Properties}
              />
            </Box>
          ) : (
            <Typography>No viewed properties available.</Typography>
          )}
        </Box>
      </Box>
      <Button
          sx={{ mb: 4 }}
          variant="contained"
          color="success"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          Submit
        </Button>
    </Container>
  );
};

export default ViewingDone;
