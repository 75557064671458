import React, { memo } from "react";
import PropertyForm from "../../components/PropertyForm";
import { Box } from "@mui/material";

function AddProperty() {
  return (
    <Box sx={{ maxWidth: "1600px", mx: "auto" }}>
      <PropertyForm />
    </Box>
  );
}

export default memo(AddProperty);
