import { toast } from "react-toastify";
import { apiSlice } from ".";

const agentsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addAgent: builder.mutation({
      query: (data) => ({
        url: "/Agent/PostAgentTable",
        method: "POST",
        body: data,
      }),

      async onQueryStarted(data, { queryFulfilled, dispatch }) {
        toast.promise(queryFulfilled, {
          pending: "Adding Agent",
          success: "Agent Added",
          error: "Error Adding Agent",
        });

        const { data: agent } = await queryFulfilled;

        dispatch(
          agentsApi.util.updateQueryData("getAgents", data.agencyid, (agents) => {
            agents.push(agent);
          })
        );
      },
    }),

    editAgent: builder.mutation({
      query: (data) => ({
        url: `/Agent/PutAgentTable/${data.agentid}`,
        method: "POST",
        body: data,
      }),

      async onQueryStarted(data, { queryFulfilled, dispatch }) {
        toast.promise(queryFulfilled, {
          pending: "Updating Agent",
          success: "Agent Updated",
          error: "Error Updating Agent",
        });

        const editResult = dispatch(
          agentsApi.util.updateQueryData("getAgents", data.agencyid, (agents) => {
            let index = agents.findIndex((agent) => agent.agentid === data.agentid);
            agents[index] = data;
          })
        );

        queryFulfilled.catch(() => {
          editResult.undo();
        });
      },
    }),

    addAgentLanguages: builder.mutation({
      query: (data) => ({
        url: `/languagelists/Postlanguagelist`,
        method: "POST",
        body: data,
      }),
      // invalidatesTags: ["Agents"],
    }),

    deleteAgent: builder.mutation({
      query: ({ agentId, agencyId }) => ({
        url: `/Agent/DeleteAgentTable/${agentId}`,
        method: "POST",
      }),

      async onQueryStarted({ agentId, agencyId }, { dispatch, queryFulfilled }) {
        try {
          dispatch(
            agentsApi.util.updateQueryData("getAgents", agencyId, (agents) => {
              return agents.filter((item) => item.agentid !== agentId);
            })
          );
          await queryFulfilled;
        } catch (err) {
          dispatch(agentsApi.util.invalidateTags(["Agents"]));
        }
      },
    }),

    getAgents: builder.query({
      query: (agencyId) => `/Agent/GetAgentbyAgencyID/${agencyId}`,
      providesTags: ["Agents"],
    }),

    getAgentById: builder.query({
      query: (agentId) => `/Agent/GetAgentAndLangByAgentID?agtid=${agentId}`,
    }),

    changeAgentStatus: builder.mutation({
      query: ({ agentId, status, agencyId }) => ({
        url: `/Agent/UpdateAgentStatus/${agentId}?stat=${status}`,
        method: "POST",
      }),

      onQueryStarted({ agentId, status, agencyId }, { dispatch, queryFulfilled }) {
        const editResult = dispatch(
          agentsApi.util.updateQueryData("getAgents", agencyId, (agents) => {
            return agents.map((item) =>
              item.agentid === agentId ? { ...item, status: status } : item
            );
          })
        );

        queryFulfilled.catch(() => {
          editResult.undo();
        });
      },
    }),
  }),
});

export const {
  useAddAgentMutation,
  useGetAgentsQuery,
  useChangeAgentStatusMutation,
  useAddAgentLanguagesMutation,
  useDeleteAgentMutation,
  useGetAgentByIdQuery,
  useEditAgentMutation,
} = agentsApi;
