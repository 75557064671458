import React, { useState, useCallback, useMemo } from "react";
import { RxCross2 } from "react-icons/rx";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { TextField, Button, CircularProgress } from "@mui/material";
import MyLocationIcon from '@mui/icons-material/MyLocation';

const libraries = ["places"];
const apiKey = "AIzaSyDS_pyEKeQM9PGoNr73T2bDPUyZ7lc6Kqk";
const customMarkerIcon = "data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24'%3E%3Cpath d='M12 2C8.13 2 5 5.13 5 9c0 2.36.94 4.55 2.46 6.1L12 22l4.54-6.9C18.06 13.55 19 11.36 19 9c0-3.87-3.13-7-7-7zm0 10.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 7.5 12 7.5s2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z'/%3E%3C/svg%3E";

const MapModel = ({ toggleMap, setToggleMap, setGoogleMapsLink }) => {
  const [searchInput, setSearchInput] = useState("");
  const [map, setMap] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 28.2037723, lng: 56.6075263 });
  const [markerPosition, setMarkerPosition] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);

  const loaderOptions = useMemo(() => ({
    googleMapsApiKey: apiKey,
    libraries: libraries,
  }), []);

  const { isLoaded, loadError } = useJsApiLoader(loaderOptions);

  const containerStyle = useMemo(
    () => ({
      width: "100%",
      height: "100%",
    }),
    []
  );

  const handleSearch = useCallback(() => {
    if (!isLoaded || !window.google) return;

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: searchInput }, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK && results && results.length > 0) {
        const location = results[0].geometry.location;
        const newPosition = { lat: location.lat(), lng: location.lng() };
        setMapCenter(newPosition);
        setMarkerPosition(newPosition);

        if (map) {
          map.panTo(newPosition);
          map.setZoom(11);
        }
      } else {
        alert(`Search failed: ${status}. Please try a different search term.`);
      }
    });
  }, [searchInput, map, isLoaded]);

  const handleCurrentLocation = useCallback(() => {
    if (!navigator.geolocation) {
      alert("Geolocation is not supported by your browser.");
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const newPosition = { lat: latitude, lng: longitude };
        setCurrentLocation(newPosition);
        setMapCenter(newPosition);
        setMarkerPosition(newPosition);

        if (map) {
          map.panTo(newPosition);
          map.setZoom(14);
        }

        setGoogleMapsLink(
          `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
        );
      },
      (error) => {
        console.error("Error fetching current location: ", error);
        alert("Unable to fetch current location.");
      },
      {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0,
      }
    );
  }, [map, setGoogleMapsLink]);

  const onLoad = useCallback((mapInstance) => {
    setMap(mapInstance);
    handleCurrentLocation();
  }, [handleCurrentLocation]);

  const handleMapClick = useCallback((event) => {
    if (!isLoaded || !window.google) return;

    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    const newPosition = { lat, lng };
    setMarkerPosition(newPosition);

    const geocoder = new window.google.maps.Geocoder();
    const latLng = { lat, lng };

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
        setGoogleMapsLink(
          `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
        );
      } else {
        console.log("Geocoder failed due to: " + status);
      }
    });
  }, [isLoaded]);

  const handleMarkerClick = useCallback(() => {
    alert("Marker clicked!");
    // Perform any other actions on marker click
  }, []);

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      {toggleMap && (
        <div className="fixed z-[99999] inset-0 bg-gray-200 bg-opacity-80 flex items-center justify-center p-4">
          <div className="relative w-full h-full md:w-[85%] md:h-[95%] bg-white px-2 py-4 rounded-md overflow-hidden">
            <div className="absolute z-[1001] top-[25px] right-[18px] cursor-pointer w-10 h-10 flex justify-center items-center bg-white" onClick={() => setToggleMap(false)}>
              <RxCross2 className="text-lg sm:text-xl" />
            </div>

            <div className="absolute top-2 sm:gap-2 left-0 w-full p-4 z-[1000] flex items-center sm:justify-center">
              <TextField
                placeholder="Search Location"
                variant="outlined"
                size="small"
                sx={{
                  background: "white",
                  borderRadius: "5px",
                  width: "100%",
                  maxWidth: {
                    xs: "55%",
                    sm: "400px",
                    md: "500px",
                  },
                }}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />

              <Button
                variant="contained"
                onClick={handleSearch}
                style={{
                  padding: "7px 15px",
                  color: "white",
                  marginLeft: "10px",
                }}
              >
                Search
              </Button>
            </div>

            <div className="absolute z-[1003] bottom-52 right-6">
              <Button
                variant="contained"
                onClick={handleCurrentLocation}
                style={{
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  minWidth: "unset",
                  padding: 0,
                  backgroundColor: "#56C662", // Google Maps blue color
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                <MyLocationIcon style={{ color: "white", fontSize: "21px" }} />
              </Button>
            </div>

            <GoogleMap
              mapContainerStyle={containerStyle}
              center={mapCenter}
              zoom={14}
              onLoad={onLoad}
              onClick={handleMapClick}
              options={{
                mapTypeControl: true,
                mapTypeControlOptions: {
                  style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                  position: window.google.maps.ControlPosition.BOTTOM_CENTER,
                  mapTypeIds: ["roadmap", "satellite"],
                },
              }}
            >
              {markerPosition && (
                <Marker
                  position={markerPosition}
                  icon={customMarkerIcon}
                  animation={window.google.maps.Animation.BOUNCE}
                  onClick={handleMarkerClick} // Handle marker click
                />
              )}
            </GoogleMap>
          </div>
        </div>
      )}
    </>
  );
};

export default MapModel;
