export const LANGUAGES = [
  {
    id: 4,
    label: "Arabic",
  },
  {
    id: 3,
    label: "English",
  },
  {
    id: 2,
    label: "Urdu",
  },
  {
    id: 1,
    label: "Pashto",
  },
  {
    id: 9,
    label: "Hindi",
  },
  {
    id: 10,
    label: "Telugu",
  },
  {
    id: 11,
    label: "Turkish",
  },
  {
    id: 12,
    label: "French",
  },
  {
    id: 13,
    label: "Punjabi",
  },
  {
    id: 14,
    label: "Tagalog",
  },
  {
    id: 15,
    label: "Russian",
  },
  {
    id: 16,
    label: "Ukrainian",
  },
  {
    id: 17,
    label: "Tamil",
  },
  {
    id: 18,
    label: "Gujarati",
  },
  {
    id: 19,
    label: "Spanish",
  },
  {
    id: 20,
    label: "German",
  },
  {
    id: 21,
    label: "Danish",
  },
  {
    id: 22,
    label: "Malayalam",
  },
  {
    id: 23,
    label: "Italian",
  },
  {
    id: 24,
    label: "Persian/Farsi",
  },
  {
    id: 25,
    label: "Dutch",
  },
  {
    id: 26,
    label: "Japanese",
  },
  {
    id: 27,
    label: "Serbian",
  },
  {
    id: 28,
    label: "Swedish",
  },
  {
    id: 29,
    label: "Armenian",
  },
  {
    id: 30,
    label: "Croatian",
  },
  {
    id: 31,
    label: "Afrikaans",
  },
  {
    id: 32,
    label: "Albanian",
  },
  {
    id: 33,
    label: "Amharic",
  },
  {
    id: 34,
    label: "Azerbaijani",
  },
  {
    id: 35,
    label: "Baluchi",
  },
  {
    id: 36,
    label: "Bengali",
  },
  {
    id: 37,
    label: "Belarusian",
  },
  {
    id: 38,
    label: "Finnish",
  },
  {
    id: 39,
    label: "Greek",
  },
  {
    id: 40,
    label: "Kazakh",
  },
  {
    id: 41,
    label: "Korean",
  },
  {
    id: 42,
    label: "Malay",
  },
  {
    id: 43,
    label: "Mandarin",
  },
  {
    id: 44,
    label: "Polish",
  },
  {
    id: 45,
    label: "Romanian",
  },
  {
    id: 46,
    label: "Somali",
  },
  {
    id: 47,
    label: "Sudanese",
  },
  {
    id: 48,
    label: "Uzbek",
  },
];
