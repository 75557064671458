import React, { memo, useEffect, useState } from "react";
import {
  Box,
  FormHelperText,
  IconButton,
  styled,
  TextField as TextFieldMUI,
  Typography,
} from "@mui/material";
import { useController } from "react-hook-form";
import Dropdown from "../FormElements/Dropdown";
import { Close } from "@mui/icons-material";
import {
  useGetAllAmenitiesQuery,
  useLazyGetPropertyAmenitiesQuery,
} from "../../redux/api/amenitiesApi";

function AmenitiesPicker({ editingAmenities }) {
  const { data: amenities = [], isLoading, isSuccess } = useGetAllAmenitiesQuery();
  const [
    getPropertyAmenities,
    { data: amenitiesByPropertyId, isSuccess: isSuccessEditingAmenities },
  ] = useLazyGetPropertyAmenitiesQuery();

  const [search, setSearch] = useState("");

  const {
    field,
    fieldState: { error },
  } = useController({
    name: "amenitiesList",
    defaultValue: [],
    rules: {
      validate: (value) => {
        if (!value || value.length === 0) return "Please Select Amenities";
      },
    },
  });

  const searchRegex = new RegExp(search, "i");

  const removeAmenity = (id) => {
    return field.onChange(field.value.filter((item) => item !== id));
  };

  const addAmenity = (id) => {
    field.onChange([...field.value, id]);
  };

  useEffect(() => {
    if (isSuccess && editingAmenities) {
      getPropertyAmenities(editingAmenities, false);
    }
  }, [editingAmenities, isSuccess]);

  useEffect(() => {
    if (amenitiesByPropertyId) {
      field.onChange(amenitiesByPropertyId.map((item) => item.amenitiesid));
    }
  }, [amenitiesByPropertyId]);

  return (
    <>
      <Dropdown
        label={!!error ? error.message : "Pick Amenities" + ` (${field.value?.length} Selected)`}
        color={!!error ? "error" : "primary"}
        disabled={isLoading}
      >
        <Box sx={{ p: 2, width: { xs: "300px", sm: "500px" } }}>
          {field.value.map((item) => (
            <span key={item}>
              <Typography variant="caption" key={item}>
                {amenities.find((amenity) => amenity.amenitiesid === item).amenities}
              </Typography>
              <IconButton
                size="small"
                sx={{ width: 20, height: 20 }}
                onClick={() => removeAmenity(item)}
              >
                <Close sx={{ fontSize: 15 }} />
              </IconButton>
              &nbsp;|&nbsp;
            </span>
          ))}
          <Typography variant="caption"></Typography>
          <TextFieldMUI
            label="Search"
            size="small"
            sx={{ my: 2 }}
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <StyledContainer>
            {amenities
              .filter((item) => searchRegex.test(item.amenities))
              .map((option) => (
                <label key={option.amenitiesid} style={{ fontSize: "12px" }}>
                  <input
                    type="checkbox"
                    name={"amenList"}
                    value={option.amenitiesid}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      if (isChecked) {
                        addAmenity(option.amenitiesid);
                      } else {
                        removeAmenity(option.amenitiesid);
                      }
                    }}
                    checked={field.value.includes(option.amenitiesid)}
                  />
                  {option.amenities}
                </label>
              ))}
          </StyledContainer>
        </Box>
      </Dropdown>
    </>
  );
}

export default memo(AmenitiesPicker);

const StyledContainer = styled("div")(({ theme }) => ({
  display: "grid",
  rowGap: "16px",
  gridTemplateColumns: "1fr 1fr 1fr",
  alignContent: "start",
  height: "200px",
  overflowY: "auto",

  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "1fr 1fr ",
  },
}));
