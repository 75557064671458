import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { Button, CircularProgress, styled } from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import PasswordField from "../../components/FormElements/PasswordField";
import { useResetAgencyPasswordMutation, useVerifyTokenQuery } from "../../redux/api/authApi";
import { toast } from "react-toastify";

export default function ResetPassword() {
  const [error, setError] = useState("");
  const methods = useForm({
    defaultValues: { confirmPassword: "", password: "" },
  });
  const { handleSubmit } = methods;

  const [resetAgencyPassword, { isLoading }] = useResetAgencyPasswordMutation();
  
  // Extract token from query parameters
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get('token');
  
  // Verify the token
  const { error: verifyError, isLoading: verifyIsLoading } = useVerifyTokenQuery(token);

  const navigate = useNavigate();

  useEffect(() => {
    if (verifyError) {
      toast.error("Invalid or expired token");
      navigate("/forget-password");
    }
  }, [verifyError, navigate]);

  const onSubmit = (data) => {
    setError("");
    const { password, confirmPassword } = data;

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    resetAgencyPassword({ password, confirmPassword, token })
      .unwrap()
      .then((res) => {
        toast.success("Password has been reset successfully");
        navigate("/login");
      })
      .catch((err) => {
        const errorMessage = err?.data?.Message || "Unknown error occurred";
        setError(errorMessage);
      });
  };

  if (verifyIsLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }  

  return (
    <StyledContainer>
      <Avatar sx={{ m: 1, bgcolor: "#56C662" }}>
        <LockOutlinedIcon />
      </Avatar>

      <Typography variant="h2">Reset Agency Password</Typography>

      {error && (
        <Typography variant="h5" color="error">
          {error}
        </Typography>
      )}
      <FormProvider {...methods}>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <PasswordField
            name="password"
            rules={{ required: "Password is required" }}
            label="New Password"
            size="large"
          />
          <PasswordField
            name="confirmPassword"
            rules={{ required: "Confirm Password is required" }}
            label="Confirm Password"
            size="large"
          />

          <Button
            type="submit"
            variant="contained"
            size="large"
            disabled={isLoading}
            endIcon={isLoading ? <CircularProgress size={20} /> : undefined}
            sx={{ color: "white" }}
          >
            Reset
          </Button>

          <StyledLinksContainer>
            <Typography
              component={Link}
              color="secondary"
              to="/login"
              variant="body2"
            >
              Sign in
            </Typography>
          </StyledLinksContainer>
        </StyledForm>
      </FormProvider>
    </StyledContainer>
  );
}

const StyledContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  height: "100%",
  padding: "16px",
  marginTop: "40px",
}));

const StyledForm = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "400px",
  justifyContent: "center",
  gap: "24px",
  marginTop: "24px",
}));

const StyledLinksContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "16px",
  flexWrap: "wrap",
});
