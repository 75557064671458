
import React from "react";
import { useSignupAgencyMutation } from "../../redux/api/authApi";
import { toast } from "react-toastify";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../../redux/features/authSlice";
import AgencyProfileForm from "../../components/AgencyProfileForm";
import { useUploadImageMutation } from "../../redux/api/filesApi";

export default function Signup() {
  const [uploadImage] = useUploadImageMutation()
  const [signupAgency, { isLoading }] = useSignupAgencyMutation();




  const onSubmit = async (data) => {

    let uploadedLogo = null;
    let uploadedWatermark = null;
    let uploadedLicensePic = null;


    let p1;
    let p2;
    let p3;

    if (data.agencylogo) {
      let logoFormData = new FormData();
      logoFormData.append("files", data.agencylogo);
      p1 = uploadImage({ image: logoFormData, imageType: "agencylogo" })
        .unwrap()
        .then((res) => (uploadedLogo = res));
    }

    if (data.agencywatermark) {
      let watermarkFormData = new FormData();
      watermarkFormData.append("files", data.agencywatermark);
      p2 = uploadImage({ image: watermarkFormData, imageType: "agencywm" })
        .unwrap()
        .then((res) => (uploadedWatermark = res));
    }

    if (data.agencylicensepic) {
      let licensePicFormData = new FormData();
      licensePicFormData.append("files", data.agencylicensepic);

      p3 = uploadImage({ image: licensePicFormData, imageType: "agencylicpic" })
        .unwrap()
        .then((res) => (uploadedLicensePic = res));
    }

    await Promise.all([p1, p2, p3]).then((res) => {
      let postData = data;
      postData.agencylogo = uploadedLogo ?? data.agencylogo;
      postData.agencywatermark = uploadedWatermark ?? data.agencywatermark;
      postData.agencylicensepic = uploadedLicensePic ?? data.agencylicensepic;
      postData.agencystatus = 0;

      signupAgency(postData)
        .unwrap()
        .then((res) => {
          toast.success("Agency is registered. Please wait while we review your details.");
        })
        .catch((err) => {
          if (err.status === 409)
            return toast.error("Agency Already Exists Please Login to continue");

          toast.error("unkown error occured, please try again later");
        });

    });



  };

  return (
    <div>
      <AgencyProfileForm onAddAgency={onSubmit} isSubmitting={isLoading} />
    </div>
  );
}

