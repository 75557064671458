import React, { useRef } from "react";
import { useMediaQuery } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { usePropertiesData } from "../hooks/useContext/agencyProperties";
import { AGENCY_PROPERTY_COLUMNS } from "../pages/Properties/propertiesData";

const AllProperties = ({ setAllPropertiesComp, setSelectedProperties }) => {
    const isMobileScreen = useMediaQuery("(max-width:768px)");
    const { properties: data, isLoading } = usePropertiesData();
    const tableRef = useRef();

    const submitHandler = () => {
        setAllPropertiesComp(false);
    };

    const handleSave = () => {
        const selectedRows = tableRef.current.getSelectedRowModel().rows.map((row) => row.id);
        setSelectedProperties(selectedRows);
        submitHandler();
    };

    const handleClose = () => {
        submitHandler();
    };

    return (
        <div className="containerWidthWithSidebar fixed top-16 left-0 min-[1200px]:left-[265px] h-full overflow-y-auto z-[9999] bg-white"> 
        <div className="px-20 pt-5">
                <h1 className="mb-5">All Properties</h1>
                <MaterialReactTable
                    state={{ showSkeletons: isLoading }}
                    columns={AGENCY_PROPERTY_COLUMNS}
                    tableInstanceRef={tableRef}
                    data={data ?? []}
                    enableFixed
                    enableStickyHeader={!isMobileScreen}
                    muiTableContainerProps={{
                        sx: { maxHeight: isMobileScreen ? "100%" : "calc(100vh - 350px)" },
                    }}
                    enableRowSelection
                    enableDensityToggle={false}
                    initialState={{ density: "compact" }}
                    getRowId={(row) => row.propertyid}
                />
                <div className="flex items-center justify-between p-2">
                    <button className="p-2 rounded-lg bg-green text-white" onClick={handleSave}>
                        Save
                    </button>
                    <button className="p-2 rounded-lg bg-customGray" onClick={handleClose}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AllProperties;
