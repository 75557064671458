import { MuiFileInput } from "mui-file-input";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useUploadImageMutation } from "../../redux/api/filesApi";

export default function AgentId() {
  const { control } = useFormContext();
  const [uploadAgentImage] = useUploadImageMutation();

  return (
    <Controller
      name="nicimg"
      control={control}
      render={({ field: { ref, onChange, value, ...field }, fieldState: { error } }) => (
        <MuiFileInput
          onChange={(file) => {
            if (file) {
              let agentNicFormData = new FormData();
              agentNicFormData.append("", file);

              uploadAgentImage({ image: agentNicFormData, imageType: "agentid" })
                .unwrap()
                .then((res) => onChange(res));
            } else onChange("");
          }}
          value={value}
          getInputText={(value) => value.substr(0, 15) + "..."}
          {...field}
          label="Agent ID"
          inputRef={ref}
          size="small"
          helperText={error?.message}
          error={!!error}
        />
      )}
    />
  );
}
