import React from "react";
import AgentForm from "../../components/AgentForm";
import { useAddAgentLanguagesMutation, useAddAgentMutation } from "../../redux/api/agentsApi";
import { useNavigate } from "react-router-dom";
import { AGENTS_ROUTE } from "../../constants/routes";
import { errorToast } from "../../constants/toast";

export default function AddAgent() {
  const [addAgent, { isLoading: isLoadingAddAgent }] = useAddAgentMutation();
  const [addAgentLanguages, { isLoading: isLoadingAddLanguages }] = useAddAgentLanguagesMutation();

  const navigate = useNavigate();

  const onSubmit = (data) => {
    const { agentLanguages, ...postData } = data;
    addAgent(postData)
      .unwrap()
      .then((res) => {
        const postedAgentId = res.agentid;

        let promises = data.agentLanguages.map((item) =>
          addAgentLanguages({ agentid: postedAgentId, languageid: item.id })
        );

        navigate(AGENTS_ROUTE);
        Promise.all(promises)
          .then((res) => {})
          .catch((err) => {
            errorToast("Error Adding Languages");
          });
      })
      .catch((err) => {
        if (err.status === 409) return errorToast("Email already exists");

        return errorToast("Unkown Error Occured");
      });
  };

  return (
    <AgentForm
      title="Add Agent"
      onAddAgent={onSubmit}
      isSubmitting={isLoadingAddAgent || isLoadingAddLanguages}
    />
  );
}
