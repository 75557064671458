// ShowMore.js
import React, { useState, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import Chart from "react-apexcharts";
import { IoMdArrowBack } from "react-icons/io";

const ShowMore = ({
  isSuccess,
  setShowAll,
  isFetching,
  labels,
  seriesConfig,
  title,
  chipTitle,
  optionssalesoverview,
}) => {
  const [chartHeight, setChartHeight] = useState("300px"); // Initial height

  useEffect(() => {
    // Calculate the height dynamically based on the number of data points
    if (isSuccess && seriesConfig.data) {
      const numberOfDataPoints = seriesConfig.data.length;
      const minHeight = 400; // Minimum height to prevent it from being too small
      const calculatedHeight = Math.max(numberOfDataPoints * 30, minHeight);
      setChartHeight(`${calculatedHeight}px`);
    }
  }, [isSuccess, seriesConfig.data]);

  useEffect(() => {
    // Conditionally apply body scroll class
    document.body.classList.add("body-no-scroll");
    return () => {
      document.body.classList.remove("body-no-scroll");
    };
  }, []);

  const seriessalesoverview = [
    {
      name: seriesConfig.name,
      data: seriesConfig.data,
    },
  ];

  return (
    <div className="containerWidthWithSidebar fixed top-16 left-0 min-[1200px]:left-[265px] p-5 h-full overflow-y-auto z-20 bg-white"> 
    <div className="min-h-screen">
        <div className="absolute left-5 sm:left-20 top p-2 cursor-pointer z-10" onClick={() => setShowAll(false)}>
          <IoMdArrowBack size={20} />
        </div>
        <div className="sm:flex justify-between items-center py-10 sm:py-5">
          <p className="text-center flex-1 font-bold text-lg">{title}</p>
          <p className="text-center sm:text-auto text-sm">{chipTitle}</p>
        </div>
        {isFetching && (
          <Box sx={{ height: chartHeight, display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
          </Box>
        )}

        {isSuccess && !isFetching && (
          <Chart options={optionssalesoverview} series={seriessalesoverview} type="bar" height={chartHeight} />
        )}
      </div>
    </div>
  );
};

export default ShowMore;
