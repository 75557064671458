import { toast } from "react-toastify";
import { apiSlice } from ".";


const contactsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({

    getContacts: builder.query({
      query: (agcid) => `/Contacts360/Contact?agtid=&agcid=${agcid}`,
    }),
  }),
});

export const { useGetContactsQuery } = contactsApi;
