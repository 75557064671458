import React from "react";
import ReactApexChart from "react-apexcharts";
import { Box, CircularProgress, useTheme } from "@mui/material";
import BaseCard from "../../components/BaseCard/BaseCard";
import { PROPERTY_STATUSES } from "../../constants/property";

export default function PropertyStatusGraph({
  isFetching,
  isSuccess,
  labels: temp,
  series,
  total,
}) {
  const theme = useTheme();
  const options = {
    chart: {
      type: "pie",
    },
    labels: temp?.map((item) => item.label) || [],
    colors:
      temp?.map(
        (item) => theme.palette[PROPERTY_STATUSES.find((i) => item.id === i.id)?.color]?.main
      ) || [],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <BaseCard title="Properties By Status">
      {isFetching && (
        <Box
          sx={{ height: "300px", display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <CircularProgress />
        </Box>
      )}

      {isSuccess && !isFetching && (
        <ReactApexChart options={options} series={series} type="pie" height="300px" />
      )}
    </BaseCard>
  );
}
