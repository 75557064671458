import { matchIsValidTel } from "mui-tel-input";
import { PROPERTY_OFF_PLAN_NO } from "../../constants/property";


export const propertyDefaultValues = {
  saluation: "",
  ownfirstname: "",
  ownlastname: "",
  ownemail: "",
  mobileno: "",
  ownrole: "",
  duration: "",
  nationality: "",
  propertytitle: "",
  propertyprice: "",
  securityfee: "",
  percentage: "",
  commission: "",
  propertysizekanal: "",
  propertysizemarla: "",
  propertysizesqft: "",
  buildingno: "",
  unitno: "",
  bedrooms: "",
  bathrooms: "",
  propertyaddress: "",
  coordinates: "",
  propertydescription: "",
  cityid: "",
  propertypurposeid: "",
  propertytypeid: "",
  propertyvideos: "",
  propertyrefnum: "",
  amenitiesList: [], //amenities
  sublocid: "",
  notes: "",
  furnished: null,
  offPlan: PROPERTY_OFF_PLAN_NO,

  // Supportive
  provid: "",
};

export const propertyRules = {
  saluation: {},
  ownfirstname: {},
  ownlastname: {},
  ownemail: {
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "Invalid email address.",
    },
  },
  mobileno: {
    // validate: {
    //   phone: (value) => {
    //     const countrycodes = [+92, +971];

    //     if (!value || countrycodes.some((code) => code == value)) return true;

    //     return matchIsValidTel(value) || "Invalid phone number";
    //   },
    // },
    required: "Phone Number is required.",
  },
  ownrole: {},
  duration: { required: "Duration Required" },
  nationality: {},
  propertytitle: {
    required: "Property Title is required.",
  },
  propertyprice: {
    required: "Property Price is required.",
    min: {
      value: 0,
      message: "Property Price should be a positive number.",
    },
  },
  securityfee: {
    required: "Security Fee is required.",
    min: {
      value: 0,
      message: "Security Fee should be a positive number or zero.",
    },
  },
  percentage: {
    required: "Percentage Required",
    min: {
      value: 0,
      message: "Percentage should be a positive number or zero.",
    },
  },
  commission: {
    required: "Commission Required",
    min: {
      value: 0,
      message: "Commission should be a positive number or zero.",
    },
  },
  propertytypeid: { required: "Property Type Required" },
  propertysizekanal: {
    required: "Field required",
    min: {
      value: 0,
      message: "Property Size (Kanal) should be a positive number or zero.",
    },
  },
  propertysizemarla: {
    required: "Field required",
    min: {
      value: 0,
      message: "Property Size (Marla) should be a positive number or zero.",
    },
  },
  propertysizesqft: {
    required: "Field required",
    min: {
      value: 0,
      message: "Property Size (Sqft) should be a positive number or zero.",
    },
  },
  buildingno: {},
  unitno: {},
  bedrooms: {},
  bathrooms: {},
  propertyaddress: {
    required: "Property Address is required.",
  },
  coordinates: {
    required: "Goolge Maps Link Required",

  },

  propertydescription: {
    required: "Property Description is required.",
  },

  propertypurposeid: {
    required: "Property Purpose is required.",
  },
  agentid: {
    required: "Agent is required.",
  },
  propertyvideos: {
    validate: (value) => {
      if (!value) return true;
      const regex = /^https:\/\/youtu\.be\/.*$/;
      if (!regex.test(value)) {
        return "Invalid YouTube video link";
      }

      return true;
    },
  },
  cityid: {
    required: "City is required.",
  },
  sublocid: {
    required: 'Sub Location is required'
  },
  notes: {},

  //supportive
  provid: {
    required: "Select State",
  },
};
