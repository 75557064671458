import { Box, CircularProgress } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import AgentForm from "../../components/AgentForm";
import { useEditAgentMutation, useGetAgentByIdQuery } from "../../redux/api/agentsApi";

export default function EditAgent() {
  const { agentId } = useParams();
  const {
    data: agent,
    isFetching,
    isSuccess,
  } = useGetAgentByIdQuery(agentId, {
    refetchOnMountOrArgChange: true,
  });

  const [editAgent, { isLoading }] = useEditAgentMutation();
  const navigate = useNavigate();

  const onEditAgent = (data) => {
    data.languageList = data.agentLanguages.map((item) => item.id);
    editAgent(data)
      .unwrap()
      .then((res) => {
        navigate("/agents");
      })
      .catch((err) => {});
  };

  if (isFetching)
    return (
      <div style={{ height: "100%", width: "100%", display: "grid", placeItems: "center" }}>
        <CircularProgress />
      </div>
    );

  if (isSuccess && agent && agent.length > 0)
    return (
      <Box sx={{ maxWidth: "1600px", mx: "auto" }}>
        <AgentForm
          onEditAgent={onEditAgent}
          isSubmitting={isLoading}
          agentData={{
            ...agent[0],
            agentLanguages: agent[0]?.laguages?.map((item) => ({ id: item })),
          }}
        />
      </Box>
    );

  return <div>Agent not found</div>;
}
