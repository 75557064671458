import { Button, Typography, styled } from "@mui/material";
import React, { useState } from "react";
import { useGetAgencyPropertiesQuery } from "../../redux/api/propertiesApi";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectCurrentagency } from "../../redux/features/authSlice";
import PropertiesTable from "./PropertiesTable";
import { Link, useSearchParams } from "react-router-dom";
import { PROPERTY_STATUSES } from "../../constants/property";
import SharePdfModal from "./SharePdfModal";
import { usePropertiesData } from "../../hooks/useContext/agencyProperties";
import { ADD_PROPERTY_ROUTE } from "../../constants/routes";

const propertyStatuses = [

  {
    id: 0,
    label: "Un Published",
    color: "error",
  },
  {
    id: 1,
    label: "Published",
    color: "success",
  },
  {
    id: 2,
    label: "Pending",
    color: "warning",
  },
  {
    id: 3,
    label: "Draft",
    color: "secondary",
  },
];

export default function Properties() {
  // const currentAgency = useSelector(selectCurrentagency);
  // const {
  //   data: properties = [],
  //   isFetching,
  //   isSuccess,
  // } = useGetAgencyPropertiesQuery(currentAgency.agencyid);
  const { properties, isLoading: isFetching } = usePropertiesData();


  const [searchParams, setSearchParams] = useSearchParams();
  const [toShareProperties, setToShareProperties] = useState([]);

  const activePropertyStatus = parseInt(searchParams.get("status"));

  const isAllPropertiesStatusSelected = PROPERTY_STATUSES.every(
    (status) => status.id !== activePropertyStatus
  );

  const filteredProperties = useMemo(() => {

    if (isAllPropertiesStatusSelected) return properties;

    return properties?.filter((property) => property.pstatus === activePropertyStatus);
  }, [activePropertyStatus, properties, isAllPropertiesStatusSelected]);


  const onChangeActivePropertyStatus = (id) => {
    setSearchParams((prev) => {
      if (id === "all") {
        prev.delete('status')
        return new URLSearchParams({
          ...Object.fromEntries(prev),
        });
      }

      return {
        ...Object.fromEntries(prev),
        status: id,
      };
    });
  };

  const isOpenSharePdfModal = toShareProperties && toShareProperties.length > 0;

  const onCloseSharePdfModal = () => {
    setToShareProperties([]);
  };

  const onClickSharePdf = (propertyIds) => {
    setToShareProperties(propertyIds);
  }



  return (
    <div>
      {isOpenSharePdfModal && (
        <SharePdfModal
          isOpen={isOpenSharePdfModal}
          onClose={onCloseSharePdfModal}
          properties={properties.filter((property) => toShareProperties.includes(property.propertyid))}
        />
      )}
      <Typography variant="h2">Properties</Typography>

      <StyledTop>
        <StyledButton
          size="small"
          onClick={() => onChangeActivePropertyStatus("all")}
          variant={isAllPropertiesStatusSelected ? "contained" : "outlined"}
          sx={{
            color: isAllPropertiesStatusSelected ? "white" : "#56C662",
          }}
        >
          All Properties
        </StyledButton>
        {propertyStatuses.map((button, index) => (
          <StyledButton
            size="small"
            key={index}
            color={button.color}
            onClick={() => onChangeActivePropertyStatus(button.id)}
            variant={activePropertyStatus === button.id ? "contained" : "outlined"}
          >
            {button.label}
          </StyledButton>))}
        <StyledButton
          size="small"
          component={Link}
          to={ADD_PROPERTY_ROUTE}
          variant="contained"
          sx={{ ml: "auto", backgroundColor: "#56C662", color: "white" }}
        >
          Add Property
        </StyledButton>
      </StyledTop>

      <PropertiesTable data={filteredProperties} onClickSharePdf={onClickSharePdf} isFetching={isFetching} />
    </div>
  );
}

const StyledButton = styled(Button)(({ theme }) => ({
  marginRight: "8px",
}));

const StyledTop = styled("div")(({ theme }) => ({
  marginTop: "16px",
  marginBottom: "16px",
  display: "flex",
  flexWrap: "wrap",
  rowGap: "16px",
  alignItems: "center",
}));
