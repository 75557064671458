import {
  Button,
  Typography,
  styled,
  CircularProgress,
  Box,
} from "@mui/material";
import React, { memo, startTransition, useState } from "react";
import ImagesUpload from "./ImagesUpload";
import { propertyDefaultValues, propertyRules } from "./propertyValidation";
import { FormProvider, useForm } from "react-hook-form";
import TextField from "../FormElements/TextField";
import AmenitiesPicker from "./AmenitiesPicker";
import {
  useAddImagesMutation,
  useAddPropertyMutation,
  useEditPropertyMutation,
} from "../../redux/api/propertiesApi";
import { useSelector } from "react-redux";
import { selectCurrentagency } from "../../redux/features/authSlice";
import { useNavigate } from "react-router-dom";
import PropertyAttributesPicker from "./PropertyAttributesPicker";
import PropertyLocationPicker from "./PropertyLocationPicker";
import PropertyPricePicker from "./PropertyPricePicker";
import OwnerInfoPicker from "./OwnerInfoPicker";
import { arrayMoveImmutable } from "array-move";
import { toast } from "react-toastify";
import { PENDING_STATUS_ID } from "../../constants/property";
import cuid from "cuid";
import useUploadPdf from "../../hooks/useUploadPdf";
import { useGetAgentsQuery } from "../../redux/api/leadsApi";
import MapModel from "./MapModel";

function PropertyForm({ propertyData, imagesData }) {
  const [images, setImages] = useState(imagesData || []);
  const [addProperty, { isLoading: isLoadingAddProperty }] =
    useAddPropertyMutation();
  const [selectedAgentId, setSelectedAgentId] = useState(null);

  const currentAgency = useSelector(selectCurrentagency);
  const { data: agents, isFetching } = useGetAgentsQuery(
    currentAgency.agencyid
  );
  const methods = useForm({
    defaultValues: propertyData ?? propertyDefaultValues,
  });
  const {
    handleSubmit,
    setFocus,
    getValues,
    setValue,
    formState: { errors },
  } = methods;

  const [editProperty, { isLoading: isLoadingEditProperty }] =
    useEditPropertyMutation();
  const [addImages, { isLoading: isLoadingAddImages }] = useAddImagesMutation();
  const propertyPdfUploader = useUploadPdf();
  const navigate = useNavigate();

  const onDrop = (acceptedFiles) => {
    const formData = new FormData();
    for (const image of acceptedFiles) {
      formData.append("", image);
    }
    let uploadedFiles = [];
    const watermark = {
      location: currentAgency.agencywatermarkposition,
      size: currentAgency.agencywatermarksize,
      name: currentAgency.agencywatermark,
      opacity: currentAgency.agencytransparency,
    };

    let loadingImages = acceptedFiles.map((item) => ({
      id: cuid(),
      url: URL.createObjectURL(item),
      description: "",
      loadingImage: true,
    }));

    setImages((prev) => [...prev, ...loadingImages]);

    addImages({ formData: formData, watermark })
      .unwrap()
      .then((res) => {
        uploadedFiles = res.map((item) => ({
          id: item,
          url: item,
          description: "",
        }));

        startTransition(() => {
          setImages((prev) => {
            let removedLoadingImages = prev.filter(
              (item) => !item.loadingImage
            );
            return [...removedLoadingImages, ...uploadedFiles];
          });
        });
      });
  };

  const handleImageDescriptionChange = (value, index) => {
    startTransition(() => {
      setImages((prev) => {
        let item = [...prev];
        item[index].description = value;
        return item;
      });
    });
  };

  const onRemoveImage = (id) => {
    setImages((prev) => {
      return prev.filter((item) => item.id !== id);
    });
  };

  const onSortEnd = (oldIndex, newIndex) => {
    setImages((array) => arrayMoveImmutable(array, oldIndex, newIndex));
  };

  React.useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);

    if (firstError) {
      setFocus(firstError);
    }
  }, [errors, setFocus]);

  const filteredAgent =
    agents && agents.find((agent) => agent.agentid === selectedAgentId);
  const addSignature = () => {
    if (!selectedAgentId) {
      return toast.error("Please select an agent.");
    }
    setValue("agentid", selectedAgentId);
    const signature =
      filteredAgent?.agentdescription || "No agent description available.";
    const currentDescription = getValues("propertydescription") || "";
    setValue("propertydescription", `${currentDescription}\n${signature}`);
  };

  const addProfile = () => {
    const profile =
      currentAgency.Agency_Descrip || "No agency description available.";
    setValue(
      "propertydescription",
      getValues("propertydescription") + "\n" + profile
    );
  };

  const onSubmit = (rhfData, pstatus) => {
    if (!images || images.length === 0) {
      return toast.error("Please Select Images");
    }

    const { amenList, ...data } = rhfData;
    data.pstatus = pstatus;
    data.agencyid = currentAgency.agencyid;

    data.propertysizesqft = parseFloat(data.propertysizesqft).toFixed(2);
    data.propertysizemarla = parseFloat(data.propertysizemarla).toFixed(2);
    data.propertysizeSqmt = parseFloat(data.propertysizeSqmt).toFixed(2);
    data.propertysizekanal = parseFloat(data.propertysizekanal).toFixed(2);
    data.propertysizeSqyd = parseFloat(data.propertysizeSqyd).toFixed(2);

    if (propertyData) {
      editProperty({
        ...data,
        imagesDetail: images.map((item) => ({
          imagename: item.url,
          imagedescription: item.description,
        })),
      })
        .unwrap()
        .then((res) => {
          navigate("/properties");
          propertyPdfUploader.upload(propertyData.propertyid);
        })
        .catch((err) => {});
    } else {
      addProperty({
        ...data,
        imagesDetail: images.map((item) => ({
          imagename: item.url,
          imagedescription: item.description,
        })),
      })
        .unwrap()
        .then(async (res) => {
          navigate("/properties");
          propertyPdfUploader.upload(res.propertyid);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div>
      <Typography variant="h2">
        {propertyData ? "Edit Property" : "Add Property"}{" "}
      </Typography>

      <FormProvider
        {...methods}
        formState={{ defaultValues: propertyDefaultValues }}
      >
        <form>
          <Typography variant="h3" mt={4}>
            Property Details
          </Typography>

          <StyledInputsContainer>
            <PropertyAttributesPicker
              agents={agents}
              isFetching={isFetching}
              setSelectedAgentId={setSelectedAgentId}
            />
          </StyledInputsContainer>

          <Typography variant="h3" mt={4}>
            Property Location
          </Typography>

          <StyledInputsContainer>
            <PropertyLocationPicker
              editingCityId={propertyData?.cityId}
              editingStateId={propertyData?.provid}
            />
          </StyledInputsContainer>

          <Typography variant="h3" mt={4}>
            Property Price
          </Typography>

          <StyledInputsContainer>
            <PropertyPricePicker />
          </StyledInputsContainer>

          <Typography variant="h3" mt={4}>
            Owner Info
          </Typography>

          <StyledInputsContainer>
            <OwnerInfoPicker editingNationality={propertyData?.nationality} currentAgency={currentAgency} />
          </StyledInputsContainer>

          <Typography variant="h3" mt={4}>
            Property Details
          </Typography>

          <StyledTitleContainer>
            <TextField
              name="propertytitle"
              label="Property Title"
              rules={propertyRules.propertytitle}
              charactersLimit={45}
            />

            <AmenitiesPicker editingAmenities={propertyData?.propertyid} />
          </StyledTitleContainer>

          <Box
            sx={{
              mt: 4,
              borderBottom: "1px solid rgba(0,0,0,0.1)",
              py: "16px",
            }}
          >
            <TextField
              name="propertydescription"
              label="Description"
              multiline
              rows={7}
              rules={propertyRules.propertydescription}
            />
            <Button
              variant="outlined"
              onClick={addSignature}
              size="small"
              sx={{ mt: 2 }}
            >
              Add Signature
            </Button>
            <Button
              variant="outlined"
              onClick={addProfile}
              sx={{ mt: 2 }}
              size="small"
            >
              Add Profile
            </Button>
          </Box>

          <ImagesUpload
            onDrop={onDrop}
            images={images}
            onSortEnd={onSortEnd}
            onChangeDescription={handleImageDescriptionChange}
            onRemoveImage={onRemoveImage}
          />

          <Typography variant="h3" my={3}>
            Additional Info
          </Typography>

          <TextField name="notes" label="Note" rules={propertyRules.notes} />

          <StyledButtonsContainer>
            <Button
              onClick={() => {
                handleSubmit(onSubmit)(PENDING_STATUS_ID); //0===PSTATUS
              }}
              variant="contained"
              disabled={isLoadingEditProperty}
              endIcon={
                isLoadingEditProperty || isLoadingAddImages ? (
                  <CircularProgress size={20} />
                ) : undefined
              }
            >
              Submit
            </Button>
            <Button
              variant="outlined"
              disabled={isLoadingEditProperty}
              onClick={() => {
                handleSubmit(onSubmit)(3);
              }}
            >
              Draft
            </Button>
          </StyledButtonsContainer>
        </form>
      </FormProvider>
    </div>
  );
}

export default memo(PropertyForm);

const StyledButtonsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "16px",
  marginTop: "24px",
}));

const StyledInputsContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: theme.spacing(2),
  margin: "24px 0px",

  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "1fr 1fr",
  },

  [theme.breakpoints.up("lg")]: {
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
  },
}));

const StyledTitleContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: theme.spacing(2),
  margin: "24px 0px",

  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "1fr 1fr",
  },
}));
