import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Autocomplete, TextField, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentagency } from "../../../redux/features/authSlice";
import { combineDateAndTime } from "../../../constants/customFunction";
import { useGetTransactionDetailsQuery, usePostTransactionMutation } from "../../../redux/api/DealsPdf";
import { useGetAgentsQuery } from "../../../redux/api/leadsApi";
import { TRANSACTION_TYPES } from "../../../constants/transaction";
import styled from 'styled-components';
import DateAndTime from "../../../components/DateAndTime";

export const AddTransaction = () => {
  const currentAgency = useSelector(selectCurrentagency);
  const agencyid = currentAgency?.agencyid;
  const methods = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const pathParts = location.pathname.split("/");
  const dealId = pathParts[4];
  const token = localStorage.getItem("token");

  const {
    data: agents,
    isFetching,
    isSuccess,
  } = useGetAgentsQuery(currentAgency.agencyid);

  const { data: TransactionDetails } = useGetTransactionDetailsQuery({
    agcid: agencyid,
    dealId: dealId,
    token: token,
  });

  let lastTransaction;
  if (TransactionDetails && TransactionDetails.length > 0) {
    lastTransaction = TransactionDetails[TransactionDetails.length - 1];
  }

  const [postTransaction, { isLoading, isError, error }] = usePostTransactionMutation();

  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [amount, setAmount] = useState("");
  const [remainingAmount, setRemainingAmount] = useState(0);

  const onSubmit = (data) => {
    if (!startDate || !startTime) {
      toast.error("Date and Time are required");
      return;
    }

    const dateAndTime = combineDateAndTime(startDate, startTime);

    const submitData = {
      amountReceived: amount,
      paymentType: data.paymentType,
      amountReceivedDate: dateAndTime,
      dealid: dealId,
    };

    postTransaction({
      data: submitData,
      token: token,
      agtid: data.agentid,
    })
      .unwrap()
      .then(() => {
        navigate("/accounts/deals");
      })
      .catch((err) => {
        console.error("Failed to post transaction:", err);
        toast.error(
          "Failed to post transaction: " + err.data?.Message || "Unknown error"
        );
      });
  };

  useEffect(() => {
    if (lastTransaction) {
      const totalAmount = lastTransaction.balance || 0;
      setRemainingAmount(totalAmount - amount);
    }
  }, [amount, lastTransaction]);

  return (
    <>
      <h1 className="mb-5 text-3xl">New Transaction</h1>

      <StyledInputsContainer>
        <DateAndTime
          startDate={startDate}
          setStartDate={setStartDate}
          startTime={startTime}
          setStartTime={setStartTime}
        />

<Controller
  name="agentid"
  control={methods.control}
  defaultValue={null} // default to null or empty object if not selected
  render={({ field: { onChange, value, ...field } }) => (
    <Autocomplete
      {...field}
      options={agents || []}
      size="small"
      getOptionLabel={(option) => option.agentname || ""}
      loading={isFetching}
      value={agents && agents.find(agent => agent.agentid === value) || null} // Find the selected agent
      onChange={(event, newValue) => {
        onChange(newValue ? newValue.agentid : ""); // Set the selected agent id
      }}
      renderInput={(params) => <TextField {...params} label="Select Agent" />}
    />
  )}
/>


        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel>Payment Type</InputLabel>
          <Controller
            name="paymentType"
            control={methods.control}
            defaultValue=""
            render={({ field }) => (
              <Select
                {...field}
                label="Payment Type"
                onChange={(e) => field.onChange(Number(e.target.value))}
              >
                {TRANSACTION_TYPES.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>

        <TextField
          label="Amount"
          type="number"
          fullWidth
          size="small"
          variant="outlined"
          value={amount}
          onChange={(e) => setAmount(Number(e.target.value))}
        />

        <TextField
          label="Will be remaining"
          type="number"
          fullWidth
          size="small"
          variant="outlined"
          value={remainingAmount}
          InputProps={{
            readOnly: true,
          }}
        />
      </StyledInputsContainer>

      {TransactionDetails && TransactionDetails.length > 0 && (
        <div className="flex text-gray-500 my-5 items-center justify-between sm:w-[50%]">
          <div>
            <p className="p-2">Total Amount</p>
            <p className="p-2">Remaining Amount</p>
          </div>
          <div>
            <p className="p-2">
              {lastTransaction && lastTransaction.amountTotal}
            </p>
            <p className="p-2">
              {lastTransaction && (lastTransaction.balance ?? 0)}
            </p>
          </div>
        </div>
      )}

      <button
        type="submit"
        className="bg-green hover:bg-green-700 text-white py-2 px-4 rounded mx-auto"
        onClick={methods.handleSubmit(onSubmit)}
      >
        Submit
      </button>
    </>
  );
};

const StyledInputsContainer = styled.div({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
  gap: "24px",
  marginTop: "24px",
  "@media (max-width: 1024px)": { // roughly lg
    gridTemplateColumns: "1fr 1fr 1fr",
  },
  "@media (max-width: 768px)": { // roughly md
    gridTemplateColumns: "1fr 1fr",
  },
  "@media (max-width: 480px)": { // roughly sm
    gridTemplateColumns: "1fr",
  },
});
