import React from "react";
import { Button, Typography, styled } from "@mui/material";
import { Link } from "react-router-dom";
import { useGetAgentsQuery } from "../../redux/api/agentsApi";
import AgentsTable from "./AgentsTable";
import { useSelector } from "react-redux";
import { selectCurrentagency } from "../../redux/features/authSlice";
import { ADD_AGENT_ROUTE } from "../../constants/routes";

export default function Agents() {
  const currentAgency = useSelector(selectCurrentagency);
  const { data: agents, isFetching, isSuccess } = useGetAgentsQuery(currentAgency.agencyid);

  return (
    <div>
      <StyledTop>
        <Typography variant="h2">Agents </Typography>

        <Button
          component={Link}
          to={ADD_AGENT_ROUTE}
          variant="contained"
          sx={{ ml: "auto", color: "white",  }}
        >
          Add Agent
        </Button>
      </StyledTop>

      <AgentsTable isFetching={isFetching} isSuccess={isSuccess} data={agents} />
    </div>
  );
}

const StyledTop = styled("div")(({ theme }) => ({
  marginTop: "16px",
  marginBottom: "16px",
  display: "flex",
  alignItems: "center",
}));
