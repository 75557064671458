import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  CircularProgress,
  TextField,
  DialogActions,
  Button
} from '@mui/material';
import { useSelector } from 'react-redux';
import { selectCurrentagency } from '../redux/features/authSlice';

const ExcelDialogTable = ({ open, onClose, excelData, loading, onSave }) => {
    const [updatedData, setUpdatedData] = useState([]);
    const currentAgency = useSelector(selectCurrentagency);

    useEffect(() => {
      if (excelData) {
        setUpdatedData(excelData);
      }
    }, [excelData]);
  
    const handleInputChange = (index, field, value) => {
      const newData = [...updatedData];
      newData[index][field] = value; 
      setUpdatedData(newData);
    };

    const handleSave = () => {
      const formattedLeads = updatedData.map((record) => ({
        leadtype: record['Lead Type'],
        firstname: record['First Name'],
        lastname: record['Last Name'],
        userphone: record['Contact No'],
        companyName: record['Company Name'],
        propertyrefnum: record['Property Ref Num'],
        location: record.Location,
        designation: record.Designation,
        budget: record.Budget,
        useremail: record.Email,
        leadTime: new Date().toISOString(),
        agencyid: currentAgency.agencyid,
      }));
  
      onSave(formattedLeads);
    };
  
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        Excel Leads Data
        <Button
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          Close
        </Button>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Grid container justifyContent="center" alignItems="center">
            <CircularProgress />
          </Grid>
        ) : (
          <div style={{ overflowX: 'auto', maxHeight: '400px' }}>
            <Grid container spacing={1} alignItems="center">
              {updatedData.map((record, index) => (
                <Grid item xs={12} key={index} mt={2}>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '30px repeat(9, 200px)',
                      alignItems: 'center',
                      gap: 4
                    }}
                  >
                    <div>{index + 1}</div>
                    <TextField
                      label="First Name"
                      value={record["First Name"] || ""}
                      variant="outlined"
                      size="small"
                      onChange={(e) => handleInputChange(index, "First Name", e.target.value)}
                    />
                    <TextField
                      label="Last Name"
                      value={record["Last Name"] || ""}
                      variant="outlined"
                      size="small"
                      onChange={(e) => handleInputChange(index, "Last Name", e.target.value)}
                    />
            
                    <TextField
                      label="Email"
                      value={record["Email"] || ""}
                      variant="outlined"
                      size="small"
                      onChange={(e) => handleInputChange(index, "Email", e.target.value)}
                    />
                    <TextField
                      label="Contact No"
                      value={record["Contact No"] || ""}
                      variant="outlined"
                      size="small"
                      onChange={(e) => handleInputChange(index, "Contact No", e.target.value)}
                    />
                    <TextField
                      label="Property Ref Num"
                      value={record["Property Ref Num"] || ""}
                      variant="outlined"
                      size="small"
                      onChange={(e) => handleInputChange(index, "Property Ref Num", e.target.value)}
                    />
                    <TextField
                      label="Company Name"
                      value={record["Company Name"] || ""}
                      variant="outlined"
                      size="small"
                      onChange={(e) => handleInputChange(index, "Company Name", e.target.value)}
                    />
                    <TextField
                      label="Location"
                      value={record["Location"] || ""}
                      variant="outlined"
                      size="small"
                      onChange={(e) => handleInputChange(index, "Location", e.target.value)}
                    />
                    <TextField
                      label="Designation"
                      value={record["Designation"] || ""}
                      variant="outlined"
                      size="small"
                      onChange={(e) => handleInputChange(index, "Designation", e.target.value)}
                    />
                    <TextField
                      label="Budget"
                      value={record["Budget"] || ""}
                      variant="outlined"
                      size="small"
                      onChange={(e) => handleInputChange(index, "Budget", e.target.value)}
                    />
                    
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExcelDialogTable;
