import { useEffect, useState } from "react";
import { usePostLeadStatusMutation } from "../../../../redux/api/leadsApi";
import { useSelector } from "react-redux";
import { CardSlider } from "../../../../components/AppointmentSlider";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../components/loader/Loader";
import { toast } from "react-toastify";
import {
  DatePicker,
  LocalizationProvider,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { combineDateAndTime } from "../../../../constants/customFunction";
import { useLeadsData } from "../../../../hooks/useContext/leadsData";
import { usePropertiesData } from "../../../../hooks/useContext/agencyProperties";
import AllProperties from "../../../../components/AllProperties";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import DateAndTime from "../../../../components/DateAndTime";

const ViewingArranged = () => {
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [note, setNote] = useState("");
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [selectedAllProperties, setSelectedAllProperties] = useState([]);
  const [allPropertiesComp, setAllPropertiesComp] = useState(false);

  const currentPath = window.location.pathname;
  const segments = currentPath.split("/");
  const agentId = segments[3];
  const userId = segments[5];
  const leadId = segments[segments.length - 1];
  const navigate = useNavigate();

  const dateAndTime = combineDateAndTime(startDate, startTime);

  // Get All Leads
  const { agencyLeads, refetch } = useLeadsData();
  const leadsData = agencyLeads;

  // Filter agent through agentId
  const filterAgentId = leadsData?.filter(
    (item) => item.agentid === parseInt(agentId)
  );

  // Filter user through userId
  let filteredData =
    filterAgentId &&
    filterAgentId.filter((item) => item.userid === parseInt(userId));
  // Filter leads through leadId
  const statusLead =
    filteredData &&
    filteredData[0]?.Properties &&
    filteredData[0]?.Properties?.filter(
      (lead) => lead.leadid === parseInt(leadId)
    );

  useEffect(() => {
    if (
      statusLead &&
      statusLead[0]?.propertyid &&
      !selectedProperties.includes(statusLead[0].propertyid)
    ) {
      setSelectedProperties([statusLead[0].propertyid, ...selectedProperties]);
    }
  }, [statusLead, selectedProperties]);

  const {
    properties: suggestedProperties,
    isLoading: isLoadingSuggestedProperties,
  } = usePropertiesData();

  const handlePropertySelect = (propertyId) => {
    setSelectedProperties((prevSelectedProperties) =>
      prevSelectedProperties.includes(propertyId)
        ? prevSelectedProperties.filter((id) => id !== propertyId)
        : [...prevSelectedProperties, propertyId]
    );
  };

  const [leadStatusData, { isLoading }] = usePostLeadStatusMutation();

  const propertiesIDs = selectedProperties.concat(selectedAllProperties);

  const handleSubmit = () => {
    if (!dateAndTime) {
      toast.error("Date and Time is required");
    } else {
      leadStatusData({
        Leadid: leadId,
        leadStatus: 9,
        propertyIds: JSON.stringify(propertiesIDs),
        leadDate: dateAndTime,
        note: note || null,
      }).then(() => {
        refetch();
        navigate(-1);
      });
    }
  };

  const AllPropertiesHandler = () => {
    setAllPropertiesComp(true);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Typography variant="h4" gutterBottom>
          Viewing Arranged
        </Typography>
        <Box display="flex" gap={2} mb={2}>
          <DateAndTime
            startDate={startDate}
            setStartDate={setStartDate}
            startTime={startTime}
            setStartTime={setStartTime}
          />
        </Box>
        <TextField
          label="Description"
          multiline
          rows={7}
          variant="outlined"
          fullWidth
          value={note}
          onChange={(e) => setNote(e.target.value)}
          sx={{ mb: 5 }}
        />
        <Box sx={{ mb: 5 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5" gutterBottom>
              Suggested Properties
            </Typography>
            <Button
              variant="contained"
              color="success"
              onClick={AllPropertiesHandler}
            >
              All Properties
            </Button>
          </Box>
          {isLoadingSuggestedProperties ? (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
              <CircularProgress />
            </Box>
          ) : suggestedProperties && suggestedProperties.length > 0 ? (
            <CardSlider
              selectedProperties={selectedProperties}
              handlePropertySelect={handlePropertySelect}
              isLoading={isLoadingSuggestedProperties}
              suggestedProperties={suggestedProperties}
            />
          ) : (
            <Typography>No suggested properties available</Typography>
          )}
        </Box>
        <Button
          sx={{ mb: 4 }}
          variant="contained"
          color="success"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          Submit
        </Button>
      </Box>
      {allPropertiesComp && (
        <AllProperties
          setAllPropertiesComp={setAllPropertiesComp}
          setSelectedProperties={setSelectedAllProperties}
        />
      )}
    </>
  );
};

export default ViewingArranged;
