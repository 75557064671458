import { MenuItem, Skeleton, TextField, styled, useMediaQuery } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { AGENT_COLUMNS } from "./agentData";
import { useChangeAgentStatusMutation, useDeleteAgentMutation } from "../../redux/api/agentsApi";

const agentStatuses = [
  {
    id: 0,
    label: "In Active",
  },
  { id: 1, label: "Active" },
  { id: 2, label: "Delete" },
];

export default function AgentsTable({ data, isSuccess, isFetching, columns }) {
  const [changeAgentStatus] = useChangeAgentStatusMutation();
  const [deleteAgent] = useDeleteAgentMutation();
  const isMobileScreen = useMediaQuery("(max-width:768px)");

  const tableRef = useRef(null);

  const onChangeAction = (e, agents) => {
    agents.forEach((agent) => {
      if (e.target.value === 2) {
        deleteAgent({ agentId: agent.agentId, agencyId: agent.agencyId });
        tableRef.current?.resetRowSelection();
        return;
      }

      changeAgentStatus({
        agentId: agent.agentId,
        agencyId: agent.agencyId,
        status: e.target.value,
      });
    });
  };

  return (
    <>
      <MaterialReactTable
        state={{ isLoading: isFetching }}
        tableInstanceRef={tableRef}
        enableStickyHeader={!isMobileScreen}
        muiTableContainerProps={{
          sx: { maxHeight: isMobileScreen ? "100%" : "calc(100vh - 300px)" },
        }}
        columns={AGENT_COLUMNS}
        data={data ?? []}
        enableRowSelection
        enableDensityToggle={false}
        initialState={{ density: "compact" }}
        getRowId={(row) => {
          return row.agentid;
        }}
        renderTopToolbarCustomActions={({ table }) => {
          const agents = table.getSelectedRowModel().flatRows.map((item) => ({
            agentId: item.original.agentid,
            agencyId: item.original.agencyid,
          }));

          return (
            <TextField
              select
              variant="outlined"
              size="small"
              sx={{ minWidth: "100px" }}
              label="Action"
              value=""
              onChange={(e) => onChangeAction(e, agents)}
            >
              {agentStatuses.map((status) => (
                <MenuItem value={status.id}>{status.label}</MenuItem>
              ))}
            </TextField>
          );
        }}
      />
    </>
  );
}
